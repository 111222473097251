export const USER_SIGN_UP_REQUEST = "USER_SIGN_UP_REQUEST"
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS"
export const USER_SIGN_UP_FAIL = "USER_SIGN_UP_FAIL"

export const USER_SIGN_IN_REQUEST = "USER_SIGN_IN_REQUEST"
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS"
export const USER_SIGN_IN_FAIL = "USER_SIGN_IN_FAIL"

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"

export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAIL = "USER_LIST_FAIL"

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"
export const USER_DELETE_RESET = "USER_DELETE_RESET"

export const USER_ADD_REQUEST = "USER_ADD_REQUEST"
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS"
export const USER_ADD_FAIL = "USER_ADD_FAIL"

// export const USER_ADD_REQUEST = "USER_ADD_REQUEST"
// export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS"
// export const USER_ADD_FAIL = "USER_ADD_FAIL"