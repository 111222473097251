import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate  } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import productImg from '../assets/images/product-1.png'
import { medicineDetails } from '../redux/actions/medicineActions'
import { addCart } from '../redux/actions/cartActions'
import axios from 'axios'
import parse from 'html-react-parser';
import { API_ENDPOINT, HTML_GT } from '../rd-utils/constants'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter, getHeader, getToken, getUserEmail, getUserName, isLoggedIn } from '../rd-utils/util'

export default function ProductDetail() {
    const navigate = useNavigate()
    const [counter, setCounter] = useState(0)
    const [medicineData, setMedicineData] = useState({})
    const [loading, setLoading] = useState(false)
    const [previewImage, setPreviewImage] = useState('')

    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    const linkCode = keysUrl[4]

    const handleIncrement = () => {
        setCounter(counter + 1)
    }
  
    const handleDecrement = () => {
        if (counter > 0){
            setCounter(counter - 1)
        }
    }

    const addCartHandle = () => {
       if(isLoggedIn()){
          let md = {...medicineData}
          delete md._id
          delete md.createdOn
          delete md.updatedOn
          let cartData = {...md, productId: medicineData._id, count: counter, userName: getUserName(), userEmail: getUserEmail()}
          axios.post(API_ENDPOINT+'cart/add-product', cartData, getHeader()).then(res=>{
            let d = res.data
            toast(d.message)
          })
       }else{
          navigate('/login')
       }
    }
    useEffect(()=>{
      loadProductDetail()
    }, [])
    function loadProductDetail(){
      setLoading(true)
      axios.get(`${API_ENDPOINT}user/product/${linkCode}`, getHeader()).then(res=>{
          setLoading(false)
          let d = res.data
          if(d.success){
              let mo = d.message
              let imageA = mo.images
              let ind = imageA.findIndex(m=>m.isDefault)
              setPreviewImage(imageA[ind].url)
              setMedicineData(mo)
              //setUserData(d.message)
          }else{
              toast(d.message)
          }
      })
    }
  return (
    <div>
      <CustomCircularP show={loading}/>
      {Object.keys(medicineData).length>0?
      <div className="container">
        <div className="row my-5">
        
          <div className="col-md-12">
            {/* Breadcrumbs */}
            <div>
               <p><Link to={"/"} className="text-muted">Home</Link> <span> {parse(HTML_GT)} </span> 
                  <Link to={"/"+medicineData.type} className="text-muted">{capitalizeFirstLetter(medicineData.type)}</Link>
                {/* <span> {'>'} </span> <Link to={"/sub-medicine/"+medicineData.diseaseId._id} className="text-muted"></Link> */}
                 <span>{parse(HTML_GT)}</span> <span className="custom-text-color">{medicineData.name}</span>
                </p>
            </div>
          </div>
            {/* Breadcrumbs ends*/} 
          <div className="col-md-6 text-center my-3">
            <div>
              <img className="w-50" src={previewImage} alt="" />
            </div>
            <div className="row">
                <div className="col my-4">
                    <div>
                        <img src={previewImage} style={{width:100}} alt="" />
                    </div>
                </div>
            </div>
          </div>
          <div className="col-md-6 my-3">
            <h2>{medicineData.name}</h2>
            {
            medicineData.rating == '5' ? <span className='text-warning'><i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> </span>  
            : medicineData.rating == '4' ? <span className='text-warning'><i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i> </span> 
            : medicineData.rating == '3' ? <span className='text-warning'><i class="fa-solid fa-star"></i> <i class="fa-solid fa-star"></i></span>  
            : medicineData.rating == '2' ? <span className='text-warning'><i class="fa-solid fa-star"></i></span>
            : <i class="fa-solid fa-star"></i>
            }
            <div className="">
              <p>
                Marketed by : <span>{medicineData.manufacturer}</span>
              </p>
              <p>
                Country of Origin : <span>India</span>
              </p>
              <p>
                Certificate : 
              </p>

             {medicineData.type !== 'labtest' &&
              <div className='d-inline-flex'>
                {medicineData.certificates.map((cert, i) => (
                  <img className='me-2' key={i} src={cert.url} alt="" width={"100px"} height={'100px'}/>
                ))}
              </div>
              }
              
              <div>
            <div className="cart-btn my-4">
                <button className='btn btn-primary me-2' onClick={handleDecrement}> - </button>
                <span>{counter}</span>
                <button className='btn btn-primary ms-2' onClick={handleIncrement}> + </button>
            </div>
            </div>
              <button className="btn btn-blue-style" onClick={addCartHandle} disabled={!counter}>Add To Cart</button>
            </div>
          </div>
          <div className="col-md-12 my-5">
            <h5>PRODUCT DETAILS</h5>
            <p>{medicineData.description}</p>
          </div>
        </div>
      </div>:''}
    </div>
  )
}
