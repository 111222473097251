import {
    MEDICINE_ADD_REQUEST,
    MEDICINE_ADD_SUCCESS,
    MEDICINE_ADD_FAIL,
    MEDICINE_ADD_RESET,
    MEDICINE_LIST_REQUEST,
    MEDICINE_LIST_SUCCESS,
    MEDICINE_LIST_FAIL,
    MEDICINE_LIST_OF_DISEASE_REQUEST,
    MEDICINE_LIST_OF_DISEASE_SUCCESS,
    MEDICINE_LIST_OF_DISEASE_FAIL,
    MEDICINE_DETAILS_REQUEST,
    MEDICINE_DETAILS_SUCCESS,
    MEDICINE_DETAILS_FAIL,
    MEDICINE_UPLOAD_REQUEST,
    MEDICINE_UPLOAD_SUCCESS,
    MEDICINE_UPLOAD_FAIL,
    MEDICINE_UPDATE_REQUEST,
    MEDICINE_UPDATE_SUCCESS,
    MEDICINE_UPDATE_FAIL,
    MEDICINE_UPDATE_RESET,
    MEDICINE_DELETE_REQUEST,
    MEDICINE_DELETE_SUCCESS,
    MEDICINE_DELETE_FAIL,
    MEDICINE_DELETE_RESET,
} from '../constants/medicineConstants'

export const addMedicineReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_ADD_REQUEST:
        return { loading: true }
      case MEDICINE_ADD_SUCCESS:
        return { loading: false, medicine: action.payload, success: true }
      case MEDICINE_ADD_FAIL:
        return { loading: false, error: action.payload }
      case MEDICINE_ADD_RESET:
        return {}
      default:
        return state
    }
  }

  export const updateMedicineReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_UPDATE_REQUEST:
        return { loading: true }
      case MEDICINE_UPDATE_SUCCESS:
        return { loading: false, medicine: action.payload, success: true }
      case MEDICINE_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case MEDICINE_UPDATE_RESET:
        return {}
      default:
        return state
    }
  }

  export const uploadMedicineFileReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_UPLOAD_REQUEST:
        return { loading: true }
      case MEDICINE_UPLOAD_SUCCESS:
        return { loading: false, medicineFile: action.payload.medicineData, success: true }
      case MEDICINE_UPLOAD_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listMedicineReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_LIST_REQUEST:
        return { loading: true }
      case MEDICINE_LIST_SUCCESS:
        return { loading: false, medicines: action.payload.medicinesList }
      case MEDICINE_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listMedicineOfDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case  MEDICINE_LIST_OF_DISEASE_REQUEST:
        return { loading: true }
      case  MEDICINE_LIST_OF_DISEASE_SUCCESS:
        return { loading: false, medicines: action.payload.medicinesList }
      case  MEDICINE_LIST_OF_DISEASE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const medicineDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_DETAILS_REQUEST:
        return { loading: true }
      case MEDICINE_DETAILS_SUCCESS:
        return { loading: false, medicineData: action.payload.medicineData }
      case MEDICINE_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const medicineDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case MEDICINE_DELETE_REQUEST:
        return { loading: true }
      case MEDICINE_DELETE_SUCCESS:
        return { loading: false, medicineData: action.payload, success: true }
      case MEDICINE_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case MEDICINE_DELETE_RESET:
        return {}
      default:
        return state
    }
  }