import React from 'react'
import b1 from '../../assets/images/blog/b1.jpg'
import {Link, useNavigate} from 'react-router-dom';

export default function BlogCard(props) {
  const {blog} = props

  return (
    <>
    <div className='col-md-4'>
        <div className="card effect my-3" >
            <img src={blog.image} width={100} className="card-img-top" alt="..." />
            <div className="card-body">
                <div style={{fontWeight: 'bold', fontSize: '18px'}} className='rd-two-lines'>{blog.header}</div>
                <div className='rd-two-lines' style={{marginBottom: '8px'}}> {blog.shortIntro}</div>
               <div style={{display: 'table', marginLeft: 'auto'}}>
               <Link  to={`/blog-detail/${blog._id}`} className="btn btn-outline-dark btn-sm">View More</Link>
               </div>
            </div>
        </div>
    </div>

    </>
  )
}
