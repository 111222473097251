import React from 'react'
import accessories1 from '../../assets/images/static_pages/eletronics/accessories/accessories1.png'
import accessories2 from '../../assets/images/static_pages/eletronics/accessories/accessories2.png'
import accessories3 from '../../assets/images/static_pages/eletronics/accessories/accessories3.png'
import accessories4 from '../../assets/images/static_pages/eletronics/accessories/accessories4.png'
import accessories5 from '../../assets/images/static_pages/eletronics/accessories/accessories5.png'
import accessories6 from '../../assets/images/static_pages/eletronics/accessories/accessories6.png'
import accessories7 from '../../assets/images/static_pages/eletronics/accessories/accessories7.png'
import accessories8 from '../../assets/images/static_pages/eletronics/accessories/accessories8.png'
import accessories9 from '../../assets/images/static_pages/eletronics/accessories/accessories9.png'

export default function ElectronicsAccessories() {
  return (
    <>
       <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>Accessories</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">ADOPTER</h5>

                                 <p className='text-dark'>
                                 Iravine Laptop Charger Adapter 65 W

Compatible 19.5 V

Power Cord Included

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">CHARGER</h5>

                                 <p className='text-dark'>
                                 Chias 2 A Mobile Fast Type-C Mobile Charger Adapter Wall Charger | Universal Travel Charger | Type-C Charger Cable Fast Charging Mobile Charger Android Charger with 1 M Type-C Charging Data Cable (2 Amp) Charger with Detachable Cable  (White, Cable Included)

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">POWER BANK</h5>
                               <p className='text-dark'>
                               Capacity (mAH) 
5001 - 10000 mAH
Color 
Black
USB Port 
Single,Dual
Connector 
Micro Single USB,Micro Dual USB

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Doctor blade</h5>
                               <p className='text-dark'>Adjustable Doctor blade with 100mm Width and 0-100micron coating height, ideal for coating chemical slury and paint
</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">VGA</h5>
                               <p className='text-dark'>Pasow VGA Splitter Cable Dual VGA Monitor Y Cable 1 Male to 2 Female Adapter Converter Video Cable for Screen Duplication - 1 Feet

</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">HDMI</h5>
                               <p className='text-dark'>Terabyte Tera Byte 3Mtr 4K Ultra HD HDMI to HDMI Cable (Black)

</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories7} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">TONER</h5>
                               <p className='text-dark'>HP CF283X and CRG137 II Laser Toner Cartridge for Office, Packaging Type: Box</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories8} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Lan cabel</h5>
                               <p className='text-dark'>FEDUS Cat6 Heavy Duty Outdoor Cable 15M 49 Feet Weatherproof/UV Resistant 1000mbps Ethernet Cable Suitable for Direct Burial Installations cat6 Ethernet Patch Cable LAN Cable Internet Network Cord</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={accessories9} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Cat 6 lan cabel</h5>
                               <p className='text-dark'>D-Link Cat 6 Lan Cable
</p>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
