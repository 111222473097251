import React, { useState } from 'react'
import { useEffect } from 'react'

export default function ProductFilter({data, setData, category}) {
    const [toggle, setToggle] = useState(false)
    const [toggle2, setToggle2] = useState(false)
    const [toggle3, setToggle3] = useState(false)
    const [toggle4, setToggle4] = useState(false)

   const categoryHandler = (cat)=>{

   }
   const manufatureHandler = (e)=>{

   }
    return (
    <>
         <div className="card my-3 border-0">
                        <div className="card-body">
                            
                            <h4 className='fw-bold my-3'>Categories</h4>
                            <div className='custom-box scroll' style={{ maxHeight: 260, overflowY: "auto", overflowX: "hidden" }}> 
                                <p style={{cursor: 'pointer'}} onClick={() => {
                                    setToggle(!toggle)
                                    }}><i className="fa-solid fa-angle-right"></i> Supplements</p>
                                    {toggle && (
                                        <ul>
                                            {/* <li><input type="checkbox" name="Nutritional" id="Nutritional" onChange={() => categoryHandler('Nutritional')}/> Nutritional</li> */}
                                            <li><input type="checkbox" name="Men" id="Men" onChange={() => categoryHandler('Men')}/> Men</li>
                                            <li><input type="checkbox" name="Women" id="Women" onChange={() => categoryHandler('Women')}/> Women</li>
                                            <li><input type="checkbox" name="Kids" id="Kids" onChange={() => categoryHandler('Kids')}/> Kids</li>
                                        </ul>
                                    )}
                            
                                <p style={{cursor: 'pointer'}} onClick={() => {
                                    setToggle2(!toggle2)
                                    }}><i className="fa-solid fa-angle-right"></i> Sexual Wellness</p>
                                    {toggle2 && (
                                        <ul>
                                            <li><input type="checkbox" name="Condoms" id="Condoms" onChange={() => categoryHandler('Condoms')}/> Condoms</li>
                                            <li><input type="checkbox" name="Gellies" id="Gellies" onChange={() => categoryHandler('Gellies')}/> Gellies</li>
                                            <li><input type="checkbox" name="Lubricants" id="Lubricants" onChange={() => categoryHandler('Lubricants')}/> Lubricants</li>
                                            <li><input type="checkbox" name="Sexual Health" id="Sexual Health" onChange={() => categoryHandler('Sexual Health')}/> Sexual Health</li>
                                        </ul>
                                    )}
                        
                                <p style={{cursor: 'pointer'}} onClick={() => {
                                    setToggle3(!toggle3)
                                    }}><i className="fa-solid fa-angle-right"></i> Beauty</p>
                                    {toggle3 && (
                                        <ul>
                                            <li><input type="checkbox" name="Hair Care" id="Hair Care" onChange={() => categoryHandler('Hair Care')}/> Hair Care</li>
                                            <li><input type="checkbox" name="Skin Care" id="Skin Care" onChange={() => categoryHandler('Skin Care')}/> Skin Care</li>
                                            <li><input type="checkbox" name="Personal Care" id="Personal Care" onChange={() => categoryHandler('Personal Care')}/> Personal Care</li>
                                            <li><input type="checkbox" name="Perfumes" id="Perfumes" onChange={() => categoryHandler('Perfumes')}/> Perfumes</li>
                                        </ul>
                                    )}
                                <p style={{cursor: 'pointer'}} onClick={() => {
                                    setToggle4(!toggle4)
                                    }}><i className="fa-solid fa-angle-right"></i> Generic</p>
                                    {toggle4 && (
                                    <ul>
                                        <li><input type="checkbox" name="Men" id="Men" onChange={() => categoryHandler('Men')}/> Men</li>
                                        <li><input type="checkbox" name="Women" id="Women" onChange={() => categoryHandler('Women')}/> Women</li>
                                        <li><input type="checkbox" name="Kids" id="Kids" onChange={() => categoryHandler('Kids')}/> Kids</li>
                                    </ul>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="card my-3 border-0">
                        <div className="card-body">
                            <h4 className='fw-bold my-3'>Filters</h4>
                            {/* <div className='my-3'>
                                <p className='fw-bold'>Availability</p>
                                <div className="form-check my-3">
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">Exclude out of stock (253)</label>
                                </div>
                            </div> */}
                            <div className='my-3'>
                                    <p className='fw-bold'>Manufacturers</p>
                                    <input type="search" id="form1" className="form-control my-2" placeholder='Search' onChange={manufatureHandler}/>
                                    <div className='custom-box scroll' style={{ maxHeight: 240, overflowY: "auto", overflowX: "hidden" }}> 

                                   {/* { manufacturers.map( (manufacture, i) => (
                                    <div className="form-check my-3" key={i}>
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault" onChange={() => selectedManufactureHandler(manufacture)}/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">{manufacture}</label>
                                    </div>))} */}

                                    {/* <div className="form-check my-3">
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">Advind Healthcare India Pvt. Ltd. (42)</label>
                                    </div>
                                    <div className="form-check my-3">
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">Livinguard Technologies Pvt. Ltd. (26)</label>
                                    </div>
                                    <div className="form-check my-3">
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">West Coast Pharmaceuticals Works Ltd (15)</label>
                                    </div> */}
                                </div> 
                            </div>
                            {/* <div className='my-3'>
                                    <p className='fw-bold'>Brand</p>
                                    <input type="search" id="form1" className="form-control my-2" placeholder='Search' onChange={(e) => brandHandler(e)}/>
                                    <div className='custom-box scroll' style={{ maxHeight: 240, overflowY: "auto", overflowX: "hidden" }}> 
                                    {brand.map((b, i)=>(
                                    <div className="form-check my-3">
                                        <input  className="form-check-input"  type="checkbox" defaultValue=""  id="flexCheckDefault" onChange={() => selectedBrandHandler(b)}/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">{b &&(b.charAt(0).toUpperCase() + b.slice(1))}</label>
                                    </div>))}
                                </div> 
                            </div> */}
                            {/* <div className='my-3'>
                                    <p className='fw-bold'>Price</p>
                                    <input type="range" className="form-range" id="customRange1" />
                            </div>
                            <div className='my-3'>
                                    <p className='fw-bold'>Discount</p>
                                    <input type="range" className="form-range" id="customRange1" />
                            </div> */}
                        </div>
                    </div>
    </>
  )
}