export const MEDICINE_ADD_REQUEST = "MEDICINE_ADD_REQUEST"
export const MEDICINE_ADD_SUCCESS = "MEDICINE_ADD_SUCCESS"
export const MEDICINE_ADD_FAIL = "MEDICINE_ADD_FAIL"
export const MEDICINE_ADD_RESET = "MEDICINE_ADD_RESET"

export const MEDICINE_LIST_REQUEST = "MEDICINE_LIST_REQUEST"
export const MEDICINE_LIST_SUCCESS = "MEDICINE_LIST_SUCCESS"
export const MEDICINE_LIST_FAIL = "MEDICINE_LIST_FAIL"

export const MEDICINE_LIST_OF_DISEASE_REQUEST = "MEDICINE_LIST_OF_DISEASE_REQUEST"
export const MEDICINE_LIST_OF_DISEASE_SUCCESS = "MEDICINE_LIST_OF_DISEASE_SUCCESS"
export const MEDICINE_LIST_OF_DISEASE_FAIL = "MEDICINE_LIST_OF_DISEASE_FAIL"

export const MEDICINE_DETAILS_REQUEST = "MEDICINE_DETAILS_REQUEST"
export const MEDICINE_DETAILS_SUCCESS = "MEDICINE_DETAILS_SUCCESS"
export const MEDICINE_DETAILS_FAIL = "MEDICINE_DETAILS_FAIL"

export const MEDICINE_UPLOAD_REQUEST = "MEDICINE_UPLOAD_REQUEST"
export const MEDICINE_UPLOAD_SUCCESS = "MEDICINE_UPLOAD_SUCCESS"
export const MEDICINE_UPLOAD_FAIL = "MEDICINE_UPLOAD_FAIL"

export const MEDICINE_DELETE_IMAGES_OR_ICON_REQUEST = "MEDICINE_DELETE_IMAGES_OR_ICON_REQUEST"
export const MEDICINE_DELETE_IMAGES_OR_ICON_SUCCESS = "MEDICINE_DELETE_IMAGES_OR_ICON_SUCCESS"
export const MEDICINE_DELETE_IMAGES_OR_ICON_FAIL = "MEDICINE_DELETE_IMAGES_OR_ICON_FAIL"

export const MEDICINE_UPDATE_REQUEST = "MEDICINE_UPDATE_REQUEST"
export const MEDICINE_UPDATE_SUCCESS = "MEDICINE_UPDATE_SUCCESS"
export const MEDICINE_UPDATE_FAIL = "MEDICINE_UPDATE_FAIL"
export const MEDICINE_UPDATE_RESET = "MEDICINE_UPDATE_RESET"

export const MEDICINE_DELETE_REQUEST = "MEDICINE_DELETE_REQUEST"
export const MEDICINE_DELETE_SUCCESS = "MEDICINE_DELETE_SUCCESS"
export const MEDICINE_DELETE_FAIL = "MEDICINE_DELETE_FAIL"
export const MEDICINE_DELETE_RESET = "MEDICINE_DELETE_RESET"