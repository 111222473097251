export const COMPANY_ADD_REQUEST = "COMPANY_ADD_REQUEST"
export const COMPANY_ADD_SUCCESS = "COMPANY_ADD_SUCCESS"
export const COMPANY_ADD_FAIL = "COMPANY_ADD_FAIL"

export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST"
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS"
export const COMPANY_LIST_FAIL = "COMPANY_LIST_FAIL"

export const COMPANY_DETAILS_REQUEST = "COMPANY_DETAILS_REQUEST"
export const COMPANY_DETAILS_SUCCESS = "COMPANY_DETAILS_SUCCESS"
export const COMPANY_DETAILS_FAIL = "COMPANY_DETAILS_FAIL"

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST"
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS"
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL"
export const COMPANY_UPDATE_RESET = "COMPANY_UPDATE_RESET"

export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST"
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS"
export const COMPANY_DELETE_FAIL = "COMPANY_DELETE_FAIL"
export const COMPANY_DELETE_RESET = "COMPANY_DELETE_RESET"

export const COMPANY_UPLOAD_REQUEST = "COMPANY_UPLOAD_REQUEST"
export const COMPANY_UPLOAD_SUCCESS = "COMPANY_UPLOAD_SUCCESS"
export const COMPANY_UPLOAD_FAIL = "COMPANY_UPLOAD_FAIL"
export const COMPANY_UPLOAD_RESET = "COMPANY_UPLOAD_RESET"