import { Api } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_ENDPOINT } from '../../rd-utils/constants';

export default function ForgotPassword() {
    const emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const submit = () => {
        if(email === ""){
            toast("Please Enter email Id")
        }else if(!emailPattern.test(email)){
            toast("Please Enter valid email Id")
        }else{
            setLoading(true)
            axios.post(API_ENDPOINT+'user/generate-pwd-reset-link', {email: email}).then(res=>{
                setLoading(false)
                let d = res.data
                if(d.success){
                    setMessage(d.message)
                }else{
                    toast(d.message)
                }
            })
        }
    }
  return (
    <>
     <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-5">
                <div className="card">
                    <div className="card-body">
                        <div className='mb-5'>
                            <h3 className='text-center fw-bolder custom-text-color'>Forgot Password ?</h3>
                            <p className='text-muted text-center fw-lighter'>Enter your email below to recive your password reset instructions</p>
                        </div>
                        <div className="form-floating mb-4">
                            <input type="email" className="form-control" id="floatingInput" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            <label htmlFor="floatingInput" className='fw-lighter'>Email address</label>
                        </div>
                        <div className="text-center">
                            <LoadingButton fullWidth loading={loading} variant='contained' loadingPosition='start' onClick={submit}>Submit</LoadingButton>
                            <p className='my-3 fw-lighter text-muted'><span className=''><Link to={{pathname:"/login"}}><i className="fa-solid fa-angle-left"></i> Back to login page</Link></span></p>

                            <p>{message !==''?message:''}</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    </>
  )
}
