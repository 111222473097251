import React from 'react'
import img1 from '../../assets/images/static_pages/logistics/freight/img1.png'
import img2 from '../../assets/images/static_pages/logistics/freight/img2.png'

export default function LogisticsFreight() {
  return (
    <>
       <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4 logistics_freight_banner">
                        <h1 className='fw-bolder text-uppercase text-light text-center mt-4' style={{marginBottom: '20%'}}>FREIGHT FORWARDING</h1>
                    </div>
                 
                   
                    <div className="row my-3">
                        <div className="col-md-8">
                        
                            <h2 className='text-dark fw-bold'>WHAT IS FREIGHT FORWARDING?</h2>
                            <p className='text-dark'>Freight forwarding is the coordination and shipment of goods around the world through the use of single or multiple carriers via air, ocean, road, or railway. Freight forwarding works to provide the efficient and cost-effective transfer of products, while also ensuring that everything being shipped remains in good condition throughout the entire transportation process.</p>
                        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img1} style={{width: '100%'}} alt=""/>
                          </div>
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-md-4">
                            <div className='text-center'>
                                <img src={img2} style={{width: '100%'}} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-8">
                                 <h2 className='text-dark fw-bold'>FREIGHT FORWARDER RESPONSIBILITIES</h2>
                                 <p className='text-dark fw-bold'> Some of the responsibilities of a freight forwarder include:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul style={{listStyleType: 'circle'}}>
                                            <li>Arranging for cargo insurance and damage claim inspections</li>
                                            <li>Arranging for special transportation for time-sensitive or over-sized shipments</li>
                                            <li>Consolidating shipments</li>
                                           
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                    <ul style={{listStyleType: 'circle'}}>
                                            <li>Coordinating cargo surveys</li>
                                            <li>Negotiating rates with carriers</li>
                                            <li>Preparing and submitting shipping and export documents</li>
                                            <li>Selecting and booking product transportation based on the estimated time of arrival and overall price.</li>
                                            <li>Tracking shipments</li>
                                        </ul>
                                    </div>

                                </div>
                             
                        </div>
                        <div className="col-md-12  my-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className=' my-4'> 
                                        <h3 className='text-dark fw-bold'>DANGEROUS CARGO (DG CARGO HANDLING)</h3>
                                        <p className='text-dark'>we handle DG with packing and Hazardous goods to perfection. All compliance regarding necessary certifications and checks & balances are met. </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className=' my-4'> 
                                        <h3 className='text-dark fw-bold'>DOOR TO DOOR</h3>
                                        <p className='text-dark'>We also handle door to door  deliveries expertly for a wide range of products </p>
                                        <p className='text-dark'>International air express is a unique product mainly for shipments ranging from 500 GMs to 50 kgs. These are the services in which a premium cost is applicable but the services are door to door and faster in speed compared to other services.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className=' my-4'> 
                                        <h3 className='text-dark fw-bold'>EXCESS BAGGAGES</h3>
                                        <p className='text-dark'>Both Commercial and Non-Commercial shipments including Documents / Non Documents, Samples, Gifts, Eatables, Sweets, dry foods, Snacks, Pickles, Homemade items, Household Utensils, Condiments, Clothes, Books and University Applications excess baggage etc, which are small shipments, are best routes through this mode</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className=' my-4'> 
                                            <h3 className='text-dark fw-bold'>COURIER SAMPLES AND COMMECIAL SHIPMENTS</h3>
                                            <p className='text-dark'>In addition to our own network, we have strategic partnership accounts with UPS, FedEx,DHL and all Airlines  thereby providing our customers’ unmatched variety for selection based on geographic and other aspects.</p>
                                            <p className='text-dark'>Documentation, paper work, packing, pricing, door pickup, tracking, personalized customer support services 24/7/365 days, RK world wide  is your Trusted international air express specialist.Turnaround time (TAT) based deliveries for urgent shipments</p>

                                        </div>
                                </div>

                            </div>
                         

                        

                       

                            
                        </div>

                        


                    </div>

                    
            </div>
        </div>
    </>
  )
}
