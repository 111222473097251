import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function Menu() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
    <>
   <div className="row justify-content-center d-filter" style={{marginBottom: '16px'}}>
    <div className="col-md-12">
    <nav className="navbar navbar-expand-lg mb-0 pb-0 nav justify-content-center">
    <ul className="navbar-nav nav justify-content-center">
            <li className='nav-item text-center' style={{marginLeft:'10px', marginRight:'25px'}}>
              <Link to="/medicine" className="fw-bold text-dark m-0 fs-6" style={{cursor:'pointer'}}>Medicine</Link>
            </li>
            <li className='nav-item text-center' style={{marginLeft:'10px', marginRight:'25px'}}>
                 <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Supplements</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/labtest" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Lab Test</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Cosmetics</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Sexual Wellness</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Ayurveda</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Devices</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Health Corner</Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/doctors" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Doctor's </Link>
            </li>
            <li className="nav-item text-center" style={{marginLeft:'10px', marginRight:'25px'}}>
                <Link to="/product" className="fw-bold text-dark m-0" style={{cursor:'pointer'}}>Covid Essential</Link>
            </li>
        </ul>
    </nav>
      

        {/* <div className="row justify-content-center bg-light">
        
        <Slider {...settings}>
        <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/Supplements" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Medicine</Link>
          </div>
          <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/Supplements" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Supplements</Link>
          </div>
          <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/Supplements" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Lab Test</Link>
          </div>
          <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/Beauty" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Cosmetics</Link>
          </div>
          <div className="col-md-2 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>SexualWellness</Link>
          </div>
          <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Ayurveda</Link>
          </div>
          <div className="col-md-1 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Devices</Link>
          </div>
          <div className="col-md-2 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Health Corner</Link>
          </div>
          <div className="col-md-2 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Doctor & Articles </Link>
          </div>
          <div className="col-md-2 my-3 text-center">
              <Link to="/product/category/SexualWellness" className="fw-bold text-uppercase text-muted m-0" style={{cursor:'pointer'}}>Covid Essntials</Link>
          </div>
        </Slider>
        
         
      </div> */}
    </div>
  </div>
    </>
  )
}