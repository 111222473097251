import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_ENDPOINT } from '../../rd-utils/constants';

export default function ResetPassword() {

    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    const token = keysUrl[4]
    console.log(token)

    const [pwd, setPwd] = useState('')
    const [cpwd, setCpwd] = useState('')
    const [loading, setLoading] = useState(false)
    const submit = ()=>{
        if(pwd === ''){
            toast("Please Enter Password")
        }else if(pwd !== cpwd){
            toast("Passwords dont match")
        }else{
            setLoading(true)
            axios.post(API_ENDPOINT+'user/reset-password', {token: token, password: pwd}).then(res=>{
                setLoading(false)
                let d = res.data
                if(d.success){
                    document.location.href = '/login'
                }else{
                    toast(d.message)
                }
            })
        }
    }
  return (
    <>
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <div className='mb-5'>
                                <h4 className='text-center fw-bolder custom-text-color'>Reset password to login your account</h4>
                                <p className='text-muted text-center'>Enter your new password below, we're just extra safe</p>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" value={pwd} onChange={e=>setPwd(e.target.value)}/>
                                <label htmlFor="floatingPassword">Password</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={cpwd} onChange={e=>setCpwd(e.target.value)}/>
                                <label htmlFor="floatingPassword">Confirm Password</label>
                            </div>
                            <div className="text-center">
                            <LoadingButton fullWidth loading={loading} variant='contained' loadingPosition='start' onClick={submit}>Reset Password</LoadingButton>
                                <p className='my-3 fw-lighter text-muted'><span className=''><Link to={{pathname:"/login"}}><i className="fa-solid fa-angle-left"></i> Back to login page</Link></span></p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </>
  )
}
