import React from 'react'
import img1 from '../../assets/images/static_pages/logistics/ocean/img1.png'

export default function LogisticsOcean() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4 logistics_ocean_banner">
                        <h1 className='fw-bolder text-uppercase text-light text-center mt-4' style={{marginBottom: '20%'}}> OCEAN FREIGHT-IMPORT-EXPORT</h1>
                        <h3 className='fw-bolder text-uppercase text-light text-center'>your ocean freight partner</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                          <div className="my-3">
                            <div >
                            <h2 className='text-dark text-center fw-bold'>OCEAN FREIGHT-IMPORT-EXPORT</h2>
                                <p className='text-dark'>RK WORLD WIDE offers complete ocean freight services through a dedicated network of offices strategically located in the major shipping centers of the world. We offer full-container-load services (FCL) and LCL (outsourced), project forwarding and specialized services for small, non-containerized or oversized cargo.</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-6">
                          <div className="my-3">
                            <div >
                            <h2 className='text-dark fw-bold'>Regular Sea Service</h2>
                                <ul style={{listStyleType: 'circle'}}>
                                    <li>FCL – Full container load</li>
                                    <li>LCL/ Consolidation services (Co-load/outsourced)</li>
                                    <li>Door to Door/ Ex-works/ Special equipment/ Intermodal</li>
                                    <li>Customs Clearance (Licensed customs broker)</li>
                                    <li>Documentation Services (Free zone and mainland)</li>
                                    <li>Break-bulk cargo, Ro/Ro</li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 my-3">
                            <h2 className='text-dark fw-bold'>Multimodal – Sea-Air/ Sea-Road</h2>
                                <ul style={{listStyleType: 'circle'}}>
                                    <li>FCL – Full container load</li>
                                    <li>LCL/ Consolidation services (Co-load/outsourced)</li>
                                    <li>Door to Door/ Ex-works/ Special equipment/ Intermodal</li>
                                    <li>Customs Clearance (Licensed customs broker)</li>
                                    <li>Documentation Services (Free zone and mainland)</li>
                                    <li>Break-bulk cargo, Ro/Ro</li>
                                </ul>
                            
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                          <div className="my-3">
                            <div >
                                 <h2 className='text-dark fw-bold'>Multimodal – Sea-Air/ Sea-Road </h2>
                                <div className="row">
                                  <div className="col-md-6">
                                  <ul style={{listStyleType: 'circle'}}>
                                    <li>Oversized/ Project cargo</li>
                                    <li>Marine & Ship spares services</li>
                                    <li>Door to Door/ Ex-works/ Special equipment/ Intermodal</li>
                                    <li>Container loading /unloading/ Cross-stuffing</li>
                                </ul>
                                  </div>
                                  <div className="col-md-6">
                                  <ul style={{listStyleType: 'circle'}}>
                                    <li>Cargo inspection arrangements</li>
                                    <li>Banking documentation assistance</li>
                                    <li>Marine insurance</li>
                                    <li>Distribution and logistics planning</li>
                                    <li>Tracking and Tracing shipments</li>
                                </ul>
                                  </div>

                                </div>
                               
                            </div>
                          </div>
        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img1} style={{width: '100%'}} alt=""/>
                          </div>
                        </div>
                    </div>

                    
            </div>
        </div>
    </>
  )
}
