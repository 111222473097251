import React from 'react'
import camera1 from '../../assets/images/static_pages/eletronics/camera/camera1.png'
import camera2 from '../../assets/images/static_pages/eletronics/camera/camera2.png'
import camera3 from '../../assets/images/static_pages/eletronics/camera/camera3.png'
import camera4 from '../../assets/images/static_pages/eletronics/camera/camera4.png'


export default function ElectronicsCamera() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>Camera's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={camera1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title">CC CAMERA</h5>

                                 <p className='text-dark'>
                                 CP Plus 2.4 MP Dome Camera, Max. Camera Resolution: 1920 x 1080
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={camera2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title">WEB CAM</h5>

                                 <p className='text-dark'>
                                 Logitech Webcam - 90 fps - USB 2.0-1 Pack(s) - 4096 x 2160 Video - Auto-Focus - 5X Digital Zoom -

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={camera3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title">SURVIALLANCE</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={camera4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title">SURVIALLANCE CAMERAS</h5>
                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>

    </>
  )
}
