export const LAB_TEST_ADD_REQUEST = "LAB_TEST_ADD_REQUEST"
export const LAB_TEST_ADD_SUCCESS = "LAB_TEST_ADD_SUCCESS"
export const LAB_TEST_ADD_FAIL = "LAB_TEST_ADD_FAIL" 
export const LAB_TEST_ADD_RESET = "LAB_TEST_ADD_RESET"

export const LAB_TEST_LIST_REQUEST = "LAB_TEST_LIST_REQUEST"
export const LAB_TEST_LIST_SUCCESS = "LAB_TEST_LIST_SUCCESS"
export const LAB_TEST_LIST_FAIL = "LAB_TEST_LIST_FAIL"

export const LAB_TEST_LIST_OF_DISEASE_REQUEST = "LAB_TEST_LIST_OF_DISEASE_REQUEST"
export const LAB_TEST_LIST_OF_DISEASE_SUCCESS = "LAB_TEST_LIST_OF_DISEASE_SUCCESS"
export const LAB_TEST_LIST_OF_DISEASE_FAIL = "LAB_TEST_LIST_OF_DISEASE_FAIL"

export const LAB_TEST_DETAILS_REQUEST = "LAB_TEST_DETAILS_REQUEST"
export const LAB_TEST_DETAILS_SUCCESS = "LAB_TEST_DETAILS_SUCCESS"
export const LAB_TEST_DETAILS_FAIL = "LAB_TEST_DETAILS_FAIL"

export const LAB_TEST_UPLOAD_REQUEST = "LAB_TEST_UPLOAD_REQUEST"
export const LAB_TEST_UPLOAD_SUCCESS = "LAB_TEST_UPLOAD_SUCCESS"
export const LAB_TEST_UPLOAD_FAIL = "LAB_TEST_UPLOAD_FAIL"

export const LAB_TEST_UPDATE_REQUEST = "LAB_TEST_UPDATE_REQUEST"
export const LAB_TEST_UPDATE_SUCCESS = "LAB_TEST_UPDATE_SUCCESS"
export const LAB_TEST_UPDATE_FAIL = "LAB_TEST_UPDATE_FAIL"
export const LAB_TEST_UPDATE_RESET = "LAB_TEST_UPDATE_RESET"

export const LAB_TEST_DELETE_REQUEST = "LAB_TEST_DELETE_REQUEST"
export const LAB_TEST_DELETE_SUCCESS = "LAB_TEST_DELETE_SUCCESS"
export const LAB_TEST_DELETE_FAIL = "LAB_TEST_DELETE_FAIL"
export const LAB_TEST_DELETE_RESET = "LAB_TEST_DELETE_RESET"