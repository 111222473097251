import { toast } from 'react-toastify'
import {
    CART_ADD_REQUEST,
    CART_ADD_SUCCESS,
    CART_ADD_FAIL,
    CART_LIST_REQUEST,
    CART_LIST_SUCCESS,
    CART_LIST_FAIL,
    CART_CUSTOMER_LIST_REQUEST,
    CART_CUSTOMER_LIST_SUCCESS,
    CART_CUSTOMER_LIST_FAIL,
    CART_UPDATE_REQUEST,
    CART_UPDATE_SUCCESS,
    CART_UPDATE_FAIL,
    CART_UPDATE_RESET,
    CART_DETAILS_REQUEST,
    CART_DETAILS_SUCCESS,
    CART_DETAILS_FAIL,
    CART_PROCEED_ORDER_REQUEST,
    CART_PROCEED_ORDER_SUCCESS,
    CART_PROCEED_ORDER_FAIL,
    CART_PROCEED_ORDER_RESET,
    CART_ADMIN_CUSTOMER_LIST_REQUEST,
    CART_ADMIN_CUSTOMER_LIST_SUCCESS,
    CART_ADMIN_CUSTOMER_LIST_FAIL,
    CART_UPLOAD_RECEIPT_REQUEST,
    CART_UPLOAD_RECEIPT_SUCCESS,
    CART_UPLOAD_RECEIPT_FAIL,
    CART_UPDATE_STATUS_REQUEST,
    CART_UPDATE_STATUS_SUCCESS,
    CART_UPDATE_STATUS_FAIL,
    CART_DELETE_REQUEST,
    CART_DELETE_SUCCESS,
    CART_DELETE_FAIL,
} from '../constants/cartConstants'

export const addCartReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_ADD_REQUEST:
        return { loading: true }
      case CART_ADD_SUCCESS:
        toast("Added To Cart Successfully")
        return { loading: false, cartData: action.payload }
      case CART_ADD_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const updateCartReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_UPDATE_REQUEST:
        return { loading: true }
      case CART_UPDATE_SUCCESS:
        return { loading: false, cartData: action.payload, success: true }
      case CART_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case CART_UPDATE_RESET:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listCartReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_LIST_REQUEST:
        return { loading: true }
      case CART_LIST_SUCCESS:
        return { loading: false, cartItems: action.payload.cartList }
      case CART_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_DETAILS_REQUEST:
        return { loading: true }
      case CART_DETAILS_SUCCESS:
        return { loading: false, cartData: action.payload.cartData }
      case CART_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartDetailsOfCustomerReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_CUSTOMER_LIST_REQUEST:
        return { loading: true }
      case CART_CUSTOMER_LIST_SUCCESS:
        return { loading: false, cartItems: action.payload }
      case CART_CUSTOMER_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartListByCustomerIdReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_ADMIN_CUSTOMER_LIST_REQUEST:
        return { loading: true }
      case CART_ADMIN_CUSTOMER_LIST_SUCCESS:
        return { loading: false, cartItems: action.payload }
      case CART_ADMIN_CUSTOMER_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartUploadRecieptReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_UPLOAD_RECEIPT_REQUEST:
        return { loading: true }
      case CART_UPLOAD_RECEIPT_SUCCESS:
        return { loading: false, cartItems: action.payload }
      case CART_UPLOAD_RECEIPT_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartStatusUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_UPDATE_STATUS_REQUEST:
        return { loading: true }
      case CART_UPDATE_STATUS_SUCCESS:
        return { loading: false, cartItems: action.payload }
      case CART_UPDATE_STATUS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const cartDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_DELETE_REQUEST:
        return { loading: true }
      case CART_DELETE_SUCCESS:
        return { loading: false, ayurvedaData: action.payload }
      case CART_DELETE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const proceedOrderReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_PROCEED_ORDER_REQUEST:
        return { loading: true }
      case CART_PROCEED_ORDER_SUCCESS:
        return { loading: false, success: true }
      case CART_PROCEED_ORDER_FAIL:
        return { loading: false, error: action.payload }
      case CART_PROCEED_ORDER_RESET:
          return {}
      default:
        return state
    }
  }