import React from 'react'
import DoctorDetail from '../../pages/DoctorDetail'
import b1 from '../../assets/images/blog/b1.jpg'
import doc from '../../assets/images/doctor/doc7.jpg'
import { Link, useNavigate } from 'react-router-dom'
export default function DoctorCard(props) {
    const {doctor, onSelect} = props
    const onDetail = ()=>{
        onSelect(doctor)
    }
  return (
    <>
        <div className="col-md-3 my-3">
            <div className="card effect" style={{borderRadius: '10px 20px 30px 40px/30px'}}>
                <div className='text-center mt-2'>
                    <img src={doctor.pic} style={{height: '30vh'}} />
                </div>
                <div className="card-body" style={{backgroundColor: 'rgb(95 179 237)'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{color: '#444', fontWeight: 600, fontSize: '17px'}}>{doctor.name}</div> 
                            <div style={{color: 'white'}}>{doctor.designation} | phone: {doctor.phone}</div>
                            <div className='rd-doctor-intro' style={{color: 'white'}}>{doctor.short_intro}</div>
                            <div onClick={onDetail} style={{textDecoration: 'underline', cursor: 'pointer', display: 'table', marginLeft: 'auto', color: 'green'}}>detail</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}