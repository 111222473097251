import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/actions/userActions'
import siteLogo from '../assets/images/site-logo.png'



const Navbar = () => {
  const dispatch = useDispatch()

  return (
    <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
            {/* <Link className="navbar-brand" to={"/"}>
                <img src={siteLogo} alt="" width="100px" height="auto" className="d-inline-block align-text-top" />
            </Link> */}
           
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto me-5">
                
            <li className="nav-item dropdown ">
              
              <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa-solid fa-user me-3"></i> Admin
            </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to={{pathname:`/admin-profile`}}>Profile</Link></li>
            <li><Link className="dropdown-item" to="#" onClick={()=>dispatch(logout())}>Logout</Link></li>
          </ul>
        </li>
                
            </ul>
            </div>
        </div>
    </nav>
  )
}

export default Navbar