import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { userLogin } from '../../redux/actions/userActions';
import {Alert} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { keys } from 'lodash';
import axios from 'axios';
import { API_ENDPOINT } from '../../rd-utils/constants';
import { saveToken, saveUser } from '../../rd-utils/util';
import { Link, useNavigate } from 'react-router-dom';

export default function Login( {setLoginUser} ) {
    const navigate = useNavigate();
    
    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    const [user, setUser] = useState({email: "", password: ""})
    const [loading, setLoading] = useState(false)

    const login = () => {
        //dispatch(userLogin(user))
        //console.log(user)
        setLoading(true)
        axios.post(API_ENDPOINT+'user/login', user).then(res=>{
            let d = res.data
            setLoading(false)
            console.log(d)
            if(d.success){
                let data = d.message
                let name = data.name
                let email = data.email
                let token = data.token
                saveToken(token)
                saveUser({name, email}) 
                navigate('/')              
            }else{
                toast(d.message)
            }
        })
    }

    const handleChange = (e) =>{
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value 
        })
    }
  return (
    <>
    <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-5">
                <div className="card">
                    <div className="card-body">
                           {
                            keysUrl.length>4 && keysUrl[4] === 'msg'?
                            <h4 className='text-center custom-text-color mb-5'>A verification link has been sent to your email id. Please click the link in the email to verify.</h4>:
                            <h4 className='text-center fw-bolder custom-text-color mb-5'>Welcome, Sign In to continue</h4>
                           }
                        <div className="form-floating mb-4">
                                <input type="email" className="form-control"  placeholder="name@example.com"  name="email" value={user.email} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Email Address</label>
                        </div>
                        <div className="form-floating mb-4">
                                <input type="password" className="form-control"  placeholder="password"  name="password" value={user.password} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Password</label>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="p-2">
                                <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Remember me </label>
                                </div>
                            </div>
                            <div className="p-2">
                                <p className='text-end'><span className='custom-text-color'><Link to='/forgot-password'>forgot password?</Link></span></p>
                            </div>
                        </div>
                        <LoadingButton loading={loading} loadingPosition='start' type="submit" variant='contained' fullWidth onClick={login}>Sign In</LoadingButton>
                        <p className="text-center text-muted">I don't have an account <span className='custom-text-color'><Link to='/register'>Create new account</Link></span></p>                  
                    </div>
                </div>
            </div> 
        </div>
    </div>
    </>
  )
}
