import { createStore,combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { 
  userSignUpReducer,
  userSignInReducer,
  userListReducer,
  userDetailReducer,
  updateUserReducer,
  userDeleteReducer
 } from './redux/reducers/userReducers'

 import { 
  addCompanyReducer,
  updateCompanyReducer,
  companyDeleteReducer,
  listCompanyReducer,
  companyDetailsReducer,
  uploadPurchaseReceiptCompanyReducer
 } from './redux/reducers/companyReducers'

 import {
  addDiseaseReducer,
  updateDiseaseReducer,
  diseaseDeleteReducer,
  diseaseDetailsReducer,
  listDiseaseReducer
 } from './redux/reducers/diseaseReducers'

 import doctorReducer from  './redux/reducers/doctorReducer'
 
 import {
  addMedicineReducer,
  updateMedicineReducer,
  uploadMedicineFileReducer,
  medicineDeleteReducer,
  medicineDetailsReducer,
  listMedicineReducer,
  listMedicineOfDiseaseReducer
 } from './redux/reducers/medicineReducers'

 import {
  addLabTestReducer,
  updateLabTestReducer,
  uploadLabTestFileReducer,
  labTestDeleteReducer,
  labTestDetailsReducer,
  listLabTestReducer,
  listLabTestOfDiseaseReducer
 } from './redux/reducers/labTestReducers'

 import {
  addAyurvedaReducer,
  updateAyurvedaReducer,
  uploadAyurvedaFileReducer,
  ayurvedaDeleteReducer,
  ayurvedaDetailsReducer,
  listAyurvedaReducer,
  listAyurvedaOfDiseaseReducer
 } from './redux/reducers/ayurvedaReducers'

 import {
  addCartReducer,
  updateCartReducer,
  cartDeleteReducer,
  cartDetailsOfCustomerReducer,
  cartListByCustomerIdReducer,
  cartUploadRecieptReducer,
  cartStatusUpdateReducer,
  listCartReducer,
  cartDetailsReducer
 } from './redux/reducers/cartReducers'

 import {
  addDoctorReducer,
  updateDoctorReducer,
  doctorDeleteReducer,
  listDoctorReducer,
  doctorDetailsReducer
 } from './redux/reducers/doctorReducers'

 import {
  orderListReducer,
  orderListCustomerReducer,
  orderListCustomerByIdReducer,
  orderDetailsReducer
 } from './redux/reducers/orderReducers'

const reducer = combineReducers({
  userSignUp : userSignUpReducer,
  userSignIn : userSignInReducer,
  userList : userListReducer,
  userDetail : userDetailReducer,
  updateUser : updateUserReducer,
  userDelete : userDeleteReducer,
  addCompany: addCompanyReducer,
  updateCompany: updateCompanyReducer,
  companyDelete: companyDeleteReducer,
  listCompany: listCompanyReducer,
  companyDetails: companyDetailsReducer,
  uploadPurchaseReceiptCompany: uploadPurchaseReceiptCompanyReducer,
  addDisease: addDiseaseReducer,
  updateDisease: updateDiseaseReducer,
  diseaseDelete: diseaseDeleteReducer,
  listDisease: listDiseaseReducer,
  diseaseDetails: diseaseDetailsReducer,
  allDoctors: doctorReducer,
  addMedicine: addMedicineReducer,
  updateMedicine: updateMedicineReducer,
  medicineDelete: medicineDeleteReducer,
  listMedicine: listMedicineReducer,
  medicineDetails: medicineDetailsReducer,
  listMedicineOfDisease: listMedicineOfDiseaseReducer,
  listLabTestOfDisease: listLabTestOfDiseaseReducer,
  addLabTest: addLabTestReducer,
  updateLabTest: updateLabTestReducer,
  labTestDelete: labTestDeleteReducer,
  listLabTest: listLabTestReducer,
  labTestDetails: labTestDetailsReducer,
  addAyurveda: addAyurvedaReducer,
  updateAyurveda: updateAyurvedaReducer,
  ayurvedaDelete: ayurvedaDeleteReducer,
  listAyurveda: listAyurvedaReducer,
  ayurvedaDetails: ayurvedaDetailsReducer,
  addCart: addCartReducer,
  updateCart: updateCartReducer,
  cartDelete: cartDeleteReducer,
  cartDetailsOfCustomer: cartDetailsOfCustomerReducer,
  cartListByCustomerId: cartListByCustomerIdReducer,
  cartUploadReciept: cartUploadRecieptReducer,
  cartStatusUpdate: cartStatusUpdateReducer,
  cartDetails: cartDetailsReducer,
  listCart: listCartReducer,
  addDoctor: addDoctorReducer,
  updateDoctor: updateDoctorReducer,
  doctorDelete: doctorDeleteReducer,
  listDoctor: listDoctorReducer,
  doctorDetails: doctorDetailsReducer,
  orderList: orderListReducer,
  orderListCustomer: orderListCustomerReducer,
  orderListCustomerById: orderListCustomerByIdReducer,
  orderDetails : orderDetailsReducer,
  listAyurvedaOfDisease: listAyurvedaOfDiseaseReducer,
  uploadMedicine: uploadMedicineFileReducer,
  uploadLabTest: uploadLabTestFileReducer,
  uploadAyurveda: uploadAyurvedaFileReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
    userSignIn: { userInfo: userInfoFromStorage }
}

const middleware = [thunk]

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )
  
  export default store