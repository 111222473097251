import {
    COMPANY_ADD_REQUEST,
    COMPANY_ADD_SUCCESS,
    COMPANY_ADD_FAIL,
    COMPANY_LIST_REQUEST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_FAIL,
    COMPANY_DETAILS_REQUEST,
    COMPANY_DETAILS_SUCCESS,
    COMPANY_DETAILS_FAIL,
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_RESET,
    COMPANY_UPDATE_FAIL,
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_SUCCESS,
    COMPANY_DELETE_FAIL,
    COMPANY_DELETE_RESET,
    COMPANY_UPLOAD_REQUEST,
    COMPANY_UPLOAD_SUCCESS,
    COMPANY_UPLOAD_FAIL,
    COMPANY_UPLOAD_RESET
} from '../constants/companyConstants'

export const addCompanyReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_ADD_REQUEST:
        return { loading: true }
      case COMPANY_ADD_SUCCESS:
        return { loading: false, company: action.payload }
      case COMPANY_ADD_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const updateCompanyReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_UPDATE_REQUEST:
        return { loading: true }
      case COMPANY_UPDATE_SUCCESS:
        return { loading: false, companyData: action.payload, success:true }
      case COMPANY_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case COMPANY_UPDATE_RESET:
          return { }
      default:
        return state
    }
  }

  export const listCompanyReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_LIST_REQUEST:
        return { loading: true }
      case COMPANY_LIST_SUCCESS:
        return { loading: false, companies: action.payload.companiesList }
      case COMPANY_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const companyDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_DETAILS_REQUEST:
        return { loading: true }
      case COMPANY_DETAILS_SUCCESS:
        return { loading: false, companyData: action.payload.companyData }
      case COMPANY_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const companyDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_DELETE_REQUEST:
        return { loading: true }
      case COMPANY_DELETE_SUCCESS:
        return { loading: false, companyData: action.payload, success: true }
      case COMPANY_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case COMPANY_DELETE_RESET:
          return { }
      default:
        return state
    }
  }

  export const uploadPurchaseReceiptCompanyReducer = (state = {}, action) => {
    switch (action.type) {
      case COMPANY_UPLOAD_REQUEST:
        return { loading: true }
      case COMPANY_UPLOAD_SUCCESS:
        return { loading: false, purchaseReciept: action.payload, success: true }
      case COMPANY_UPLOAD_FAIL:
        return { loading: false, error: action.payload }
      case COMPANY_UPLOAD_RESET:
        return {}
      default:
        return state
    }
  }