import React from 'react'
import scanner1 from '../../assets/images/static_pages/eletronics/scanner/scanner1.png'
import scanner2 from '../../assets/images/static_pages/eletronics/scanner/scanner2.png'
import scanner3 from '../../assets/images/static_pages/eletronics/scanner/scanner3.png'
import scanner4 from '../../assets/images/static_pages/eletronics/scanner/scanner4.png'
import scanner5 from '../../assets/images/static_pages/eletronics/scanner/scanner5.png'
import scanner6 from '../../assets/images/static_pages/eletronics/scanner/scanner6.png'
import scanner7 from '../../assets/images/static_pages/eletronics/scanner/scanner7.png'


export default function ElectronicsScanner() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>scanner's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">HP</h5>

                                 <p className='text-dark'>
                                 Scan speed (color, single-pass 2-sided) Up to 35ppm/70ipm

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">EPSON</h5>

                                 <p className='text-dark'>
                                 4800 x 4800 dpi scanning
USB powered
Built-in kickstand
Detachable lid
Easy Photo Scan

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">CANON</h5>
                               <p className='text-dark'>
                               Canon DR-C240 Black and White Document Scanner


                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">BARCODE SCANNER</h5>
                               <p className='text-dark'>MUNBYN 2022 New Android Scanner Android 11 Barcode Scanner Handheld Mobile Computer 1D/2D QR PDF17 Zebra Scanner, IP65 8000mAh Pistol Grip Inventory Scanner, 5.2'' Screen 4G Wi-Fi GMS Data Collector</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Flat bed Scanner</h5>
                               <p className='text-dark'>Scan speed (color, single-pass 2-sided) Up to 20ppm/40ipm
</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Handheld Scanner</h5>
                               <p className='text-dark'>Handheld Barcode Scanner Application: Commercial
9500 INR
</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={scanner7} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Drum scanner</h5>
                               <p className='text-dark'>Handheld Barcode Scanner Application: Commercial</p>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
