export const DOCTOR_ADD_REQUEST = "DOCTOR_ADD_REQUEST"
export const DOCTOR_ADD_SUCCESS = "DOCTOR_ADD_SUCCESS"
export const DOCTOR_ADD_FAIL = "DOCTOR_ADD_FAIL"
export const DOCTOR_ADD_RESET = "DOCTOR_ADD_RESET"

export const DOCTOR_LIST_REQUEST = "DOCTOR_LIST_REQUEST"
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS"
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL"

export const DOCTOR_DETAILS_REQUEST = "DOCTOR_DETAILS_REQUEST"
export const DOCTOR_DETAILS_SUCCESS = "DOCTOR_DETAILS_SUCCESS"
export const DOCTOR_DETAILS_FAIL = "DOCTOR_DETAILS_FAIL"

export const DOCTOR_UPDATE_REQUEST = "DOCTOR_UPDATE_REQUEST"
export const DOCTOR_UPDATE_SUCCESS = "DOCTOR_UPDATE_SUCCESS"
export const DOCTOR_UPDATE_FAIL = "DOCTOR_UPDATE_FAIL"

export const DOCTOR_DELETE_REQUEST = "DOCTOR_DELETE_REQUEST"
export const DOCTOR_DELETE_SUCCESS = "DOCTOR_DELETE_SUCCESS"
export const DOCTOR_DELETE_FAIL = "DOCTOR_DELETE_FAIL"
export const DOCTOR_DELETE_RESET = "DOCTOR_DELETE_RESET"