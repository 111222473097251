export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL"

export const CUSTOMER_ORDER_LIST_REQUEST = "CUSTOMER_ORDER_LIST_REQUEST"
export const CUSTOMER_ORDER_LIST_SUCCESS = "CUSTOMER_ORDER_LIST_SUCCESS"
export const CUSTOMER_ORDER_LIST_FAIL = "CUSTOMER_ORDER_LIST_FAIL"

export const CUSTOMER_ORDER_DETAILS_REQUEST = "CUSTOMER_ORDER_DETAILS_REQUEST"
export const CUSTOMER_ORDER_DETAILS_SUCCESS = "CUSTOMER_ORDER_DETAILS_SUCCESS"
export const CUSTOMER_ORDER_DETAILS_FAIL = "CUSTOMER_ORDER_DETAILS_FAIL"

export const CUSTOMER_ORDER_LIST_BY_ID_REQUEST = "CUSTOMER_ORDER_LIST_BY_ID_REQUEST"
export const CUSTOMER_ORDER_LIST_BY_ID_SUCCESS = "CUSTOMER_ORDER_LIST_BY_ID_SUCCESS"
export const CUSTOMER_ORDER_LIST_BY_ID_FAIL = "CUSTOMER_ORDER_LIST_BY_ID_FAIL"