import React from 'react'
import network1 from '../../assets/images/static_pages/eletronics/network/network1.png'
import network2 from '../../assets/images/static_pages/eletronics/network/network2.png'
import network3 from '../../assets/images/static_pages/eletronics/network/network3.png'
import network4 from '../../assets/images/static_pages/eletronics/network/network4.png'
export default function ElectronicsNetwork() {
  return (
    <>
           <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>network's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={network1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Modem</h5>

                                 <p className='text-dark'>
                                 NETGEAR AC1200 (8x4) WiFi DOCSIS 3.0 Cable Modem Router (C6220)


                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={network2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">WIFI</h5>

                                 <p className='text-dark'>
                                 Connectivity Type 
Wireless or Wi-Fi
WAN Ports 
2
Voltage 
230 V
Frequency 
50 / 60 Hz
Packaging Type 
Carton Box
Product Type 
WiFi Router


                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={network3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Dongal</h5>
                               <p className='text-dark'>
                               Airtel AMF-311WW Data Card  (Black)

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={network4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">ROUTER</h5>
                               <p className='text-dark'>New-generation WiFi 6 - Enjoy ultrafast speeds up to 5400 Mbps with the latest WiFi 6 (802.11ax) and 160MHz channels</p>
                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
