export const DISEASE_ADD_REQUEST = "DISEASE_ADD_REQUEST"
export const DISEASE_ADD_SUCCESS = "DISEASE_ADD_SUCCESS"
export const DISEASE_ADD_FAIL = "DISEASE_ADD_FAIL"
export const DISEASE_ADD_RESET = "DISEASE_ADD_RESET"

export const DISEASE_LIST_REQUEST = "DISEASE_LIST_REQUEST"
export const DISEASE_LIST_SUCCESS = "DISEASE_LIST_SUCCESS"
export const DISEASE_LIST_FAIL = "DISEASE_LIST_FAIL"

export const DISEASE_DETAILS_REQUEST = "DISEASE_DETAILS_REQUEST"
export const DISEASE_DETAILS_SUCCESS = "DISEASE_DETAILS_SUCCESS"
export const DISEASE_DETAILS_FAIL = "DISEASE_DETAILS_FAIL"

export const DISEASE_UPDATE_REQUEST = "DISEASE_UPDATE_REQUEST"
export const DISEASE_UPDATE_SUCCESS = "DISEASE_UPDATE_SUCCESS"
export const DISEASE_UPDATE_FAIL = "DISEASE_UPDATE_FAIL"

export const DISEASE_DELETE_REQUEST = "DISEASE_DELETE_REQUEST"
export const DISEASE_DELETE_SUCCESS = "DISEASE_DELETE_SUCCESS"
export const DISEASE_DELETE_FAIL = "DISEASE_DELETE_FAIL"