import React from 'react'
import doc from '../assets/images/doctor/doc8.jpg'
import { useLocation } from 'react-router-dom'
export default function DoctorDetail(props) {
  const {doctor, onSelect} = props
  // const location = useLocation()
  // const { data } = location.state
  // console.log(data)
  return (  
    <>
        <div className="container">
          <div className="row justify-content-center my-5">
            <div className="col-md-4 mt-5">
                <div className='box'>
                    {/* <img src={data.photo} className='w-100' height={'250px'} /> */}
                    <img src={doc} style={{height: '250px'}} />

                </div>
            </div>
            <div className="col-md-4">
                <small className='badge rounded-pill  text-bg-info'>{doctor.designation}</small>
                <h1 className='fw-bolder'>{doctor.name}</h1>
                <div>
                    <p><span className='text-dark'>{doctor.email}</span> | <span className='text-dark'>{doctor.phone}</span></p>
                </div>
                <hr />
                <p className='lead'>{doctor.short_intro}</p>
                <p className='lead'>{doctor.description}</p>
            </div>
           </div>
        </div>
    </>
  )
}