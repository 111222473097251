import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  CUSTOMER_ORDER_LIST_REQUEST,
  CUSTOMER_ORDER_LIST_SUCCESS,
  CUSTOMER_ORDER_LIST_FAIL,
  CUSTOMER_ORDER_DETAILS_REQUEST,
  CUSTOMER_ORDER_DETAILS_SUCCESS,
  CUSTOMER_ORDER_DETAILS_FAIL,
  CUSTOMER_ORDER_LIST_BY_ID_REQUEST,
  CUSTOMER_ORDER_LIST_BY_ID_SUCCESS,
  CUSTOMER_ORDER_LIST_BY_ID_FAIL,
} from '../constants/orderConstants'

export const orderListReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_LIST_REQUEST:
        return { loading: true }
      case ORDER_LIST_SUCCESS:
        return { loading: false, orderData: action.payload.ordersList }
      case ORDER_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const orderListCustomerReducer = (state = {}, action) => {
    switch (action.type) {
      case CUSTOMER_ORDER_LIST_REQUEST:
        return { loading: true }
      case CUSTOMER_ORDER_LIST_SUCCESS:
        return { loading: false, orderData: action.payload.ordersList}
      case CUSTOMER_ORDER_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const orderListCustomerByIdReducer = (state = {}, action) => {
    switch (action.type) {
      case CUSTOMER_ORDER_LIST_BY_ID_REQUEST:
        return { loading: true }
      case CUSTOMER_ORDER_LIST_BY_ID_SUCCESS:
        return { loading: false, orderData: action.payload.ordersList }
      case CUSTOMER_ORDER_LIST_BY_ID_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const orderDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case CUSTOMER_ORDER_DETAILS_REQUEST:
        return { loading: true }
      case CUSTOMER_ORDER_DETAILS_SUCCESS:
        return { loading: false, orderData: action.payload.orderInfo }
      case CUSTOMER_ORDER_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
