import React from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom';

import pharma1 from '../assets/images/pharma1.jpg'
import pharma2 from '../assets/images/pharma2.webp'
import pharma3 from '../assets/images/pharma3.webp'

import electronic1 from '../assets/images/electronic1.jpg'
import electronic2 from '../assets/images/electronic2.jpg'
import electronic3 from '../assets/images/electronic3.jpg'

import supply1 from '../assets/images/supply1.jpg'
import supply2 from '../assets/images/supply2.jpg'
import supply3 from '../assets/images/supply3.jpg'
import supply4 from '../assets/images/supply4.webp'
import supply5 from '../assets/images/supply5.jpg'




export default function Services() {
    const navigate = useNavigate();
    const location = useLocation();
    const current_page = location.state;

  return (
    <>
      <div className="container my-4">
            <div className="row">
                <div className="col-md-12">
                   {
                      current_page.data == 'supply_chain' ? 
                       <span> <h1 className=' text-center fw-bolder my-3'>Supply Chain</h1> <hr className='mt-2 mb-5' /></span>
                       
                       : current_page.data == 'pharma' ?
                       <span><h1 className=' text-center fw-bolder my-3'>Pharma</h1> <hr className='mt-2 mb-5' /></span>
                       :
                       <span><h1 className=' text-center fw-bolder my-3'>Peripherals and Accessories</h1> <hr className='mt-2 mb-5' /></span>
                    }
                </div>

                {
                      current_page.data == 'supply_chain' ? 
                            <div className='row'>
                                    <div className="col-md-6">
                                        <img src={supply1} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Domestic Services</h1>
                                    </div>

                                    <div className="col-md-6 text-center mt-5">
                                    <h1 className='fw-bold mt-5'>Export</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={supply2} style={{width: '100%'}} alt=""/>
                                    </div>

                                    <div className="col-md-6">
                                        <img src={supply3} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Import</h1>
                                    </div>

                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Warehouse</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={supply4} style={{width: '100%'}} alt=""/>
                                    </div>
                                    

                                    <div className="col-md-6">
                                        <img src={supply5} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Custom Clearance</h1>
                                    </div>
                            </div>
                       
                       : current_page.data == 'pharma' ?
                            <div className='row'>
                                    <div className="col-md-6">
                                        <img src={pharma1} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Devices</h1>
                                    </div>

                                    <div className="col-md-6 text-center mt-5">
                                    <h1 className='fw-bold mt-5'>Supplements</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={pharma2} style={{width: '100%'}} alt=""/>
                                    </div>

                                    <div className="col-md-6">
                                        <img src={pharma3} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Cosmetics</h1>
                                    </div>
                            </div>
                       :
                       <div className='row'>
                                    <div className="col-md-6">
                                        <img src={electronic1} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Printer</h1>
                                    </div>

                                    <div className="col-md-6 text-center mt-5">
                                    <h1 className='fw-bold mt-5'>Laptop</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={electronic2} style={{width: '100%'}} alt=""/>
                                    </div>

                                    <div className="col-md-6">
                                        <img src={electronic3} style={{width: '100%'}} alt=""/>
                                    </div>
                                    <div className="col-md-6 text-center mt-5">
                                        <h1 className='fw-bold mt-5'>Toner</h1>
                                    </div>
                            </div>
                    }

             
            </div>
      
      </div>      
    </>
  )
}
