import { bgcolor } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {Link, useNavigate} from 'react-router-dom';
import b1 from '../assets/images/blog/b1.jpg'
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { API_ENDPOINT } from '../rd-utils/constants';
import { getDateFormat } from '../rd-utils/util';
import parse from 'html-react-parser';

export default function BlogDetail(props) {
  const loc = document.location.href.replace(/\/+$/, "")
  const keysUrl = loc.split('/')
  const linkCode = keysUrl[4]
  const [blog, setBlog] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    getBlog()
  }, [])

  function getBlog(){
    setLoading(true)
    axios.get(API_ENDPOINT+'user/get-blog/'+linkCode).then(res=>{
      setLoading(false)
      let d = res.data
      if(d.success){
        setBlog(d.message)
      }
    })
  }
  return (
       <div className="container">
        <CustomCircularP show={loading}/>
          {Object.keys(blog).length>0 &&
           <div style={{margin: '12px auto', maxWidth: '800px', padding: '8px'}}>
                <img src={blog.image} alt={blog.image} style={{borderRadius: '8px', maxHeight: '300px', display: 'block', margin: '4px auto'}}/>
                <h2>{blog.header}</h2>
                <div style={{fontSize: '14px', fontStyle: 'italic'}}>Category: {blog.category}</div>
                <div style={{fontSize: '13px'}}>Posted On: {getDateFormat(new Date(blog.createdOn))}</div>
                <hr />
                <div style={{marginTop: '16px'}}>{parse(blog.desc)}</div>
            </div>
          }
        </div>
  )
}
