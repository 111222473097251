import React, { useEffect, useState, useCallback } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CartBox from '../components/Cart/CartBox'
import { Link, useNavigate } from 'react-router-dom'
import { addCart, cartDetailsOfCustomer, proceedOrderMail, uploadRecieptCart } from '../redux/actions/cartActions';

import Paginations from '../components/Pagination/Paginations'
import data from '../components/data'
import ProductCart from '../components/Product/ProductCart'
import Preloader from '../components/PreLoader'
import axios from 'axios';
import { API_ENDPOINT} from '../rd-utils/constants';
import { isCursorAtStart } from '@testing-library/user-event/dist/utils';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { getHeader, isLoggedIn } from '../rd-utils/util';
import { toast } from 'react-toastify';
import ItemImage from './ItemImage';

export default function Cart() {
    const navigate = useNavigate();

    const [cartData, setCartData] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
       if(!isLoggedIn()){
            navigate('/login')
       }else{
            loadCart()
       }
    }, [])

    function loadCart(){
        axios.get(API_ENDPOINT+'cart/get-my-cart', getHeader()).then(res=>{
            let d = res.data
            if(d.success){
                setCartData(d.message)
            }
        })
    }

    const handleIncrement = (pid) => {
        setLoading(true)
        axios.post(API_ENDPOINT+'cart/cart-inc-item', {productId: pid}, getHeader()).then(res=>{
            setLoading(false)
            let d = res.data
            if(d.success){
                let mdA = [...cartData]
                let md = mdA.find(p=>p.productId === pid)
                md.count = md.count + 1
                setCartData(mdA)
            }
        })
    }
  
    const handleDecrement = (pid) => {
        setLoading(true)
        axios.post(API_ENDPOINT+'cart/cart-dec-item', {productId: pid}, getHeader()).then(res=>{
            setLoading(false)
            let d = res.data
            if(d.success){
                if(d.message === 'Deleted Successfully'){
                    let mdA = [...cartData]
                    let md = mdA.findIndex(p=>p.productId === pid)
                    mdA.splice(md,1)
                    setCartData(mdA)
                }else{
                    let mdA = [...cartData]
                    let md = mdA.find(p=>p.productId === pid)
                    md.count = md.count - 1
                    setCartData(mdA)
                }
            }
        })
    }
    const requestQuote = ()=>{

        setLoading(true)
        axios.post(API_ENDPOINT+'user/request-quote', {}, getHeader()).then(res=>{
            let d = res.data
            setLoading(false)
            if(d.success){
                navigate('/dashboard')
            }else{
                toast(d.message)
            }
        })
    }

   
  return (
    <>
        <div className="container">
            <CustomCircularP show = {loading}/>
            <div className="row">
                <div className="col-md-12 my-5">
                    <h3 className='my-4'><b> Cart Summary </b></h3>
                        <div className="d-flex">
                            <div className="me-auto p-2 ">
                                  <h6 className='card-title text-muted'>PRODUCTS</h6>
                            </div>

                           {/* <div className="p-2">
                                <button style={{border: "none"}} className="fw-bold cart_proceed" >PROCEED</button>
                            </div> */}
                        
                            <div className="p-2">
                                 <Link to={'/product'} className="text-info fw-bold">ADD MORE ITEMS <i className="fa-solid fa-plus"></i></Link>
                            </div>
                        </div>

                        <div className="table-responsive table-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        {/* <th>Approval Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {cartData.map((cd, i)=>(
                                     <tr key={i} className="cart-item">
                                     <td>
                                         <ItemImage images={cd.images} alt={cd.name}/>
                                     </td>
                                     <td>
                                         <h5 className='fw-bold'>{cd.name}</h5>
                                     </td>
                                     <td>
                                     <div className="cart-btn my-4">
                                        <button className='btn btn-primary me-2' onClick={()=>handleDecrement(cd.productId)}> - </button>
                                        <span>{cd.count}</span>
                                        <button className='btn btn-primary ms-2' onClick={()=>handleIncrement(cd.productId)}> + </button>
                                    </div>
                                     </td>
                                     {/* <td>
                                         <h5 style={{fontWeight: "500"}}> <span>{items.adminApprovalStatus}</span></h5>
                                     </td> */}
                                     
                                 </tr>
                                ))}
                                </tbody>
                            </table>
                           {
                                cartData.length>0?
                                <div>
                                <button onClick={requestQuote} className="btn btn-outline-info btn-lg" >Request A Quote</button>
                                </div>:''
                           }
                        </div>
                        {/* {(cartData && cartData.length>0) &&<div className='text-end'> 
                            <button className="btn btn-outline-info btn-lg" disabled = {!((cartData && cartData.items) && cartData.items.length>0)}>PROCEED</button>
                        </div>} */}
                            

                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-12">
                    <h5><b> MOST SELLING PRODUCTS </b></h5>
                </div> */}

              

                {/* { currentData.map((item) => {
                            return (
                                <div className="col-md-3">
                                    {console.log("item", item)}
                                    <ProductCart id={item.id} name={item.title} data={item} />
                                </div>
                            );
                        })}   */}
               </div>
                </div>
    </>
  )
}
