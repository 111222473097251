import React, { useState, lazy, Suspense } from 'react'; 
import {useSelector} from 'react-redux'
import { Routes, Route, useNavigate, Navigate  } from "react-router-dom";


import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';

import Logistics from './pages/static_pages/logistics';
import LogisticsInternational from './pages/static_pages/logisticsInternational';
import LogisticsDomestic from './pages/static_pages/LogisticsDomestic';
import LogisticsWarehouse from './pages/static_pages/LogisticsWarehouse';
import LogisticsOcean from './pages/static_pages/LogisticsOcean';
import LogisticsCustom from './pages/static_pages/LogisticsCustom';
import LogisticsFreight from './pages/static_pages/LogisticsFreight';


import Electronics from './pages/static_pages/electronics';
import ElectronicsDesktop from './pages/static_pages/ElectronicsDesktop';
import ElectronicsLaptop from './pages/static_pages/ElectronicsLaptop';
import ElectronicsMobile from './pages/static_pages/ElectronicsMobile';
import ElectronicsCamera from './pages/static_pages/ElectronicsCamera';
import ElectronicsPrinter from './pages/static_pages/ElectronicsPrinter';
import ElectronicsScanner from './pages/static_pages/ElectronicsScanner';
import ElectronicsPeripherals from './pages/static_pages/ElectronicsPeripherals';
import ElectronicsAccessories from './pages/static_pages/ElectronicsAccessories';
import ElectronicsNetwork from './pages/static_pages/ElectronicsNetwork';
import ElectronicsProjector from './pages/static_pages/ElectronicsProjector';
import ElectronicsIt from './pages/static_pages/ElectronicsIt';

import CorporateGift from './pages/static_pages/CorporateGift';


import Disclaimer from './pages/Disclaimer';
import Faq from './pages/Faq';


import UserDashboard from './pages/user/UserDashboard';
import Profile from './pages/user/Profile';
import MyOrder from './pages/user/MyOrder'
import Help from './pages/user/Help'

import Contact from './pages/Contact';
import Footer from './pages/include/Footer'; 

import AdminLogin from './admin-pages/AdminLogin';
import Login from './components/Auth/Login';

import Register from './components/Auth/Register';
import Navbar from './components/Navbar';
import Medicine from './pages/Medicine';  
import SubMedicine from './pages/SubMedicine'; 
import LabTest from './pages/LabTest';
import Ayurveda from './pages/Ayurveda';

import Cart from './pages/Cart'; 

import Product from './pages/Product'; 
import ProductDetail from './pages/ProductDetail'; 

import NotFoundPage from './pages/NotFoundPage'; 
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';

import Doctors from './pages/Doctors'; 
import DoctorDetail from './pages/DoctorDetail';

import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
 

import Dashboard from './admin-pages/Dashboard';
import UserList from './admin-pages/UserList';
import AddCompany from './admin-pages/AddCompany';
import AddMedicine from './admin-pages/AddMedicine';
import CompanyList from './admin-pages/CompanyList';
import Admin from './admin-pages/Admin';
import Ecomerce from './Ecomerce';
import Verification from './rd-pages/Verification';
import DiseaseMedicine from './pages/DiseaseMedicine';
import DiseaseLabTest from './pages/DiseaseLabTest';
import DiseaseAyurveda from './pages/DiseaseAyurveda';

// import PreLoader from './components/PreLoader';
// const Dashboard = lazy => import('./admin-pages/Dashboard.jsx')
function App() {
  const navigate = useNavigate();  
  const [user, setLoginUser] = useState({})
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const userSignIn = useSelector(state => state.userSignIn)
  const {loading, error, userInfo} = userSignIn
  return (
    <>
    {/* <PreLoader /> */}
      {/* <Suspense fallback={<div>Loading</div>}> */}
          <Routes>
            {/* <Route path="/dashboard" element={ user && user.success == true ? <Dashboard /> : <Navigate replace to={"/login"} />} ></Route> */}
            <Route exact path="verification/:code" element={<Verification />}></Route>
            <Route exact path='/' element={<Ecomerce />}>
              <Route exact path="" element={<Home />}></Route>
              <Route exact path="about" element={<About />}></Route>
              <Route exact path="disclaimer" element={<Disclaimer />}></Route>
              <Route exact path="faq" element={<Faq />}></Route>
              <Route exact path="services" element={<Services />}></Route>
              
              <Route exact path="logistics" element={<Logistics />}></Route>
              <Route exact path="logistics/logistics-international" element={<LogisticsInternational />}></Route>
              <Route exact path="logistics/logistics-domestic" element={<LogisticsDomestic />}></Route>
              <Route exact path="logistics/logistics-warehouse" element={<LogisticsWarehouse />}></Route>
              <Route exact path="logistics/logistics-ocean" element={<LogisticsOcean />}></Route>
              <Route exact path="logistics/logistics-custom" element={<LogisticsCustom />}></Route>
              <Route exact path="logistics/logistics-freight" element={<LogisticsFreight />}></Route>

              
              <Route exact path="electronics" element={<Electronics />}></Route>
              <Route exact path="electronics/electronics-desktop" element={<ElectronicsDesktop />}></Route>
              <Route exact path="electronics/electronics-laptop" element={<ElectronicsLaptop />}></Route>
              <Route exact path="electronics/electronics-mobile" element={<ElectronicsMobile />}></Route>
              <Route exact path="electronics/electronics-camera" element={<ElectronicsCamera />}></Route>
              <Route exact path="electronics/electronics-printer" element={<ElectronicsPrinter />}></Route>
              <Route exact path="electronics/electronics-scanner" element={<ElectronicsScanner />}></Route>
              <Route exact path="electronics/electronics-peripherals" element={<ElectronicsPeripherals />}></Route>
              <Route exact path="electronics/electronics-accessories" element={<ElectronicsAccessories />}></Route>
              <Route exact path="electronics/electronics-network" element={<ElectronicsNetwork />}></Route>
              <Route exact path="electronics/electronics-projector" element={<ElectronicsProjector />}></Route>
              <Route exact path="electronics/electronics-it" element={<ElectronicsIt />}></Route>

              <Route exact path="corporate-gifts" element={<CorporateGift />}></Route>

              
              

              <Route exact path="medicine" element={<Medicine />}></Route>
              <Route exact path="medicine/:disease" element={<DiseaseMedicine />}></Route>
              <Route exact path="labtest/:disease" element={<DiseaseLabTest/>}></Route>
              <Route exact path="ayurveda/:disease" element={<DiseaseAyurveda />}></Route>
              <Route exact path="labtest" element={<LabTest />}></Route>
              <Route exact path="ayurveda" element={<Ayurveda />}></Route>
              {/* <Route exact path="ayurveda/:disease" element={<Ayurveda />}></Route> */}
              <Route exact path="/sub-medicine/:disease" element={<SubMedicine />}></Route>

              <Route exact path="product/:name" element={<Product />}></Route>
              <Route exact path="product" element={<Product />}></Route>
              <Route exact path="product/category/:category" element={<Product />}></Route>
              <Route exact path="product-detail/:id" element={<ProductDetail />}></Route>
              {/* <Route exact path="login" element={<Login  setLoginUser={setLoginUser} />}></Route> */}
              <Route exact path="login" element={<Login />}></Route>
              <Route exact path="login/msg" element={<Login />}></Route>
              <Route exact path="register" element={<Register />}></Route>
              <Route exact path="contact" element={<Contact />}></Route>
              <Route exact path="forgot-password" element={<ForgotPassword />}></Route>
              <Route exact path="pwd-reset/:id" element={<ResetPassword />}></Route>
              <Route exact path="/doctors" element={<Doctors />}></Route>
              <Route path="/doctor-detail" element={<DoctorDetail />}></Route>
 
              <Route exact path="/blog" element={<Blog />}></Route>
              <Route path="blog-detail/:id" element={<BlogDetail />}></Route>

              <Route exact path="/cart" element={<Cart />}></Route>
              
              <Route path="/dashboard" element={<UserDashboard />}></Route>
               {/* <Route path="/dashboard/order" element={<MyOrder />}></Route>
               <Route path="/dashboard/help" element={<Help />}></Route> */}
            </Route>

           
            <Route path="/error" element={<NotFoundPage />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/reset-password" element={<ResetPassword />}></Route>
            {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
            <Route path="/doctors" element={<Doctors />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
        </Routes>
          {/* </Suspense> */}
    </>
  );
}

export default App;



