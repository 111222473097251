import React from 'react'
import hp1 from '../../assets/images/static_pages/eletronics/laptop/hp1.png'
import hp2 from '../../assets/images/static_pages/eletronics/laptop/hp2.png'
import hp3 from '../../assets/images/static_pages/eletronics/laptop/hp3.png'
import hp4 from '../../assets/images/static_pages/eletronics/laptop/hp4.png'
import hp5 from '../../assets/images/static_pages/eletronics/laptop/hp5.png'


import dell1 from '../../assets/images/static_pages/eletronics/laptop/dell1.png'
import dell2 from '../../assets/images/static_pages/eletronics/laptop/dell2.png'
import dell3 from '../../assets/images/static_pages/eletronics/laptop/dell3.png'
import dell4 from '../../assets/images/static_pages/eletronics/laptop/dell4.png'
import dell5 from '../../assets/images/static_pages/eletronics/laptop/dell5.png'

import acer1 from '../../assets/images/static_pages/eletronics/laptop/acer.png'
import acer2 from '../../assets/images/static_pages/eletronics/laptop/acer2.png'
import acer3 from '../../assets/images/static_pages/eletronics/laptop/acer3.png'
import acer4 from '../../assets/images/static_pages/eletronics/laptop/acer4.png'

import lenovo1 from '../../assets/images/static_pages/eletronics/laptop/lenovo1.png'
import lenovo2 from '../../assets/images/static_pages/eletronics/laptop/lenovo2.png'
import lenovo3 from '../../assets/images/static_pages/eletronics/laptop/lenovo3.png'
import lenovo4 from '../../assets/images/static_pages/eletronics/laptop/lenovo4.png'
import lenovo5 from '../../assets/images/static_pages/eletronics/laptop/lenovo5.png'

import mac1 from '../../assets/images/static_pages/eletronics/laptop/mac1.png'
import mac2 from '../../assets/images/static_pages/eletronics/laptop/mac2.png'
import mac3 from '../../assets/images/static_pages/eletronics/laptop/mac3.png'
import mac4 from '../../assets/images/static_pages/eletronics/laptop/mac4.png'
import mac5 from '../../assets/images/static_pages/eletronics/laptop/mac5.png'

import asus1 from '../../assets/images/static_pages/eletronics/laptop/asus1.png'
import asus2 from '../../assets/images/static_pages/eletronics/laptop/asus2.png'
import asus3 from '../../assets/images/static_pages/eletronics/laptop/asus3.png'

export default function ElectronicsLaptop() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>Laptop's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>Hp Laptop's</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={hp1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">

                                 <p className='text-dark'>

                                 12th Generation Intel® Core™ i5 processor
                                Windows 11 Home
                                39.6 cm (15.6) diagonal, FHD (1920 x 1080)
                                Intel® Iris® Xᵉ Graphics
                                8 GB DDR4-3200 MHz RAM (2 x 4 GB)
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={hp2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 12th Generation Intel® Core™ i3 processor
                                 Windows 11 Home Single Language
                                 35.6 cm (14) diagonal FHD IPS display
                                 Intel® UHD Graphics
                                 8 GB DDR4-3200 MHz RAM (onboard)
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={hp3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 12th Generation Intel® Core™ i5 processor
                                 Windows 11 Home Single Language
                                 35.6 cm (14) diagonal FHD IPS display
                                 Intel® Iris® Xᵉ Graphics
                                 8 GB DDR4-3200 MHz RAM (onboard)
                                 512 GB PCIe® NVMe™ TLC M.2 SSD
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={hp4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 12th ® Core™ i7 processor
                                Windows 11 Pro
                                 34.3 cm (13.5) diagonal, WUXGA+ (1920 x 1280), touch, IPS, BrightView, 1000 nits, 72% NTSC, HP Sure View integrated privacy screen
                                 Intel® Iris® Xᵉ Graphics
                                 32 GB LPDDR5-6400 MHz RAM (onboard)
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={hp5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 12th Generation Intel® Core™ i7 processor
                                Windows 11 Home
                                43.9 cm (17.3) diagonal QHD display 165 Hz 3 ms response time
                                NVIDIA® GeForce RTX™ 3070 Ti
                                32 GB DDR5-4800 MHz RAM
                                1 TB PCIe® NVMe™ SSD 
                                 </p>
                            </div>
                          </div>
                        </div>
                  </div>
                    <hr />
                    <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>Dell Laptop's</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={dell1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                    Dell Inspiron 14 Laptop - w/ 
                                    12th gen Intel Core - 14" FHD Screen - 8GB - 512G

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={dell2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Dell Inspiron 14 2-in-1 Laptop - w/ 12th gen Intel Core - 14" FHD Touch Screen - 8GB - 256G
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={dell3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Dell 13.3" XPS 13 9370 Multi-Touch Laptop | Intel Core i7 | 16GB RAM | 512GB SSD

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={dell4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Dell Latitude 5480 I7 | 7th-Gen | 16 RAM | 512 SSD | 14 inch  Laptop
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={dell5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Dell Inspiron 15 5000 Intel Core i5 15 inches FHD, 8GB Memory, 512GB Solid State Drive, Windows 10
                                 </p>
                            </div>
                          </div>
                        </div>
                  </div>
                  <hr />
                  <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>Acer Laptop's</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={acer1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Acer Aspire 5  Intel Core i5 12th gen (12-Cores) (8 GB/512 GB SSD/Windows 11 Home/4 GB Graphics


                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={acer2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Acer Aspire 3 Laptop Intel Core i3 11th Generation (4 GB/256 GB SSD/Windows 11 Home/Intel UHD Graphics /1.7 Kg/Silver) A315-58 with 15.6-inch (39.6

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={acer3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Acer Aspire 5 Core I5 11th Gen - 8 Gb/512 Gb Ssd/Windows 11 Home A515-56-51ev Thin And Light Laptop


                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={acer4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                  Acer Aspire 7 Core i7-8750H 2.2GHz 1TB 8GB 15.6" (1920x1080) BT WIN10 Webcam NVIDIA GTX 1050 4096MB BLACK Backlit Keyboard FP Reader | NH.

                                 </p>
                            </div>
                          </div>
                        </div>
                       
                  </div>
                  <hr />
                    <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>Lenovo Laptop's</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={lenovo1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Lenovo Core I7 10th Gen - 16 Gb/512 Gb Ssd/Windows 10 Pro Thinkpad X1 Carbon Thin And Light Laptop
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={lenovo2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 ThinkPad T15 Gen 2 156 FHD Business Laptop Computer Intel QuadCore i51135G7 up to 42GHz Beat i71065G7 16GB DDR4 RAM 512GB PCIe SSD WiFi 6 B
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={lenovo3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Lenovo ThinkPad X1 Carbon Intel Core i7 11th Gen 14" (35.56cms) Laptop (16GB RAM / 1TB SSD/ Windows 11 Professional/ Black/ 1.13Kg), 20XWS1XJ00
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={lenovo4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Lenovo Thinkpad P16 Intel Core I7-12800hx, 16c, 16.0" Wquxga 3840x2400 Ips 600nits Anti-glare, Nvidia Rtx A1000 4gb Gddr6, 16gb Ddr5 Ram, 512gb

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={lenovo5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Lenovo Thinkpad P15v 11th Gen Intel Core i7 11800H -15.6 inches FHD IPS Mobile (16GB RAM /1TB SSD /Windows 10 Pro /Nvidia Quadro T600 4GB Graphics /
                                 </p>
                            </div>
                          </div>
                        </div>
                  </div>
                  <hr />
                    <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>Apple Macbook</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={mac1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                  Apple Macbook Pro 13.3'' Display Laptop, Intel Core i5 2.5 GHz Processor, 16GB RAM, 512GB SSD, Mac OS, Silver | A1278-16GB

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={mac2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Apple MacBook Pro 2017, Intel Core i5, 16GB RAM, 256 GB SSD, 13.3-Inch Retina Display - Silver

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={mac3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Apple MacBook Pro with Retina display - Intel Core i7 2.2 GHz - Iris Pro Graphics - 16 GB RAM - 256 GB SSD - 15.4" IPS 2880 x 1800 - Wi-Fi 5 - kbd: US

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={mac4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Apple Macbook Pro 16" with Touchbar (2019 Model) 2.6Ghz 6-Core, Intel Core i7 9750H, 16gb RAM, 512 SSD, 4GB VGA Radeon Pro 5300M

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={mac5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Apple Macbook Pro A1398 (Mid 2015) Laptop With 15.4-Inch Display, Intel Core i7 Processor 5th Gen, 16GB RAM, 256GB SSD, 1.5GB Intel Iris Gra

                                 </p>
                            </div>
                          </div>
                        </div>
                  </div>
                  <hr />
                    <div className='row my-4'>
                        <div className="col-md-12">
                            <h2 className='fw-bold'>ASUS Laptop's</h2>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={asus1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                  ASUS ZenBook Duo 14 (2021) Dual Screen Laptop, 14-inch (35.56 cm) FHD, Intel Core i5-1155G7 11th Gen, (8GB/512GB SSD/Windows 11/Office 2021/Iris Xe

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={asus2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                  Asus Vivobook 15 Core I5 11th Gen - 8 Gb/512 Gb Ssd/Windows 11 Home X515ea-ej522ws Thin And Light Laptop

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={asus3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                 <p className='text-dark'>
                                 Asus Core I5 11th Gen - 16 Gb/1 Tb Hdd/256 Gb Ssd/Windows 11 Home X712ea-au511ws Laptop

                                 </p>
                            </div>
                          </div>
                        </div>

                        
                  </div>
            </div>
        </div>

    </>
  )
}
