import { Button } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItemImage from '../ItemImage';

export default function ProductItems(props) {
    const {order, back} = props

  return (
    <>

      <div>
        <Button onClick={back} variant='outlined' startIcon={<ArrowBackIosIcon />}>Back</Button>
          <h5 className='my-3 custom-text-color fw-bold'>Order Id: #{order.order_id}</h5>
          <div className="table-responsive table-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        {/* <th>Approval Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {order.products.map((cd, i)=>(
                                     <tr key={i} className="cart-item">
                                     <td>
                                         <ItemImage images={cd.images} alt={cd.name}/>
                                     </td>
                                     <td>
                                         <h5 className='fw-bold'>{cd.name}</h5>
                                     </td>
                                     <td>
                                     <span>{cd.count}</span>
                                     </td>
                                     {/* <td>
                                         <h5 style={{fontWeight: "500"}}> <span>{items.adminApprovalStatus}</span></h5>
                                     </td> */}
                                     
                                 </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
      </div>


    </>
  )
}
