import React from 'react'
// import p1 from '../../assets/images/products/p-1.jpg'
import p2 from '../../assets/images/products/p-2.jpg'
// import p3 from '../../assets/images/products/p-3.jpg'
// import p4 from '../../assets/images/products/p-4.jpg'
// import p5 from '../../assets/images/products/p-5.jpg'
import { Link } from 'react-router-dom'


export default function ProductCart(props) {
    const {item} = props
    let images = item.images
    let previewImage = images.find(t=>t.isDefault)
    if(previewImage == undefined){
        previewImage = images[0]
    }

    return (
    <>
        {/* <div className='col-md-4 '> */}
        <div className="effect my-3 box">
            <div className='text-center'>
                    <Link to={`/product-detail/${item._id}`}><img  src={previewImage.url}  className="product-img" alt=""  /></Link>
            </div>
            <div className='text-center'>
                <h4><b><Link to={`/product-detail/${item._id}`}>{item.name}</Link></b></h4>
                <div className="d-flex justify-content-center">
                    <div className="p-2">
                        <span className="badge rounded-pill text-secondary border">{item.manufacturer}</span>
                    </div>
                    {/* <div className="p-2">
                        <span className="badge rounded-pill text-secondary border">{item && item.manufacturerName}</span>
                    </div> */}
                </div>
                <p className='text-muted m-0'>{item.description.substring(0,24)}</p>
                <Link to={`/product-detail/${item._id}`} className="btn btn-info w-100 my-2 btn-sm " style={{backgroundColor: '#66bcee'}}>Add To Cart</Link>
            </div>
        </div>
        {/* </div> */}
      
    </>
  )
}
