import React from 'react'
import { Link  } from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <>
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-8 text-center">
                    <div className="card " style={{boxShadow: '10px 10px lightblue'}}>
                        <div className="card-body">
                        <h1 className='fw-bolder'style={{fontSize: 80}}>404</h1>
                        <h2 className='fw-bolder' >Whoops, Page Not Found</h2>
                        <h5 className='fw-lighter my-5'>Whoops, The page you were  looking for could not be found. It might have been removed renamed, or did't exist in the first place.</h5>
                         <Link to={"/"} className="btn btn-outline-dark">Back to homepage</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  )
}
