import {
    USER_SIGN_UP_REQUEST,
    USER_SIGN_UP_SUCCESS,
    USER_SIGN_UP_FAIL,
    USER_SIGN_IN_REQUEST,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_FAIL,
    USER_LOGOUT_REQUEST,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_RESET
} from '../constants/userConstants'

export const userSignUpReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_SIGN_UP_REQUEST:
        return { loading: true }
      case USER_SIGN_UP_SUCCESS:
        return { loading: false, userInfo: action.payload }
      case USER_SIGN_UP_FAIL:
        return { loading: false, error: action.payload }
    //   case USER_LOGOUT:
    //     return state={}
      default:
        return state
    }
  }

  export const userSignInReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_SIGN_IN_REQUEST:
        return { loading: true }
      case USER_SIGN_IN_SUCCESS:
        return { loading: false, userInfo: action.payload }
      case USER_SIGN_IN_FAIL:
        return { loading: false, error: action.payload }
      case USER_LOGOUT_REQUEST:
        return state={}
      default:
        return state
    }
  }

  export const userListReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LIST_REQUEST:
        return { loading: true }
      case USER_LIST_SUCCESS:
        return { loading: false, userList: action.payload.usersList }
      case USER_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const userDetailReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { loading: true }
      case USER_DETAILS_SUCCESS:
        return { loading: false, userData: action.payload.userData }
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const updateUserReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_UPDATE_REQUEST:
        return { loading: true }
      case USER_UPDATE_SUCCESS:
        return { loading: false, success: true, userInfo: action.payload }
      case USER_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }


  export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_DELETE_REQUEST:
        return { loading: true }
      case USER_DELETE_SUCCESS:
        return { loading: false, success: true }
      case USER_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case USER_DELETE_RESET:
          return {} 
      default:
        return state
    }
  }

