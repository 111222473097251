import React from 'react'


import oppo from '../../assets/images/static_pages/eletronics/mobile/oppo.png'
import iphone from '../../assets/images/static_pages/eletronics/mobile/iphone.png'
import samsung from '../../assets/images/static_pages/eletronics/mobile/samsung.png'
import oneplus from '../../assets/images/static_pages/eletronics/mobile/oneplus.png'
import tab from '../../assets/images/static_pages/eletronics/mobile/tab.png'
import notebook from '../../assets/images/static_pages/eletronics/mobile/notebook.png'
import landline from '../../assets/images/static_pages/eletronics/mobile/landline.png'
import headphone from '../../assets/images/static_pages/eletronics/mobile/headphone.png'



export default function ElectronicsMobile() {
  return (
    <>
         <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>Mobile's</h1>
                    </div>
                    <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={oppo} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">OPPO</h5>
                                 <p className='text-dark'>
                                 OPPO A74 5G (Fantastic Purple,6GB RAM,128GB Storage)

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={iphone} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">IPHONE</h5>
                                 <p className='text-dark'>
                                

                                 </p>
                            </div>
                          </div>
                        </div>

                        
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={samsung} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">SAMSUNG</h5>
                                 <p className='text-dark'>
                                 Size | 64GB
                                Model | Certified Refurbished
                                Brand | Samsung
                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={oneplus} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">ONE PLUS</h5>
                                 <p className='text-dark'>
                                 Storage GB/128 GB, 8 GB/256 GB Color Glacier Blue, Frosted Silve
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={tab} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">TAB</h5>
                                 <p className='text-dark'>
                                 There’s 6GB of RAM and 128GB storage on the base model while an 8GB/256GB configuration is also available.
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={notebook} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">NOTEBOOK</h5>
                                 <p className='text-dark'>
                                 Samsung Galaxy Z Fold3 5G (Phantom Black, 12GB RAM, 256GB Storage)

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={landline} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">LANDLINE</h5>
                                 <p className='text-dark'>
                                 Panasonic KX-TS880MXBD Corded Landline Phone  (Black)

                                 </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={headphone} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HEAD PHONE</h5>
                                 <p className='text-dark'>
                                 Connections: Bluetooth, Wireless

                                  Bluetooth Compatibility: Bluetooth 4.0

                                  Power Source: Battery

                                  Batteries: Rechargeable lithium-ion


                                 </p>
                            </div>
                          </div>
                        </div>
            </div>
        </div>

     </>
  )
}
