import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';
import { medicineList, medicineListOfDisease } from '../redux/actions/medicineActions';

export default function SubMedicine() {
  const {disease} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  
  const current_page = location.state;

  const title = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','ALL']
  const subTitle = [];
  
  const {loading, error, medicines} = useSelector(state => state.listMedicineOfDisease)
  
    useEffect(()=>{
      if(disease){
        dispatch(medicineListOfDisease(disease))
      }
    }, [disease])
    
    medicines && medicines.map(filteredMedicines => (
      subTitle.push(filteredMedicines.companyId.name.toUpperCase())
    ))
    const unique = [...new Set(subTitle.map(item => item))];
    return (
    <>
      <div className="container">
            {/* Desktop view */}
            <div className="row my-5 d-filter">
                <div className="col-md-12">
                    <div>
                        <p><Link to={"/"} className="text-muted">Home</Link> <span> {'>'} </span> <Link to={"/medicine"} className="text-muted">Prescription</Link> <span> {'>'} </span> <span className='custom-text-color'>{disease}</span></p>
                    </div>
                    <h3 className='custom-text-color m-0'>{disease} ({medicines?medicines.length:'0'})</h3>
                    <small className='custom-text-color'>drug list</small>
                    <p>Find and shop for medications in the category “Alcohol Addiction“. Get up to 20% on Prescription Medications only at our Pharmacy.</p>
                    <div className="content-section">
                    <div id="list-nav-nav" className="listNav">
                        <div className="ln-letters">
                              {
                                title.map((alpha)=>(
                                    <a className="a" href="#">{alpha}</a>
                                ))
                              }
                        </div>
                      </div>
                      <div className="prescriptions_products" id="list-nav">
                         {
                          unique.sort().map((alpha)=>(
                            <div className="drug-list-col ln-a">
                             <h3>{alpha.charAt(0)}</h3>
                             <h6 className='text-dark'>{alpha}</h6>
                              <ul className="alpha-drug-list">
                              {
                                      medicines && medicines.map(filteredMed => {
                                          return <li><Link className='custom-text-color' to={{pathname:`/product-detail/${filteredMed._id}`}} state={{ data: filteredMed }}>{filteredMed.productName}</Link></li>
                                      })
                              }
                              </ul> 
                            </div>
                          ))
                         }
                      </div>
                    </div> 
                  </div>
            </div>
        </div>
    </>
  )
}
