import React from 'react'
import {Link, useNavigate} from 'react-router-dom';
import domestic from '../../assets/images/static_pages/domestic.png'
import logistics from '../../assets/images/static_pages/logistics.png'
import international from '../../assets/images/static_pages/international.png'
import ocean from '../../assets/images/static_pages/ocean.png'
import custom from '../../assets/images/static_pages/custom.png'
import freight from '../../assets/images/static_pages/freight.png'

import '../static_pages/static.css'

export default function Logistics() {
  return (
    <>
    <div className="container my-4">
        <div className="row justify-content-center">
                <div className="col-md-12 my-4">
                    <h1 className='fw-bolder text-uppercase text-center'>Logistics</h1>
                </div>
                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={domestic} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">DOMESTIC AIR FREIGHT</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={"logistics-domestic"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={logistics} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">WAREHOUSING</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={"logistics-warehouse"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={international} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body">
                        <h5 className="m-0 fw-bold">INTERNATIONAL AIR FREIGHT IMPORT - EXPORT</h5>
                        <Link to={"logistics-international"} className="btn btn-outline-dark rounded-pill mt-3">Know More</Link>
                          {/* <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">INTERNATIONAL AIR FREIGHT  FEDEX_DHL - EXPORT -IMPORT</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={"logistics-international"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div> */}
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={ocean} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body">
                        <h5 className="m-0 fw-bold">OCEAN FREIGHT-IMPORT-EXPORT</h5>
                        <Link to={"logistics-ocean"} className="btn btn-outline-dark rounded-pill mt-3">Know More</Link>
                          {/* <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">OCEAN FREIGHT-IMPORT-EXPORT</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={  "logistics-ocean"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div> */}
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={custom} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">CUSTOM CLEARANCE</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={"logistics-custom"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={freight} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h5 className="m-0 fw-bold">FREIGHT FORWARDING</h5></div>
                            <div class="p-2 flex-fill text-end"><Link to={"logistics-freight"} className="btn btn-outline-dark rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 my-4 text-end">
                  <button className='btn btn-outline-dark btn-lg'>Get A QUOTE</button>
                </div>
        </div>
    </div>

    </>
  )
}


