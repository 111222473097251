export const AYURVEDA_ADD_REQUEST = "AYURVEDA_ADD_REQUEST"
export const AYURVEDA_ADD_SUCCESS = "AYURVEDA_ADD_SUCCESS"
export const AYURVEDA_ADD_FAIL = "AYURVEDA_ADD_FAIL"
export const AYURVEDA_ADD_RESET = "AYURVEDA_ADD_RESET"

export const AYURVEDA_LIST_REQUEST = "AYURVEDA_LIST_REQUEST"
export const AYURVEDA_LIST_SUCCESS = "AYURVEDA_LIST_SUCCESS"
export const AYURVEDA_LIST_FAIL = "AYURVEDA_LIST_FAIL"

export const AYURVEDA_LIST_OF_DISEASE_REQUEST = "AYURVEDA_LIST_OF_DISEASE_REQUEST"
export const AYURVEDA_LIST_OF_DISEASE_SUCCESS = "AYURVEDA_LIST_OF_DISEASE_SUCCESS"
export const AYURVEDA_LIST_OF_DISEASE_FAIL = "AYURVEDA_LIST_OF_DISEASE_FAIL"

export const AYURVEDA_DETAILS_REQUEST = "AYURVEDA_DETAILS_REQUEST"
export const AYURVEDA_DETAILS_SUCCESS = "AYURVEDA_DETAILS_SUCCESS"
export const AYURVEDA_DETAILS_FAIL = "AYURVEDA_DETAILS_FAIL"

export const AYURVEDA_UPLOAD_REQUEST = "AYURVEDA_UPLOAD_REQUEST"
export const AYURVEDA_UPLOAD_SUCCESS = "AYURVEDA_UPLOAD_SUCCESS"
export const AYURVEDA_UPLOAD_FAIL = "AYURVEDA_UPLOAD_FAIL"

export const AYURVEDA_UPDATE_REQUEST = "AYURVEDA_UPDATE_REQUEST"
export const AYURVEDA_UPDATE_SUCCESS = "AYURVEDA_UPDATE_SUCCESS"
export const AYURVEDA_UPDATE_FAIL = "AYURVEDA_UPDATE_FAIL"
export const AYURVEDA_UPDATE_RESET = "AYURVEDA_UPDATE_RESET"

export const AYURVEDA_DELETE_REQUEST = "AYURVEDA_DELETE_REQUEST"
export const AYURVEDA_DELETE_SUCCESS = "AYURVEDA_DELETE_SUCCESS"
export const AYURVEDA_DELETE_FAIL = "AYURVEDA_DELETE_FAIL"
export const AYURVEDA_DELETE_RESET = "AYURVEDA_DELETE_RESET"