
import React, {  useEffect, useState, useCallback  } from 'react'
import ReactLoading from "react-loading";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import DoctorCard from '../components/Doctor/DoctorCard'

import Paginations from '../components/Pagination/Paginations'
import data from '../components/data'
import '../components/Pagination/style.css'
import CustomCircularP from '../rd-pages/widgets/customCircularP'
import { API_ENDPOINT} from '../rd-utils/constants'


import { doctorList } from '../redux/actions/doctorActions';
import { Api } from '@mui/icons-material';
import { getHeader } from '../rd-utils/util';
import DoctorDetail from './DoctorDetail';

export default function Doctors() {
  const DOCTORS = "doctors"
  const DOCTOR_DETAIL = "doctor_detail"
  //   const [done, setDone] = useState(undefined);
  //   const dispatch = useDispatch()
  //   const {loading, error, doctorList : doctors} = useSelector(state => state.listDoctor)
  
  //   useEffect(() => {
  //     dispatch(doctorList())
  //   }, [])

  //   const data = []
  //   data.push(doctors);

  // //   pagination
  // const [currentPage, setCurrentPage] = useState(1);
  // let NUM_OF_RECORDS = data.length;
  // let LIMIT = 8;
  // const onPageChanged = useCallback(
  //   (event, page) => {
  //     event.preventDefault();
  //     setCurrentPage(page);
  //   },
  //   [setCurrentPage]
  // );
  // const currentData = data.slice(
  //   (currentPage - 1) * LIMIT,
  //   (currentPage - 1) * LIMIT + LIMIT
  // );
  // useEffect(() => {
  //   setTimeout(() => {
  //     setDone(true);
     
  //   }, 2000);
  // }, []);
  const [doctors, setDoctors] = useState([])
  const [currentDoctor, setCurrentDoctor] = useState({})
  const [currentView, setCurrentView] = useState(DOCTORS)
  const [loadingDoctors, setLoadingDoctors] = useState(false)
  useEffect(()=>{
    loadDoctors()
  }, [])
  function loadDoctors(){
    setLoadingDoctors(true)
    axios.get(API_ENDPOINT+'user/get-doctors', getHeader()).then(res=>{
      setLoadingDoctors(false)
      let d = res.data
      if(d.success){
        setDoctors(d.message)
      }
    })
  }
  const onSelect = (cd)=>{
    setCurrentDoctor(cd)
    setCurrentView(DOCTOR_DETAIL)
  }
  const back = ()=>{
    setCurrentView(DOCTORS)
  }
  return (
    <>
        <div className="container">
              <CustomCircularP show={loadingDoctors}/>
              <div className="row">
                <div className="col-md-12">
                  <div style={{display: 'flex', margin: '8px'}}>
                    <div onClick={back} style={{fontSize: '20px', fontWeight: 600, cursor: 'pointer', userSelect: 'none', textDecoration: currentView===DOCTOR_DETAIL?'underline':'none'}}>DOCTORS</div>
                    
                    {currentView === DOCTOR_DETAIL && <div style={{marginLeft: '16px', alignSelf: 'center'}}>≫ {currentDoctor.name}</div>}
                  </div>
                      {
                  currentView === DOCTORS?
                      <div className="row justify-content-center">
                        {
                          doctors.map((doctor, i)=><DoctorCard onSelect={onSelect} doctor = {doctor} key={i} />)
                        }
                      </div>
                    :currentView === DOCTOR_DETAIL?
                    <DoctorDetail doctor={currentDoctor} />
                    :<></>
                  }
                </div>
              </div>
        </div>
    </>
  )
}