import { DoctorTypes } from '../constants/doctorConstant'
const initialState = {
    doctors:[],
};

 const doctorReducer = (state = initialState, {type, payload}) => {
    switch (type){
        case DoctorTypes.SET_DOCTORS:
            return {...state, doctors: payload};
        default:
            return state;
    }
}
export default doctorReducer;