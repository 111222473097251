import React from 'react'
import hp1 from '../../assets/images/static_pages/eletronics/desktop/hp1.png'
import hp2 from '../../assets/images/static_pages/eletronics/desktop/hp2.png'
import hp3 from '../../assets/images/static_pages/eletronics/desktop/hp3.png'
import hp4 from '../../assets/images/static_pages/eletronics/desktop/hp4.png'
import hp5 from '../../assets/images/static_pages/eletronics/desktop/hp5.png'

import dell1 from '../../assets/images/static_pages/eletronics/desktop/dell1.png'
import dell2 from '../../assets/images/static_pages/eletronics/desktop/dell2.png'
import dell3 from '../../assets/images/static_pages/eletronics/desktop/dell3.png'
import dell4 from '../../assets/images/static_pages/eletronics/desktop/dell4.png'
import dell5 from '../../assets/images/static_pages/eletronics/desktop/dell5.png'

import acer1 from '../../assets/images/static_pages/eletronics/desktop/acer1.png'
import acer2 from '../../assets/images/static_pages/eletronics/desktop/acer2.png'
import acer3 from '../../assets/images/static_pages/eletronics/desktop/acer3.png'
import acer4 from '../../assets/images/static_pages/eletronics/desktop/acer4.png'

import lenovo1 from '../../assets/images/static_pages/eletronics/desktop/lenovo1.png'
import lenovo2 from '../../assets/images/static_pages/eletronics/desktop/lenovo2.png'
import lenovo3 from '../../assets/images/static_pages/eletronics/desktop/lenovo3.png'
import lenovo4 from '../../assets/images/static_pages/eletronics/desktop/lenovo4.png'

import apple1 from '../../assets/images/static_pages/eletronics/desktop/apple1.png'
import apple2 from '../../assets/images/static_pages/eletronics/desktop/apple2.png'
import apple3 from '../../assets/images/static_pages/eletronics/desktop/apple3.png'


export default function ElectronicsDesktop() {
  
 

 

  return (
    <>
    <div className="container my-4">
        <div className="row justify-content-center">
                <div className="col-md-12 my-4">
                    <h1 className='fw-bolder text-uppercase text-center'>Desktop's</h1>
                </div>

                <div className='row my-4'>
                <div className="col-md-12">
                    <h2 className='fw-bold'>Hp Desktop's</h2>
                  </div>
                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={hp1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HP</h5>
                                 <p className='text-dark'>12th Generation Intel® Core™ i5 processor Windows 11 Home
                                  68.6 cm (27) diagonal FHD display
                                    256 GB PCIe® NVMe™ M.2 SSD
                                    8 GB DDR4-3200 MHz RAM (1 x 8 GB)
                                    6.59 kg</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={hp2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HP</h5>
                                 <p className='text-dark'>12th Generation Intel® Core™ i7 processor
Windows 11 Home Single Language in S mode
68.6 cm (27) diagonal FHD touch display
Intel® UHD Graphics 770
1 TB PCIe® NVMe™ TLC M.2 SSD
16 GB DDR4-3200 MHz RAM (2 x 8 GB)
8.3 kg

</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={hp3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HP</h5>
                                 <p className='text-dark'>12th Generation Intel® Core™ i3 processor
Windows 11 Home
60.5 cm (23.8) diagonal FHD display
Intel® UHD Graphics 730
256 GB PCIe® NVMe™ M.2 SSD
8 GB DDR4-3200 MHz RAM (1 x 8 GB)
6.69 kg

</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={hp4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HP</h5>
                                 <p className='text-dark'>12th Generation Intel® Core™ i5 processor
Windows 11 Home
80 cm (31.5) diagonal QHD display
Intel® UHD Graphics 730
512 GB PCIe® NVMe™ TLC M.2 SSD
8 GB DDR4-3200 MHz RAM (1 x 8 GB)
14 kg

</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={hp5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">HP</h5>
                                 <p className='text-dark'>12th Generation Intel® Core™ i7 processor
Windows 11 Pro
8 GB DDR4-3200 MHz RAM (1 x 8 GB)
512 GB PCIe® NVMe™ SSD
Intel® UHD Graphics 730

</p>
                            </div>
                          </div>
                        </div>
              
               
                     
                </div>  
                <hr />

                <div className='row my-4'>
                      <div className="col-md-12">
                        <h2 className='fw-bold'>Dell Desktop's</h2>
                      </div>


                      <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={dell1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">DELL</h5>
                                 <p className='text-dark'>12th Gen Intel® Core™ i5-12500T (18 MB cache, 6 cores, 12 threads, 2.00 GHz to 4.40 GHz Turbo, 35 W)
Windows 10 Pro (Windows 11 Pro license included), English(Dell Technologies recommends Windows 11 Pro for business)
Intel® Integrated Graphics
8 GB, 1 x 8 GB, DDR4
256 GB, M.2 2230, PCIe NVMe, SSD, Class 35

</p>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={dell2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">DELL</h5>
                                 <p className='text-dark'>12th Gen Intel® Core™ i5-12500
Windows 10 Pro (Windows 11 Pro license included)(Dell Technologies recommends Windows 11 Pro for business)
16 GB, 2 x 8 GB, DDR5
256 GB, M.2 2230, PCIe NVMe, SSD</p>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={dell3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">DELL</h5>
                                 <p className='text-dark'>11th Gen Intel® Core™ i5-12500
Windows 10 Pro (Windows 11 Pro license included)(Dell Technologies recommends Windows 11 Pro for business)
16 GB, 2 x 8 GB, DDR5
256 GB, M.2 2230, PCIe NVMe, SSD,

</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={dell4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">DELL</h5>
                                 <p className='text-dark'>12th Gen Intel® Core™ i5-12400
Windows 11 Home Single Language(Dell Technologies recommends Windows 11 Pro for business)
Intel® UHD Graphics 730
8 GB, 1 x 8 GB, DDR4, 3200 MHz

</p>
                            </div>
                          </div>
                        </div>

                        
                        <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={dell5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">DELL</h5>
                                 <p className='text-dark'>12th Gen Intel® Core™ i3-12100
Windows 11 Home Single Language(Dell Technologies recommends Windows 11 Pro for business)
Intel® UHD Graphics 730
8 GB, 1 x 8 GB, DDR4, 3200 MHz
256 GB, M.2, PCIe NVMe, SSD

</p>
                            </div>
                          </div>
                        </div>
                      
               
                  </div>

                  <hr />
                <div className='row my-4'>
                      <div className="col-md-12">
                        <h2 className='fw-bold'>Acer Desktop's</h2>
                      </div>

                      <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={acer1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">ACER</h5>
                                  <p className='text-dark'>10th Gen Core i5 Processor 1035G1 I Speed 1.0 GHz (Base) 3.60 GHz ( Max Turbo) I 4 Cores I 6MB Smart Cache 23.8 Inch IPS Full HD Display 
</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={acer2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">ACER</h5>
                                  <p className='text-dark'>12th Gen Core i5 Processor 1035G1 I Speed 1.0 GHz (Base) 3.60 GHz ( Max Turbo) I 4 Cores I 6MB Smart Cache 23.8 Inch IPS Full HD Display </p>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={acer3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">ACER</h5>
                                  <p className='text-dark'>11th Gen Core i5 Processor 1035G1 I Speed 1.0 GHz (Base) 3.60 GHz ( Max Turbo) I 4 Cores I 6MB Smart Cache 23.8 Inch IPS Full HD Display </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={acer4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">ACER</h5>
                                  <p className='text-dark'>12th Gen Core i5 Processor 1035G1 I Speed 1.0 GHz (Base) 3.60 GHz ( Max Turbo) I 4 Cores I 6MB Smart Cache 23.8 Inch IPS Full HD Display  </p>
                              </div>
                            </div>
                          </div>
              
                  </div> 
                  <hr />
                  <div className='row my-4'>
                      <div className="col-md-12">
                        <h2 className='fw-bold'>Lenovo Desktop's</h2>
                      </div>

                      <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={lenovo1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">LENOVO</h5>
                                  <p className='text-dark'>Processor | 8GB DDR4 RAM & 512GB SSD Storage | 23.8-inch FHD LED (1920x1080) IPS Display | Windows 10 Home 64-bit Operating System | Wireless Keyboard and Mouse Included 
</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={lenovo2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">LENOVO</h5>
                                  <p className='text-dark'>Core i5-9400T processor helps enhance the overall performance of this computer
8GB DDR4 RAM offers smooth multitasking
1 TB internal storage lets you keep your important data and media files
 
 </p>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={lenovo3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">LENOVO</h5>
                                  <p className='text-dark'>Processor | 8GB DDR4 RAM & 512GB SSD Storage | 23.8-inch FHD LED (1920x1080) IPS Display | Windows 10 Home 64-bit Operating System | Wireless Keyboard and Mouse Included </p>
                              </div>
                            </div>
                          </div>



                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={lenovo4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">LENOVO</h5>
                                  <p className='text-dark'>Intel Core i5-1135G7 Processor, Integrated Intel Iris Xe Graphics, Intel SoC Platform Chipset, 8 GB RAM, 512 GB SSD Storage, Dual Microphone, Calliope Wireless Keyboard </p>
                              </div>
                            </div>
                          </div>

              
                  </div> 
                  <hr />
                  <div className='row my-4'>
                      <div className="col-md-12">
                        <h2 className='fw-bold'>Mac Desktop's</h2>
                      </div>



                      <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={apple1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">MAC</h5>
                                  <p className='text-dark'>21.5-inch iMac: 2.3GHz dual-core 7th-generation Intel Core i5 processor - 256GB 
</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={apple2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">MAC</h5>
                                  <p className='text-dark'>Apple iMac 21.5" - Intel Core i5 - 8GB Memory - 256GB SSD

</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 my-3 text-center">
                         <div className='card shadow'>
                            <img src={apple3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                                    <h5 class="card-title">MAC</h5>
                                  <p className='text-dark'>Apple iMac 21.5" - Intel Core i5 - 8GB Memory - 256GB SSD
</p>
                              </div>
                            </div>
                          </div>

                       
                  </div> 
         </div>
      </div>
      
    </>
  )
}
