import React from "react";
import { MDBFooter } from 'mdb-react-ui-kit';
import {Link, useNavigate} from 'react-router-dom';

import siteLogo from '../../assets/images/site-logo.png'
import facebookLogo from '../../assets/images/facebook-logo.svg'
import twitterLogo from '../../assets/images/twitter-logo.svg'
import instaLogo from '../../assets/images/insta-logo.svg'
import linkdinLogo from '../../assets/images/linkdin-logo.svg'
import whatappLogo from '../../assets/images/social_media/whatsapp.png'


import map from '../../assets/images/map.png'

const Footer = () => {
return (
	<MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between'></section>
      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row justify-content-between mt-3 '>
            <div className='col-md-5'>
                <img src={siteLogo} alt="" style={{height: '144px'}}/>
                <h3 className='mb-4'>
                  Giving The Best <br /> Health Service
                </h3>
                <p>RK India is among the trusted surgical instruments suppliers and medical equipment dealers in India, which makes your procurements of medical equipment, supplies, products, consumables and medical devices online in India easier than ever. </p>
            </div>
            <div className="col-md-6">
            <div className="row mt-5">
                    <div className='col-md-6 mb-4'>
                        <h6 className='text-uppercase fw-bold mb-4'>Our Location</h6>
                        <p className='text-muted'>05,Prathibha paradise,3rd Cross,M G Road,Tumkur-572102.Karnataka.</p>
                        <a href="https://www.google.com/maps/dir//05,Prathibha+paradise,3rd+Cross,M+G+Road,Tumkur-572102.Karnataka./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bb02c285b594cf5:0x44e26b2ddf1825d?sa=X&ved=2ahUKEwjSrpzD_8v8AhXDS2wGHaZ_DWMQ9Rd6BAhDEAM" target={'_blank'}><img src={map} alt="location" style={{width:'50px'}} /></a>
                    </div>
                    <div className='col-md-6 mb-4 col-sm-6'>
                        <h6 className='text-uppercase fw-bold mb-4'>Contact Info</h6>
                        <p className='m-0'>Phone No : <span className='text-muted'>+91-9448084952</span> </p>
                        <p className='m-0'>Phone No : <span className='text-muted'>+91-9620596301</span> </p>
                        <p className='mb-2'>Email Id :  <span className='text-muted'>rkgroupstmk@gmail.com</span>  </p>
                    <a href="https://api.whatsapp.com/send?phone=919686196301" target={'_blank'}><img src={whatappLogo} style={{width:'40px'}} alt=""/></a>
                    </div>
                </div>
                <div className="row ">
                    <div className='col-md-4 mb-4'>
                    <h6 className='text-uppercase fw-bold mb-4'>COMPANY</h6>
                    <p>
                      <Link  className='text-reset' to={{pathname:"/"}}>Home</Link>
                    </p>
                    <p>
                         <Link  className='text-reset' to={{pathname:"/about"}}>About</Link>
                    </p>
                    <p>
                         <Link  className='text-reset' to={{pathname:"/contact"}}>Contact</Link>
                    </p>  
                    </div>

                    <div className='col-md-4 mb-4 col-sm-6'>
                    <h6 className='text-uppercase fw-bold mb-4'>QUICK LINKS</h6>
                    <p>
                      
                    <Link  className='text-reset' to={{pathname:"/faq"}}>FAQ</Link>

                    </p>
                    <p>
                    <Link  className='text-reset' to={{pathname:"/blog"}}>Blogs</Link>

                    </p>
                    </div>

                    <div className='col-md-4 mb-4'>
                    <h6 className='text-uppercase fw-bold mb-4'>OUR POLICIES</h6>
                    <p>
                       <Link  className='text-reset' to={{pathname:"/disclaimer"}}>Disclaimer</Link>
                       
                    </p>
                    <p>
                        <a href='#!' className='text-reset'>
                        Privacy Policy
                        </a>
                    </p>
                    </div>
                </div>
                <hr />
                <div className="text-end mb-2">
                    <a href='' className='me-4 text-reset'>
                        <img src={facebookLogo} alt=""/>
                    </a>
                    <a href='' className='me-4 text-reset'>
                        <img src={twitterLogo} alt=""/>
                    </a>
                    <a href='' className='me-4 text-reset'>
                        <img src={instaLogo} alt=""/>
                    </a>
                    <a href='' className='me-4 text-reset'>
                        <img src={linkdinLogo} alt=""/>

                        
                    </a>
                </div>
            </div>
          </div>
        </div>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: '#072939' }}>
        © Copyright 2023. &nbsp;
        <a className='text-reset fw-bold' href='http://rkincindia.com/'>
          Rk World. All Right Reserved Designed By Metromindz
        </a>
      </div>
    </MDBFooter>
);
};
export default Footer;
