import React from 'react'
import banner from '../../assets/images/static_pages/logistics/warehouse/banner.png'

import img1 from '../../assets/images/static_pages/logistics/warehouse/img1.png'
import img2 from '../../assets/images/static_pages/logistics/warehouse/img2.png'

export default function LogisticsWarehouse() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <div className='text-center'>
                          <img src={banner} style={{width: '100%'}}  alt=""/>
                        </div>
                     </div>
                    <div className="row">
                        <div className="col-md-8">
                          <div className="my-3">
                            <div >
                            <h2 className='text-dark fw-bold'>THIRD PARTY LOGISTIC SERVICE PROVIDER</h2>
                                <p className='text-dark'>3PL business involves procurement logistics, factory logistics, sales logistics (warehouse management, transportation and delivery management) and reverse logistics (industrial waste, returned commodities, repairs) being completely outsourced instead of being carried out by customers' logistics sections.</p>
                            </div>
                          </div>
        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img1} style={{width: '100%'}} alt=""/>
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 my-4">
                            <h2 className='text-dark fw-bold'>THIRD PARTY LOGISTIC SERVICE PROVIDER</h2>
                            <p className='text-dark'>Build capability to process the orders in bulk and ensure fast order dispatches with limited stock-picking time in the warehouse.</p>
                            <ul style={{listStyleType: 'circle'}}>
                                <li>Gain Complete Inventory Visibility</li>
                                <li>Quick Clearance Of Aging Inventory</li>
                                <li>Fast Consolidation And Sorting</li>
                                <li>Precise Inventory Report</li>

                            </ul>
                        </div>
                        <div className="col-md-12 my-4">
                            <h2 className='text-dark fw-bold'>PACKING AND MOVING SYSTEM</h2>
                            <p className='text-dark'>We are Experts in the Packing and Moving Industry, offering 100% of quality and safe moving services that will leave a never-ending impact on our client’s minds. We tend to be solely your belongings in creating our services higher and serving our Customers nicely</p>
                         </div>
                         <div className="col-md-12 my-4">
                            <h2 className='text-dark fw-bold'>BIN MANAGEMENT</h2>
                            <p className='text-dark'>An AutoStore system is comprised of 4 main components: a structural grid, delivery robots, storage bins, and an operator port. Products are stored in stacked bins within the dense aluminum grid. As a product is needed to fulfill an order, the system software and controls alert a robot to find the bin containing the item.</p>
                         </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                          <div className="my-3">
                            <div >
                            <h2 className='text-dark fw-bold'>DISTRIBUTION MANAGEMENT </h2>
                                <p className='text-dark'>A comprehensive distributor and dealer management system to manage orders and track inventory levels and secondary sales.</p>
                            </div>
                          </div>
        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img2} style={{width: '100%'}} alt=""/>
                          </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 my-4">
                            <h2 className='text-dark fw-bold'>WAREHOUSE SERVICE SYSTEM</h2>
                            <p className='text-dark'>Value-add services: Pre-sales & After-market Technical Services, E-fulfillment, E-logistics, Order-to-cash and Customs</p>
                           <div className="row">
                            <div className="col-md-6">
                              <ul style={{listStyleType: 'circle'}}>
                                  <li>Warehouse management</li>
                                  <li>Inventory control</li>
                                  <li>Pick & Pack</li>
                                  <li>KPI Management</li>
                                  <li>Distribution</li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                            <ul style={{listStyleType: 'circle'}}>
                                 
                                  <li>Reverse Logistics</li>
                                  <li>EDI Interfacing</li>
                                  <li>Delivery Services</li>
                                  <li>Cold storage system </li>
                                  <li>secondary transportation</li>
                              </ul>
                            </div>

                           </div>
                           
                       
                        </div>
                    </div>
         
            </div>
        </div>
    </>
  )
}
