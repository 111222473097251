import React from 'react'
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { useForm } from "react-hook-form";
import swal from 'sweetalert';
import contactBanner from '../assets/images/contact.jpg'; 

export default function Contact() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        
       if(data){
        axios.post("http://localhost:9002/contact", data)
        .then(res => { 
            console.log(res.data.message)       
            document.getElementById("create-course-form").reset();
            swal({
                title: "Message Send Successfully",
                icon: "success",
                button: "Okay",
            });
        })
       }else{
            alert("Error")
       }
    }
  return (
    <>
    <siv className="continer-fluid mt-5">
        <div className="row justify-content-center contact_banner">
            <div className="col-md-12 text-center my-5">
                <h1 className='fw-bold mt-5 text-light  '>Need Help? Say Hello</h1>
                <h6 className='fw-bold  text-light  '>We would love to here from you</h6>
            </div>
        </div> 
    </siv>
    <div className="container">
        <div className="row justify-content-between my-5">
        <div className="col-md-6">
            <div className="row">
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                            <i class="fa-solid fa-clock fa-2xl text-dark"></i>
                            <h5 className='fw-bold mt-2 mb-3'>Working Hours</h5>
                            <p className='text-dark'>Monday, 10am to 7pm</p>
                            <p className='text-dark'>Tuesday, 10am to 7pm</p>
                            <p className='text-dark'>Wednesday, 10am to 7pm</p>
                            <p className='text-dark'>Thursday, 10am to 7pm</p>
                            <p className='text-dark'>Friday, 10am to 7pm</p>
                            <p className='text-dark'>Saturday, 10am to 7pm</p>
                            <p className='text-dark'>Sunday, closed</p>

                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                            <i class="fa-solid fa-map-location-dot fa-2xl text-info"></i>
                            <h5 className='fw-bold mt-2'>Office Location</h5>
                            <p className='text-dark fw-bold'>Registered office :</p>
                            <p className='text-dark'>R K GROUPS   05, Prathibha Paradise , 3rd cross MG road, Tumkur,572102.</p>
                            <p className='text-dark mt-0'>Corporate office: Bengaluru</p>
                            <p className='text-dark fw-bold mt-3'>Warehouse :</p>
                            <p className='text-dark'>R K GROUPS, KIADB  Industrial Area PLOT NO  658 p-42, 3rd Phase, VASANTHANASRSAPURA, TUMAKURU-572128.</p>


                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                        <i class="fa-solid fa-mobile fa-2xl text-primary"></i>
                            <h5 className='fw-bold mt-2'>Customer Support</h5>
                            <p className='text-dark'>Pharma -  9620596301 <br /> Logistics - 9686541124<br /> Electronics - 9845585624</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                        <i class="fa-solid fa-envelope fa-2xl text-warning"></i>
                            <h5 className='fw-bold mt-2'>Email Us</h5>
                            <p className='text-dark'>rkworldinfo@gmail.com<br />rkgroupstmk@gmail.com <br />rkcomposys@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                        <i class="fa-solid fa-right-from-bracket fa-2xl text-danger"></i>
                            <h5 className='fw-bold mt-2'>Exp - Import Support</h5>
                            <p className='text-dark'>Pharma -  9686196301<br /> Logistics - 9686196302</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card effect my-2 border-0">
                        <div className="card-body text-center">
                        <i class="fa-brands fa-whatsapp fa-2xl text-success"></i>
                            <h5 className='fw-bold mt-2 '>WhatApp</h5>
                            <p className='text-dark'>Pharma -  94480 84952<br /> Logistics - 9686196302 <br /> Electronics : 9845585624</p>
                        </div>
                    </div>
                </div>
            </div>
               
            </div>
            <div className="col-md-6">
            <h1 className='fw-bold  mb-3 text-secondary'>Contact Us</h1>
             <form onSubmit={handleSubmit(onSubmit)} id="create-course-form">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name='company_name'  placeholder="Enter Company Name" {...register("name", { required: true, maxLength: 10 })} />
                                <label for="floatingInput">Enter Company Name</label>
                                {errors.name && <p className='text-danger'>Please enter name</p>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name='name'  placeholder="Enter Name" {...register("name", { required: true, maxLength: 10 })} />
                                <label for="floatingInput">Enter Name</label>
                                {errors.name && <p className='text-danger'>Please enter name</p>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control"  placeholder="name@example.com"  {...register("email",{ 
                                    required: true,  
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                                })}/>
                                <label for="floatingInput">Email address</label>
                                {errors.email && <p className='text-danger'>Please enter email address</p>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name='mobile'  placeholder="Enter Mobile Number" {...register("mobile", { required: true, maxLength: 10,  minLength: 10})} />
                                <label for="floatingInput">Enter Phone Number</label>
                                {errors.mobile && <p className='text-danger'>Please enter mobile number</p>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name='mobile'  placeholder="Enter WhatsApp Number" {...register("mobile", { required: true, maxLength: 10,  minLength: 10})} />
                                <label for="floatingInput">Enter WhatsApp Number</label>
                                {errors.mobile && <p className='text-danger'>Please enter WhatsApp number</p>}
                            </div>
                        </div>
                    
                        <div className="col-md-12 mb-3">
                            <div className="form-floating">
                                <textarea className="form-control" name='msg' placeholder="Leave a comment here" {...register("msg", { required: true })}></textarea>
                                <label>Enter Message</label>
                                {errors.msg && <p className='text-danger'>Please enter message</p>}
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <button type="submit" className="btn btn-blue-style">Submit</button>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </form>
            </div> 
            
        </div>
    </div>
    </>
  )
}
