export const CART_ADD_REQUEST = "CART_ADD_REQUEST"
export const CART_ADD_SUCCESS = "CART_ADD_SUCCESS"
export const CART_ADD_FAIL = "CART_ADD_FAIL"

export const CART_LIST_REQUEST = "CART_LIST_REQUEST"
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS"
export const CART_LIST_FAIL = "CART_LIST_FAIL"

export const CART_CUSTOMER_LIST_REQUEST = "CART_CUSTOMER_LIST_REQUEST"
export const CART_CUSTOMER_LIST_SUCCESS = "CART_CUSTOMER_LIST_SUCCESS"
export const CART_CUSTOMER_LIST_FAIL = "CART_CUSTOMER_LIST_FAIL"

export const CART_ADMIN_CUSTOMER_LIST_REQUEST = "CART_ADMIN_CUSTOMER_LIST_REQUEST"
export const CART_ADMIN_CUSTOMER_LIST_SUCCESS = "CART_ADMIN_CUSTOMER_LIST_SUCCESS"
export const CART_ADMIN_CUSTOMER_LIST_FAIL = "CART_ADMIN_CUSTOMER_LIST_FAIL"

export const CART_UPDATE_REQUEST = "CART_UPDATE_REQUEST"
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS"
export const CART_UPDATE_FAIL = "CART_UPDATE_FAIL"
export const CART_UPDATE_RESET = "CART_UPDATE_RESET"

export const CART_UPLOAD_RECEIPT_REQUEST = "CART_UPLOAD_RECEIPT_REQUEST"
export const CART_UPLOAD_RECEIPT_SUCCESS = "CART_UPLOAD_RECEIPT_SUCCESS"
export const CART_UPLOAD_RECEIPT_FAIL = "CART_UPLOAD_RECEIPT_FAIL"

export const CART_UPDATE_STATUS_REQUEST = "CART_UPDATE_STATUS_REQUEST"
export const CART_UPDATE_STATUS_SUCCESS = "CART_UPDATE_STATUS_SUCCESS"
export const CART_UPDATE_STATUS_FAIL = "CART_UPDATE_STATUS_FAIL"

export const CART_DETAILS_REQUEST = "CART_DETAILS_REQUEST"
export const CART_DETAILS_SUCCESS = "CART_DETAILS_SUCCESS"
export const CART_DETAILS_FAIL = "CART_DETAILS_FAIL"

export const CART_PROCEED_ORDER_REQUEST = "CART_PROCEED_ORDER_REQUEST"
export const CART_PROCEED_ORDER_SUCCESS = "CART_PROCEED_ORDER_SUCCESS"
export const CART_PROCEED_ORDER_FAIL = "CART_PROCEED_ORDER_FAIL"
export const CART_PROCEED_ORDER_RESET = "CART_PROCEED_ORDER_RESET"

export const CART_DELETE_REQUEST = "CART_DELETE_REQUEST"
export const CART_DELETE_SUCCESS = "CART_DELETE_SUCCESS"
export const CART_DELETE_FAIL = "CART_DELETE_FAIL"