import React from 'react'
import about from '../assets/images/about/about.jpg'
import about1 from '../assets/images/about/about1.jpg'
import about2 from '../assets/images/about/about2.jpg'
import about3 from '../assets/images/about/about3.jpg'
import about4 from '../assets/images/about/about4.jpg'


export default function Faq() {
  return (
    <>
      <div className="container my-4">
      {/* SECTION 1 */}
        <div className="row justify-content-center">
            <div className="col-md-10 text-center my-4">
            <figure className="text-center">
              <blockquote className="blockquote">
                <p className='display-5 fw-bold'>Frequently Asked Questions</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                <cite title="Source Title"> RK Groups</cite>
              </figcaption>
            </figure>
             
            <div className="accordion my-5" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            How will I get my medicines? 
                    </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Customer should reach us on toll free or landline numbers and order the medicines. Billing executive will raise the bill and will be delivered as earliest. 
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Are my purchase details are secured? 
                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Yes. All information related to your purchase from us is well protected.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          How can I pay for my order at RK 
                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <ul className='text-start'>
                            <li><i className="fa-solid fa-circle-check text-info"></i>	We accept all credit/debit cards.</li>
                            <li><i className="fa-solid fa-circle-check text-info"></i>	Cash on delivery</li>
                            <li><i className="fa-solid fa-circle-check text-info"></i>	Paytm</li>
                            <li><i className="fa-solid fa-circle-check text-info"></i> Online Payments and UPI also. </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    What is Warehouse Possibility Report? 
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    WPR provides you with deep insights, optimized distribution models and available partners to cater your warehousing needs before signing a contract. Warehouse Possibility Report is customized for your warehousing needs which propose multiple strategies and distribution models to optimize your supply chain goals.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Where are Stock area warehouses located? 
                    </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Stock area provides warehousing and fulfillment services PAN India. You can go to our Network and explore the various warehouses present in different cities and clusters of India.
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      Does Stock area offer shipping or freight deliveries? 
                    </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Yes, Stock area offers shipping or freight or last-mile deliveries. We have tie-ups with major shipping companies who also provide us with discounted rates which we pass on to you directly. To know more 
                    </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How does stock area charge me? 
                    </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    When you buy and sell stock, you pay a fee to your advisor or investment firm. This fee is called a commission. Commissions reduce the return on your investment in a stock. 
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    What kind of goods can be stored in warehouse? 
                    </button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Stored goods can include any raw materials, packing materials, spare parts, components, or finished goods associated with agriculture, manufacturing, and production. 
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                    Where can I find information on a shipment that has been sent to me? 
                    </button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    Tracking
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                    Can I afford it? 
                    </button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                     I’m convinced the product is good value for the money, but I’m not sure if I have enough cash in the bank to make the spend. I wonder how close I am to my credit card limit.
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                    What if it does not work? 
                    </button>
                    </h2>
                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    This could be really disappointing – yet another product that over-promises and underdelivers. I wonder how easy it will be to get my money back? Will I have to explain why I want a refund? 
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                    How much time is all this going to take? 
                    </button>
                    </h2>
                    <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    It says I only need 30 minutes a day, but that sounds too good to be true. What if it ends up like that other dongle that I used for two weeks and then it all just got too time-intensive? 
                     </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThirteen">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                    Am I making right choice? 
                    </button>
                    </h2>
                    <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                    I’m pretty sure there are lots of other vendors on the market offering similar products. What if this is the dud of the group? What if one of the others is better, has more features, or costs less? Maybe I need to do more research before making a decision. 
                         </div>
                    </div>
                </div>
            </div>

            
             <p className='lead text-dark text-center my-5'>I’m pretty sure there are lots of other vendors on the market offering similar products. What if this is the dud of the group? What if one of the others is better, has more features, or costs less? Maybe I need to do more research before making a decision. </p>
            </div>
           
        </div>

      
      </div>


      
    </>
  )
}
