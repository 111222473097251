import React from 'react'

import img1 from '../../assets/images/static_pages/corporate/img1.png'
import img2 from '../../assets/images/static_pages/corporate/img2.png'
import img3 from '../../assets/images/static_pages/corporate/img3.png'
import img4 from '../../assets/images/static_pages/corporate/img4.png'
import img5 from '../../assets/images/static_pages/corporate/img5.png'
import img6 from '../../assets/images/static_pages/corporate/img6.png'
import img7 from '../../assets/images/static_pages/corporate/img7.png'
import img8 from '../../assets/images/static_pages/corporate/img8.png'
import img9 from '../../assets/images/static_pages/corporate/img9.png'
import img10 from '../../assets/images/static_pages/corporate/img10.png'
import img11 from '../../assets/images/static_pages/corporate/img11.png'
import img12 from '../../assets/images/static_pages/corporate/img12.png'
import img13 from '../../assets/images/static_pages/corporate/img13.png'
import img14 from '../../assets/images/static_pages/corporate/img14.png'
import img15 from '../../assets/images/static_pages/corporate/img15.png'
import img16 from '../../assets/images/static_pages/corporate/img16.png'
import img17 from '../../assets/images/static_pages/corporate/img17.png'
import img18 from '../../assets/images/static_pages/corporate/img18.png'
import img19 from '../../assets/images/static_pages/corporate/img19.png'




export default function CorporateGift() {
  return (
    <>
       <div className="container my-4">
        <div className="row justify-content-center">
                <div className="col-md-12 my-4">
                    <h1 className='fw-bolder text-uppercase text-center'>Corporate Gifts</h1>
                </div>
               
                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img1} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">CUP OF POSITIVITY</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img2} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">THE SELF CARE PACK</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img3} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">WRAPPED WONDERS</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img4} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">CHOCOLATY DATE NIGHT </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img5} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">YOU ME & CHOCOLATES</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img6} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">STRESS BURSTER  KIT</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img7} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">CHAI - T   KIT </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img8} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PERSONALIZED TABLE & CLOCK  </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img9} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">THE EXECUTIVES CHOICE</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img10} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">POWER BANK WITH MOMENTS</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img11} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">HEADPHONES </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img12} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">COFFEE DELIGHTS </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img13} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">DRY FRUITS TRAY</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img14} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">IT'S TIME TO SNACKS</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img15} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">LOVE FOR SWEETS</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img16} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">LAPTOP STAND</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img17} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PERSONAL DAIRY WITH BOTTLE</h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img18} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PEN WITH KEY CHAIN </h4></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={img19} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">BLUETOOTH SPEAKERS</h4></div>
                          </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-12 my-4 text-end">
                  <button className='btn btn-outline-dark btn-lg'>Get A QUOTE</button>
                </div>
        </div>
    </div>
    </>
  )
}
