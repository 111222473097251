import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate} from 'react-router-dom'
import siteLogo from '../assets/images/site-logo.png'
import medicineLogo from '../assets/images/medicine-logo.png'
import labTestLogo from '../assets/images/labTest-logo.png'
import AyurvedaLogo from '../assets/images/ayurveda-logo.png'
import SupplementsLogo from '../assets/images/supplements-logo.png'



import medicine from '../assets/images/medicine.png'
import gadgets from '../assets/images/gadgets.png'
import logistics from '../assets/images/logistics.png'


import facebookLogo from '../assets/images/social_media/facebook.png'
import instagramLogo from '../assets/images/social_media/instagram.png'
import youtubeLogo from '../assets/images/social_media/youtube.png'
import linkdinLogo from '../assets/images/social_media/linkedin.png'
import whatsappLogo from '../assets/images/social_media/whatsapp.png'

import broucher from '../assets/rk-groups-brouchers.pdf'


import { logout } from '../redux/actions/userActions'
import { useEffect } from 'react'
import { deleteToken, getUserName, isLoggedIn } from '../rd-utils/util'


const Navbar = () => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()

  const searchHandler = (e) => {
    e.preventDefault()
    console.log(search)
    if(search.length >0)
      navigate(`/product/${search}`)
  }
  const logout = ()=>{
      deleteToken()
      window.location.reload()
  }
  
  return (
    <>
    <div className='bg-blue'>
      <nav className="navbar navbar-expand-lg p-0 my-0" style={{backgroundColor: '#1B78B2'}}>
            <div className="container-fluid p-0 my-0" style={{display: 'initial'}}>
              <div className="row justify-content-between">
                <div className="col-md-4">
                <ul class="nav justify-content-center" >
                      <li class="nav-item mt-2">
                         <span className='text-light' style={{marginRight: '2px', marginLeft: '2px'}}><i class="fa fa-phone text-light" aria-hidden="true" style={{fontSize:"20px"}}></i> Call us : </span> 
                      </li>
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px', fontSize:'14px'}}>+91-9686196301 |</h6>
                      </li> 
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px', fontSize:'14px'}}>9686196302 |</h6>
                      </li>
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px', fontSize:'14px'}}>9620596301 </h6>
                      </li>
                    </ul>
                </div>
                <div className="col-md-3">
                  <ul class="nav justify-content-center">
                        <li class="nav-item text-center">
                            <p className='text-light me-5' style={{ marginLeft: '2px'}}> <span  style={{marginLeft: '10px'}}>Follow us  </span> 
                           <a href="https://www.facebook.com/rkgroupsworldwide/" target={'_blank'}><img src={facebookLogo} style={{width: '20px' , marginLeft: '10px'}} alt=""/></a> 
                            <a href="https://www.instagram.com/rkgroupsworldwide/" target="_blank"><img src={instagramLogo} style={{width: '20px' , marginLeft: '10px'}} alt=""/></a>
                            <a href="https://www.youtube.com/@rkgroupsworldwide/" target="_blank"><img src={youtubeLogo} style={{width: '20px' , marginLeft: '10px'}} alt=""/></a>
                            <a href="https://www.linkedin.com/company/rkgroupsworldwide/" target="_blank"><img src={linkdinLogo} style={{width: '20px' , marginLeft: '10px'}} alt=""/></a>
                            {/* <a href="http://" target="_blank"><img src={whatsappLogo} style={{width: '20px' , marginLeft: '10px'}} alt=""/></a>  */}
                            {/* <img src={twitter} style={{width: '20px' , marginLeft: '10px'}} alt=""/> */}
                          </p>
                        </li>
                      </ul>
                </div>
                <div className="col-md-5">
                    <ul class="nav justify-content-center">
                          {/* <li class="nav-item text-center">
                              <p className='text-light fw-bold me-2' style={{ marginLeft: '2px'}}>Pharma</p>
                          </li> */}
                          {/* <li className="nav-item" style={{marginRight: '15px', marginLeft: '5px'}}>
                            <div class="dropdown">
                            <span class=" dropdown-toggle text-light fw-bold" type="button" data-bs-toggle="dropdown" aria-expanded="false"> Pharma</span>
                              <ul class="dropdown-menu">
                                <li><Link to={"/medicine"} state={{ data: 'medicine' }} className="dropdown-item"><img src={medicineLogo} style={{width: '30px'}} alt=""/> Medicines</Link></li>
                                <li><Link to={"/labtest"} state={{ data: 'labtest' }} className="dropdown-item"><img src={labTestLogo} style={{width: '20px'}} alt=""/> Lab Test</Link></li>
                                <li><Link to={"/ayurveda"} state={{ data: 'ayurveda' }} className="dropdown-item"><img src={AyurvedaLogo} style={{width: '20px'}} alt=""/> Ayurveda</Link></li>
                                <li><Link to={"/product"}  className="dropdown-item"><img src={SupplementsLogo} style={{width: '20px'}} alt=""/> Supplements</Link></li>
                              </ul>
                            </div>
                          </li> */}
                          <li class="nav-item text-center">
                             <Link to={"/"} className="text-light fw-bold me-2" style={{ marginLeft: '5px'}}>Home</Link>
                          </li>
                          <li class="nav-item text-center">
                             <Link to={"/logistics"} className="text-light fw-bold me-2" style={{ marginLeft: '5px'}}>Logistics</Link>
                          </li>
                          <li class="nav-item text-center">
                            <Link to={"/electronics"} className="text-light fw-bold me-2" style={{ marginLeft: '5px'}}>Electronics</Link>
                          </li>
                          <li class="nav-item text-center">
                          
                          <Link to={"/corporate-gifts"} className='text-light fw-bold me-2' style={{ marginLeft: '5px'}}>Corporate Gifts</Link>

                          </li>
                          {/* <li class="nav-item text-center">
                            <p className='text-light' style={{marginRight: '2px'}}><i class="fa-solid fa-download"></i> Download Brochure</p>
                          </li> */}
                        </ul>
                </div>

              </div>
                    {/* <ul class="nav justify-content-start" >
                      <li class="nav-item mt-2">
                         <span className='text-light' style={{marginRight: '2px', marginLeft: '2px'}}><i class="fa fa-phone text-light" aria-hidden="true" style={{fontSize:"20px"}}></i> Call us : </span> 
                      </li>
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px'}}>+91-9686196301 |</h6>
                      </li> 
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px'}}>9686196302 |</h6>
                      </li>
                      <li class="nav-item">
                          <h6 className='text-light' style={{marginRight: '2px', marginLeft: '2px'}}>9620596301 </h6>
                      </li>
                    </ul>
                    <ul class="nav justify-content-center">
                      <li class="nav-item text-center">
                          <p className='text-light me-5' style={{ marginLeft: '2px'}}> <span  style={{marginLeft: '10px'}}>Follow us  </span> 
                          <img src={fb} style={{width: '20px' , marginLeft: '10px'}} alt=""/>
                          <img src={twitter} style={{width: '20px' , marginLeft: '10px'}} alt=""/>
                          <img src={linkdin} style={{width: '20px' , marginLeft: '10px'}} alt=""/>
                          <img src={youtube} style={{width: '20px' , marginLeft: '10px'}} alt=""/>
                          <img src={whatapp} style={{width: '20px' , marginLeft: '10px'}} alt=""/>
                          <img src={insta} style={{width: '20px' , marginLeft: '10px'}} alt=""/>


                          </p>
                      </li>
                    </ul>
                    <ul class="nav justify-content-end">
                      <li class="nav-item text-center">
                          <p className='text-light fw-bold me-2' style={{ marginLeft: '2px'}}>PHARMA</p>
                      </li>
                      <li class="nav-item text-center">
                          <p className='text-light fw-bold me-2' style={{ marginLeft: '2px'}}>LOGISTICS/WAREHOUSE</p>
                      </li>
                      <li class="nav-item text-center">
                          <p className='text-light fw-bold me-2' style={{ marginLeft: '2px'}}>COMPUTER/ACCESSORIES</p>
                      </li>
                      <li class="nav-item text-center">
                          <p className='text-light fw-bold me-2' style={{ marginLeft: '2px'}}>CORPORATE GIFTS</p>
                      </li>
                      <li class="nav-item text-center">
                        <p className='text-light' style={{marginRight: '2px'}}><i class="fa-solid fa-download"></i> Download Brochure</p>
                      </li>
                    </ul> */}
            </div>
      </nav>
      <nav className="navbar navbar-expand-lg bg-blue mb-0 pb-0">

            <div className="container">
              <Link to={"/"} className="navbar-brand"><img src={siteLogo} alt="" width={'100px'}/></Link>
              <li className="nav-item me-5  my-2"> 
                    <form className="" role="search" onSubmit={searchHandler}>
                    <div class="input-group " style={{marginRight: '200px', marginBottom: '10px'}}>
                      <input  type="search" className="form-control rounded-pill" placeholder="Search for medicine &amp; wellness beauty products"  aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
                      {/* <span className="input-group-text" style={{backgroundColor: '#1B78B2'}}><i class="fa-solid fa-magnifying-glass text-light"></i></span> */}
                    </div>
                      <input type="submit" hidden />
                    </form>
                  </li>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul className="navbar-nav nav justify-content-end mb-2 mb-lg-0">
                 
                  {/* <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                    <div class="dropdown">
                      <button class="btn btn-light btn-sm dropdown-toggle rounded-pill border border-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={medicine} style={{width: '30px'}} alt=""/> Pharma
                      </button>
                      <ul class="dropdown-menu">
                        <li><Link to={"/medicine"} state={{ data: 'medicine' }} className="dropdown-item"><img src={medicineLogo} style={{width: '30px'}} alt=""/> Medicines</Link></li>
                        <li><Link to={"/medicine"} state={{ data: 'labtest' }} className="dropdown-item"><img src={labTestLogo} style={{width: '20px'}} alt=""/> Lab Test</Link></li>
                        <li><Link to={"/medicine"} state={{ data: 'ayurveda' }} className="dropdown-item"><img src={AyurvedaLogo} style={{width: '20px'}} alt=""/> Ayurveda</Link></li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/about"}><img src={logistics} style={{width: '30px'}} alt=""/> Logistics / Warehouse</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/about"}><img src={gadgets} style={{width: '30px'}} alt=""/>  Computers / Peripherals</Link>
                      </button>
                  </li> */}
                {/* <li className="nav-item">
                    <Link to={"/about"} className="nav-link text-light">About</Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/doctors"} className="nav-link text-light"><i className="fa-solid fa-user-doctor"></i> Doctors</Link>
                  </li>
                 {(user && user.userInfo) &&  <li className="nav-item">
                    <Link to={"/cart"} className="nav-link text-light"><i className="fa-solid fa-cart-shopping"></i> Cart</Link>
                  </li>}
                  <li className="nav-item">
                    <Link to={"/blog"} className="nav-link text-light"><i className="fa-brands fa-blogger-b"></i> Blog</Link>
                  </li>
                  <li className="nav-item">
                      <Link to={"/contact"} className="nav-link   text-light"><i className="fa-solid fa-phone"></i> Contact Us</Link>
                  </li> */}
                  
                  {/* <li className="nav-item">
                    <Link to={"/login"} className="nav-link text-light"><i className="fa-solid fa-circle-user"></i> SignIn</Link>
                  </li>  */}
                 <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px', marginTop:'17px'}}>
                     <button class="btn btn-nav-style btn-sm" type="button">
                          <Link to={"about"}>  About Us</Link>
                      </button>
                  </li>
                  {/* <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px', marginTop:'17px'}}>
                    <div class="dropdown">
                      <button class="btn btn-nav-style btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Services
                      </button>
                      <ul class="dropdown-menu">
                        <li><Link to={"/services"} state={{ data: 'supply_chain' }} className="dropdown-item">Supply chain</Link></li>
                        <li><Link to={"/services"} state={{ data: 'pharma' }} className="dropdown-item">Pharma</Link></li>
                        <li><Link to={"/services"} state={{ data: 'electronics' }} className="dropdown-item">Electronics</Link></li> 
                      </ul>
                    </div>
                  </li> */}
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px', marginTop:'17px'}}>
                     <button class="btn btn-nav-style btn-sm border border-primary" type="button">
                          <Link to={"/contact"}> Contact us</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px', marginTop:'17px'}}>
                     <button class="btn btn-nav-style btn-sm border border-primary" type="button">
                          <Link to={"/contact"}>Get A Quote</Link>
                      </button>
                  </li>
                  

                  

          
                  {isLoggedIn()?
                  <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle text-light fw-bold nav-btn" to="#"  role="button"  data-bs-toggle="dropdown" aria-expanded="false" ><i className="fa-solid fa-circle-user fa-lg" title={getUserName()}></i></Link>
                  <ul className="dropdown-menu">
                      <li><Link to={"/dashboard"} className="dropdown-item">Dashboard</Link></li>
                      <li><Link to={"/"} className="dropdown-item" onClick={() => logout() }>Logout</Link></li>       
                  </ul>
                </li> :


                  <li className="nav-item" style={{marginRight:'20px'}} >
                    <Link to={"/login"} className="nav-link text-light"><i className="fa-solid fa-circle-user fa-xl"></i> SignIn</Link>
                  </li>
                  }

                  {isLoggedIn() && 
                  <li className="nav-item">
                    <Link to={"/cart"} className="nav-link text-light"><i className="fa-solid fa-cart-shopping fa-lg"></i> Cart</Link>
                  </li>
                  }
                  <li className="nav-item" style={{marginRight:'20px'}}>
                    <a href={broucher} target={'_blank'} className="nav-link text-light"><i class="fa-solid fa-download fa-lg"></i> Brochure</a>
                  </li> 

                  
              </ul>
              
        </div>
      </div>
    <div>
  </div>
</nav>
   <nav className="navbar navbar-expand-lg bg-blue mt-0 pt-0">

{/* <div className="container mt-0 pt-0">
 
  <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul className="navbar-nav nav justify-content-end mb-2 mb-lg-0">
   
     

                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/"}>  Home</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"about"}>  About Us</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                    <div class="dropdown">
                      <button class="btn btn-light btn-sm dropdown-toggle rounded-pill border border-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Services
                      </button>
                      <ul class="dropdown-menu">
                        <li><Link to={"/services"} state={{ data: 'supply_chain' }} className="dropdown-item">Supply chain</Link></li>
                        <li><Link to={"/services"} state={{ data: 'pharma' }} className="dropdown-item">Pharma</Link></li>
                        <li><Link to={"/services"} state={{ data: 'electronics' }} className="dropdown-item">Electronics</Link></li> 
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/contact"}> Contact us</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/contact"}>Career</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/contact"}>Send Inquiry</Link>
                      </button>
                  </li>
                  <li className="nav-item" style={{marginRight: '5px', marginLeft: '1px'}}>
                     <button class="btn btn-light btn-sm rounded-pill border border-primary" type="button">
                          <Link to={"/contact"}>Get A Quote</Link>
                      </button>
                  </li>
                 
                 


      
  </ul>
  
</div>
</div> */}
<div>
</div>
</nav>

    </div>

        </>
  )
  
}



export default Navbar