import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import { userRegister } from '../../redux/actions/userActions';
import axios from 'axios';
import { API_ENDPOINT } from '../../rd-utils/constants';
import { toast } from 'react-toastify';

export default function Register() {
    const navigate = useNavigate();
   
    const [errorAlert, setErrorAlert] = useState('')
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        cpassword: "",
    })

    const {userInfo} = useSelector(state => state.userSignUp)
    const handleChange = (e) =>{
        const {name, value} = e.target
        setUser({
            ...user,
            [name]: value 
        })
    }
    
    const register = () => {
        const {name, email, password, cpassword } = user  
        if(name === ""){
            toast('Name cannot be empty')
        }else if(email === ""){
            toast('Email cannot be empty')
        }else if(password.length<6){
            toast('Password length must be atleast 6 characters')
        }else if(password !== cpassword){
            toast("Passwords don't  match")
        }else {
            axios.post(API_ENDPOINT+'user/register', {name, email, password}).then(res=>{
                let d = res.data
                if(d.success){
                    navigate('/login/msg')
                }else{
                    toast(d.message)
                }
            })
        }
    }

  return (
    <>
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <h4 className='text-center fw-bolder mb-4 custom-text-color'>Create your account</h4>
                            <div className="form-floating mb-4">
                                <input type="text" className="form-control"  placeholder="name@example.com"  name="name" value={user.name} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Name</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="email" className="form-control"  placeholder="name@example.com"  name="email" value={user.email} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Email Address</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="password" className="form-control" minLength={8}  placeholder="password"  name="password" value={user.password} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Password</label>
                            </div>
                            <div className="form-floating mb-4">
                                <input type="password" className="form-control" minLength={8}  placeholder="password"  name="cpassword" value={user.cpassword} onChange={handleChange}/>
                                <label htmlFor="floatingInput">Confirm Password</label>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-blue-style w-100" onClick={register}>Register</button>
                                <p className='text-left text-muted my-3'>I already have an account click <span className='custom-text-color'><Link to={{pathname:"/login"}}>Login</Link></span></p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </>
  )
}
