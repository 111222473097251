import React from 'react'
import printer1 from '../../assets/images/static_pages/eletronics/printer/printer1.png'
import printer2 from '../../assets/images/static_pages/eletronics/printer/printer2.png'
import printer3 from '../../assets/images/static_pages/eletronics/printer/printer3.png'
import printer4 from '../../assets/images/static_pages/eletronics/printer/printer4.png'
import printer5 from '../../assets/images/static_pages/eletronics/printer/printer5.png'
import printer6 from '../../assets/images/static_pages/eletronics/printer/printer6.png'
import printer7 from '../../assets/images/static_pages/eletronics/printer/printer7.png'



export default function ElectronicsPrinter() {
  return (
    <>
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>printer's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">HP</h5>

                                 <p className='text-dark'>
                                 HP Smart Tank 530 Ink Tank Multi-function Color Wi-Fi Printer
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Canon</h5>

                                 <p className='text-dark'>
                                 Canon Pixma G670 Wireless All In One 6 Ink Tank Printer for High Volume Quality Photo Printing

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Epson</h5>
                               <p className='text-dark'>
                               Printer technology: InkTank
Functionality: Multi
Printer Output: Colour
Connector Type: USB 2.0, Wi-Fi and Wi-Fi Direct

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Label printer</h5>
                               <p className='text-dark'>F2C Direct Thermal Label Printer Compatible With Mac & Windows High-Speed 150 mm/s Shipping Label Printer Without Roll Stand-Black Thermal Receipt Printer</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">ID card printer</h5>
                               <p className='text-dark'>Smart Identity Card Printer</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">TCS</h5>
                               <p className='text-dark'>TSC ME340-300 DPI Industrial Barcode Printer, Print Width: 105 Mm (4.09"), Model Name/Number: ME-340 ₹ 52,000</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={printer7} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">ZEBRA</h5>
                               <p className='text-dark'>Zebra ZQ120 (3 inches) Direct Thermal Mobile Printer - with Mobile bluetooth . 17,000.00 Including GST (18%)</p>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>

    </>
  )
}
