import {
    AYURVEDA_ADD_REQUEST,
    AYURVEDA_ADD_SUCCESS,
    AYURVEDA_ADD_FAIL,
    AYURVEDA_ADD_RESET,
    AYURVEDA_LIST_REQUEST,
    AYURVEDA_LIST_SUCCESS,
    AYURVEDA_LIST_FAIL,
    AYURVEDA_LIST_OF_DISEASE_REQUEST,
    AYURVEDA_LIST_OF_DISEASE_SUCCESS,
    AYURVEDA_LIST_OF_DISEASE_FAIL,
    AYURVEDA_DETAILS_REQUEST,
    AYURVEDA_DETAILS_SUCCESS,
    AYURVEDA_DETAILS_FAIL,
    AYURVEDA_UPLOAD_REQUEST,
    AYURVEDA_UPLOAD_SUCCESS,
    AYURVEDA_UPLOAD_FAIL,
    AYURVEDA_UPDATE_REQUEST,
    AYURVEDA_UPDATE_SUCCESS,
    AYURVEDA_UPDATE_FAIL,
    AYURVEDA_UPDATE_RESET,
    AYURVEDA_DELETE_REQUEST,
    AYURVEDA_DELETE_SUCCESS,
    AYURVEDA_DELETE_FAIL,
    AYURVEDA_DELETE_RESET
} from '../constants/ayurvedaConstants'

export const addAyurvedaReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_ADD_REQUEST:
        return { loading: true }
      case AYURVEDA_ADD_SUCCESS:
        return { loading: false, ayurveda: action.payload, success:true }
      case AYURVEDA_ADD_FAIL:
        return { loading: false, error: action.payload }
      case AYURVEDA_ADD_RESET:
        return { }
      default:
        return state
    }
  }

  export const updateAyurvedaReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_UPDATE_REQUEST:
        return { loading: true }
      case AYURVEDA_UPDATE_SUCCESS:
        return { loading: false, ayurveda: action.payload, success: true }
      case AYURVEDA_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case AYURVEDA_UPDATE_RESET:
        return { }
      default:
        return state
    }
  }

  export const uploadAyurvedaFileReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_UPLOAD_REQUEST:
        return { loading: true }
      case AYURVEDA_UPLOAD_SUCCESS:
        return { loading: false, ayurvedaFile: action.payload, success: true }
      case AYURVEDA_UPLOAD_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listAyurvedaReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_LIST_REQUEST:
        return { loading: true }
      case AYURVEDA_LIST_SUCCESS:
        return { loading: false, ayurveda: action.payload.ayurvedaList }
      case AYURVEDA_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listAyurvedaOfDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case  AYURVEDA_LIST_OF_DISEASE_REQUEST:
        return { loading: true }
      case  AYURVEDA_LIST_OF_DISEASE_SUCCESS:
        return { loading: false, ayurveda: action.payload.ayurvedasList }
      case  AYURVEDA_LIST_OF_DISEASE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const ayurvedaDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_DETAILS_REQUEST:
        return { loading: true }
      case AYURVEDA_DETAILS_SUCCESS:
        return { loading: false, ayurvedaData: action.payload.ayurvedaData }
      case AYURVEDA_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const ayurvedaDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case AYURVEDA_DELETE_REQUEST:
        return { loading: true }
      case AYURVEDA_DELETE_SUCCESS:
        return { loading: false, ayurvedaData: action.payload, success: true }
      case AYURVEDA_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case AYURVEDA_DELETE_RESET:
        return {}
      default:
        return state
    }
  }