import './Verification.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import CustomCircularP from './widgets/customCircularP';
import { API_ENDPOINT } from '../rd-utils/constants';
import { CompareSharp } from '@mui/icons-material';

const Verification = () => {
    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    const linkCode = keysUrl[4]
    console.log(linkCode)
    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
       checkLink()
    }, [])
    function checkLink(){
        setLoading(true)
        axios.post(API_ENDPOINT+'user/verify', {token: linkCode}).then(res=>{
            setLoading(false)
            let d = res.data
            if(d.success){
                setVerified(true)
            }
        })
    }
    return (
        <div className='rd-verification'>
        {
            loading?<CustomCircularP />:
            verified?
            <div style={{textAlign: 'center', padding: '16px'}}>
                <h2>Email is Verified</h2>
                <a href='/login' style={{color: 'blue', textDecoration: 'underline'}}>Login Here</a>
            </div>:
            <div  style={{textAlign: 'center', padding: '16px'}}>
                <h2>Invalid Link</h2>
            </div>
        }
        </div>
    );
};

export default Verification;