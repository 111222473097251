import React, { Component, useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel';

import Menu from '../components/Menu'
import banner from '../assets/images/banner.png'; 
import img1 from '../assets/images/img1.png'; 
import img2 from '../assets/images/img2.png'; 
import img3 from '../assets/images/img3.png'; 

import logo1 from '../assets/images/logo1.png'; 
import logo2 from '../assets/images/logo2.png'; 
import logo3 from '../assets/images/logo3.png'; 
import logo4 from '../assets/images/logo4.png'; 
import logo5 from '../assets/images/logo5.png'; 

import bgBuleImg from '../assets/images/bg-blue-img.png'; 
import '../assets/css/style.css'

import ProductSlider from "../components/ProductSlider/ProductSlider";

import data from '../components/data'
import axios from 'axios';
import { API_ENDPOINT } from '../rd-utils/constants';

// import ProductCart from '../components/Product/ProductCart'

export default function Home() {


  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(false)

  function loadBanners(){
      setLoading(true)
      axios.get(API_ENDPOINT+'user/get-banners').then(res=>{
        setLoading(false)
        let d = res.data
        if(d.success){
          let bs = d.message.banners
          let bsA = bs.sort((a, b)=>a.order<b.order)
          setBanners(bsA)
        }
      })
  }
  useEffect(()=>{
    loadBanners()
  }, [])

  return (
    <>
    <div className="container mb-5">
     <Menu />

    {/* POSTER */}
      <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="card main_banner">
               {/* <img src={banner} alt="" /> */}
               <Carousel>


      {
        banners.map(b=>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={b.url}
            alt={b.order}
          />
        </Carousel.Item>)
      }
    </Carousel>
  
    
          </div> 
        </div>
      </div>
      {/* <div className='bg-blue mt-5'>
    <div className="container py-5">
      <div className="row justify-content-center">
       <div className="col-md-4">
            {/* <div className="text-center">
              <img className='full-img-size' src={bgBuleImg} alt=""/>
            </div> }
        </div> 
        <div className="col-md-12 text-light content-responsive">
          <div className='text-center'>
          <h1 >who are we?</h1>
          <p>Access to Quality Medicines at Affordable cost is a Right. It is not about Privileges. </p>
           <p>R K World Wide  was established in the year 2010. Ever since we laid down the structure of our working and organization we have been significant in catering to ailing citizens across globe by delivering quality medications. Generic medicines, Cosmetics, Herbal and Hormonal Preparations, Nutraceuticals, Devices etc</p>
          <p>We are amongst the best  indian Exporters.  We export and supply quality medications, that are formulated only by using approved by GMP, FDA, FSSAI, HALAL, WHO, ISO, AYUSH certifications and superior quality ingredients. Our team of quality controllers are responsible to check, correct and source only quality products through the help of our listed and approved Exporters. The products that reach the end consumers are sealed, packed, laminated and safe for consumption</p>
        </div>
        </div>
      </div>
    </div>
    </div> */}

     {/* PRODUCTS SEGMENTS */}
      <div className="row justify-content-center my-4">
          <div className="col-md-12 text-center my-3">
            <p className='text-info m-0'> High quality medicine for a better world</p>
            <h3 className="fw-bold mb-2">Product Segments</h3>
            <div className="row box-wrap">
               <ProductSlider />
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
          <Link  className='btn btn-blue-style add_cart_btn' to={{pathname:"/product"}}>View all <i className="fa-solid fa-angle-right"></i></Link>
          </div>
      </div>

      {/*Product Images */}
      <div className="row justify-content-center my-5">
        <div className="col-md-4 my-2">
          <div className="card border border-0 home_img">
             <img src={img1} alt="" />
            </div>
        </div>
        <div className="col-md-4 my-2">
          <div className="card border border-0 home_img">
            <img src={img2} style={{height:'165px'}} alt="" />
          </div>
        </div>
        <div className="col-md-4 my-2">
          <div className="card border border-0 home_img">
          <img src={img3} alt="" />
        
          </div>
        </div>
      </div>


    </div>

{/* Contact Us */}
  <section className='bg-blue'>
    <div className="container py-5">
      <div className="row justify-content-center">
       <div className="col-md-4">
            <div className="text-center">
              <img className='full-img-size' src={bgBuleImg} alt=""/>
            </div>
        </div> 
        <div className="col-md-6 text-light content-responsive">
          <div>
          <h1>How We Can Help You?</h1>
          <p>RK India is among the trusted surgical instruments suppliers and medical equipment dealers in India, which makes your procurements of medical equipment, supplies, products, consumables and medical devices online in India easier than ever. </p>
          <div className="row mt-3">
            <div className="col-md-6 my-2">
               <div className=""><span><i className="fa-solid fa-mobile"></i> +91-9448084952</span></div>
               <div className=""><span><i className="fa-solid fa-mobile"></i> +91-9620596301</span></div>

            </div>
            <div className="col-md-6 my-2">
              <div className="text-center"> <span><i className="fa-solid fa-location-dot"></i> 05,Prathibha paradise,3rd Cross,M G Road,Tumkur-572102.Karnataka.</span></div>
            </div>
          </div>
            <Link  className='btn contact-btn btn-lg my-4' to={{pathname:"/contact"}}>Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
  </section> 

{/* Brand */}
  <section className='d-none'>
    <div className="container">
        <div className="row my-5">
          <div className="col-md-3">
              <div className="text-center  my-3">
                <img src={logo1} alt=""/>
              </div>
          </div>
          <div className="col-md-2 ">
              <div className="text-center my-3">
                <img src={logo2} alt=""/>
              </div>
          </div>
          <div className="col-md-2 ">
              <div className="text-center my-3">
                <img src={logo3} alt=""/>
              </div>
          </div>
          <div className="col-md-3 ">
              <div className="text-center my-3">
                <img src={logo4} alt=""/>
              </div>
          </div>
          <div className="col-md-2 ">
              <div className="text-center my-3">
                <img src={logo5} alt=""/>
              </div>
          </div>
        </div>
    </div>
  </section>

    </>
  )
}
