import React from 'react'
import about from '../assets/images/about/about.jpg'
import about1 from '../assets/images/about/about1.jpg'
import about2 from '../assets/images/about/about2.jpg'
import about3 from '../assets/images/about/about3.jpg'
import about4 from '../assets/images/about/about4.jpg'


export default function Disclaimer() {
  return (
    <>
      <div className="container my-4">
      {/* SECTION 1 */}
        <div className="row justify-content-center">
            <div className="col-md-10 text-center my-4">
            <figure className="text-center">
              <blockquote className="blockquote">
                <p className='display-5 fw-bold'>What can we help you with? </p>
              </blockquote>
              <figcaption className="blockquote-footer">
                <cite title="Source Title"> RK Groups</cite>
              </figcaption>
            </figure>
                <p className='lead'>Tell us about your needs and we’ll pair you with our expert. </p>
                <p className='lead text-center m-0'><span className='text-muted fw-bold'>Check our website status </span> We perform planned maintenance activities and enhancements periodically to provide you a consistent user experience. Yet up to date information on the past and upcoming maintenance and enhancement activities. </p>
            </div>

            <div className="col-md-10 text-center my-4">
            <figure className="text-center">
              <blockquote className="blockquote">
                <p className='display-5 fw-bold'>Disclaimer</p>
              </blockquote>
            </figure>
                <p className='lead'>User acknowledges that the information in the website is provided as is and for general information only. It is not intended as medical advice and should not be relied upon as a substitute for professional consultation with a qualified healthcare provider familiar with your individual medical needs every effort is taken to ensure that the information published on this website is accurate and informative. This content was provided for informational purpose only. The opinions and insights discussed are those of the presenter and guests and do not necessarily represent those of the R K. </p>
                <p className='lead text-center m-0'><span className='text-muted fw-bold'>R K </span> does not warrant that any systems products or services are immune from, the malicious or illegal contact of any party. R K company strives for actual and correct information on this website nevertheless it’s possible that certain information turns out to be incomplete, has become obsolete or is no longer accurate. </p>
            </div>
           
        </div>

      </div>


      
    </>
  )
}
