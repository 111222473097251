import React, { useEffect, useState, useCallback } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import Paginations from '../components/Pagination/Paginations'
import data from '../components/data'
import '../components/Pagination/style.css'

import Menu from '../components/Menu'
import ProductFilter from '../components/ProductFilter'
import Banner from '../assets/images/banner.jpg'
import ProductCart from '../components/Product/ProductCart'
import { medicineList } from '../redux/actions/medicineActions';
import { labTestList } from '../redux/actions/labTestActions'
import { ayurvedaList } from '../redux/actions/ayurvedaActions'
import axios from 'axios'
import { API_ENDPOINT } from '../rd-utils/constants'
import CustomCircularP from '../rd-pages/widgets/customCircularP'

export default function Product() {
    const {name, category} = useParams() 
    const LIMIT = 15
    const [totalData, setTotalData] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [currentData, setCurrentData] = useState([])

    const loc = document.location.href.replace(/\/+$/, "")
    const keysUrl = loc.split('/')
    let linkCode = ''
    if(keysUrl.length>4){
        linkCode = decodeURI(keysUrl[4])
    }
    
    const [typeFilter, setTypeFilter] = useState("")
    const [categoryFilter, setCategoryFilter] = useState("")
    const [subCatFilter, setSubCatFilter] = useState("")

    const [loading, setLoading] = useState(false)
    const [nameSearch, setNameSearch] = useState(linkCode)
    const onPageChanged =   useCallback(
        (event, pn) => {
          event.preventDefault();
          console.log(pn)
          setPageNum(pn);
        },
        [setPageNum]
      );
    const sortHandler = (val)=>{
        console.log(val)
    }
    useEffect(()=>{
        loadProducts()
    }, [pageNum, linkCode, typeFilter, categoryFilter, subCatFilter])
    function loadProducts(){
        setLoading(true)
        axios.post(API_ENDPOINT+'user/get-products', {page: pageNum, nameSearch: linkCode, type: typeFilter, category: categoryFilter}).then(res=>{
            let d = res.data
            setLoading(false)
            if(d.success){
                setCurrentData(d.message)
            }
        })
    }
    useEffect(()=>{
        getProductsCount()
    }, [])
    function getProductsCount(){
        axios.post(API_ENDPOINT+'user/get-products-count', {}).then(res=>{
            let d = res.data
            if(d.success){
                setTotalData(d.message)
            }
        })
    }
    return (
    <>
       <div className='text-center'>
        <CustomCircularP show={loading}/>
        <Menu />
       </div>
        <div className="container-fluid">
            {/* Breadcum */}
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className='Breadcrumbs'>
                        <p><Link to={'/'} className="text-muted">Home</Link>&gt;<Link to={'/medicine'} className="text-muted">Prescription</Link>&gt;<span className="custom-text-color"> Products </span></p>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-3">
                    <div className='cat_mobile d-md-none d-lg-none'>
                        <p style={{cursor: 'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"  aria-controls="offcanvasScrolling">
                            <i className="fa-solid fa-bars"></i> Filters
                        </p>
                        <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                            <div className="offcanvas-header">
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"/>
                            </div>
                            <div className="offcanvas-body">
                                <ProductFilter  category = {categoryFilter}/>
                            </div>
                        </div>
                    </div>
                    <div className='cat_desktop d-filter'>
                            <ProductFilter  category = {category}/>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className='d-none'>
                        <img src={Banner} style={{width:'100%'}} alt="" />
                    </div>
                    <div className="d-flex">
                        <div className="p-2 flex-grow-1"><small> Showing <span className='fw-bold'>{LIMIT}</span> of <span className='fw-bold'>{totalData}</span> items</small></div>
                        <div className="p-2">
                           <small>Sort by:</small>
                        </div>
                        <div className="p-2">
                            <select className="form-select form-select-sm" aria-label="Default select example" onChange={(e)=> setTypeFilter(e.target.value)}>
                                {/* <option selected>Popularity</option>
                                <option value="1">Latest Products</option>  */}
                                <option selected value="">All</option>
                                <option value="medicine">Medicines</option>
                                <option value="labtest">Lab Tests</option>
                                <option value="ayurveda">Ayurveda</option>
                            </select>
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col-md-12">
                            <div className="card border-0">
                                <div className="card-body">
                                    <h6>ALL PRODUCTS</h6>
                                    {totalData && 
                                        <div className="pagination-wrapper mb-5">
                                            <Paginations
                                                totalRecords={ totalData}
                                                pageLimit={LIMIT}
                                                pageNeighbours={4}
                                                onPageChanged={onPageChanged}
                                                currentPage={pageNum}
                                            />
                                        </div> 
                                    } 
                                    <div className="row justify-content-center ">

                                        {currentData.map((item, i) => {
                                            return (
                                                <div key={i} className='col-md-4'>
                                                <ProductCart key={i}  item={item} />
                                            </div>
                                                );
                                            })}
                                    </div>
                                    {totalData && 
                                        <div className="pagination-wrapper mb-5">
                                            <Paginations
                                                totalRecords={ totalData}
                                                pageLimit={LIMIT}
                                                pageNeighbours={4}
                                                onPageChanged={onPageChanged}
                                                currentPage={pageNum}
                                            />
                                        </div> 
                                    } 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
