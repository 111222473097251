import { Button } from '@mui/material'
import { blue } from '@mui/material/colors'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSyncExternalStore } from 'react'
import CustomCircularP from '../../rd-pages/widgets/customCircularP'
import { API_ENDPOINT } from '../../rd-utils/constants'
import { capitalizeFirstLetter, getDateFormat, getHeader } from '../../rd-utils/util'
import MyReceipt from './MyReceipt'
import ProductItems from './ProductItems'

export default function MyOrder() {

  const ORDERS = "Orders"
  const RECEIPT = "Receipt"
  const ITEMS = "Items"

  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(ORDERS)
  const [currentOrder, setCurrentOrder] = useState({})

  useEffect(()=>{
      loadOrders()
  }, [])
  function loadOrders(){
    setLoading(true)
    axios.get(API_ENDPOINT+'user/get-my-orders', getHeader()).then(res=>{
      setLoading(false)
      let d = res.data
      if(d.success){
        setOrders(d.message)
      }
    })
  }
  const handleShowItems  = (order_id)=>{
      let order = orders.find(o=>o.order_id === order_id)
      setCurrentOrder(order)
      setCurrentPage(ITEMS)
  }
  const back = ()=>{
    setCurrentPage(ORDERS)
  }
  const goToUpload = (od)=>{
    setCurrentOrder(od)
    setCurrentPage(RECEIPT)
  }
  return (
    <>
  {currentPage === ORDERS?
      <div>
          <h5 className='my-3 custom-text-color fw-bold'>My Orders</h5>
          <CustomCircularP show={loading}/>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">ORDER ID</th>
                <th scope="col">DATE</th>
                <th scope="col">STATUS</th>
                <th scope="col">PAYMENT RECEIPT</th>
                <th scope="col">View</th>
              </tr>
            </thead>
            <tbody>
          {
            orders.map(o=>(
              <tr>
              <td>#{o.order_id}</td>
              <td>{getDateFormat(new Date(o.createdOn))}</td>
              <td>{capitalizeFirstLetter(o.status)}</td>
              <td><Button variant='outlined' onClick={()=>goToUpload(o)}> {o.receipt?'View Receipt':'Upload Now'}</Button></td>
              <td className='custom-text-color'><span style={{color: blue, textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>handleShowItems(o.order_id)}>View Items</span></td>
            </tr>
            ))
          }
            </tbody>
          </table>
      </div>: currentPage === ITEMS?
      <ProductItems back = {back} order = {currentOrder} />:
      <MyReceipt order = {currentOrder} back={back} />
}
    </>
  )
}
