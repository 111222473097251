import React from 'react'
import img1 from '../../assets/images/static_pages/logistics/custom/img1.png'
import img2 from '../../assets/images/static_pages/logistics/custom/img2.png'


export default function LogisticsCustom() {
  return (
    <>
       <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4 logistics_custom_banner">
                        <h1 className='fw-bolder text-uppercase text-light text-center mt-4' style={{marginBottom: '20%'}}> CUSTOM CLEARANCE</h1>
                    </div>
                 
                   
                    <div className="row my-3">
                        <div className="col-md-8">
                        
                                 <h2 className='text-dark fw-bold'>What is Customs Clearance?</h2>
                                 <p className='text-dark'>Essentially, customs clearance involves the preparation and submission of documentation that is required to export or import your goods into or out of a country.</p>
                                 <p className='text-dark'> Customs clearance is a crucial part of your cargo shipping from Point A to Point B seamlessly across the world.</p>
                        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img1} style={{width: '100%'}} alt=""/>
                          </div>
                        </div>
                    </div>

                    <div className="row my-3">
                        <div className="col-md-4">
                            <div className='text-center'>
                                <img src={img2} style={{width: '100%'}} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-8">
                                 <h2 className='text-dark fw-bold'>Our Custom Clearance Services at R K  include</h2>
                                 <p className='text-dark fw-bold'>If you are not familiar yet with our Customs Clearance benefits, here’s an overview:</p>
                               
                                <p className="text-dark fw-bold">Import Customs Clearance</p>
                                <p className="text-dark">Import customs clearance is a government requirement to gain release of inbound cargo that involves clearing goods through customs borders and territories.</p>
                               
                                <p className="text-dark mt-3 fw-bold">Export Customs Clearance</p>
                                <p className="text-dark">Export customs clearance is a government requirement to gain permission to load an outbound vessel for exporters shipping outside of their trade zones.</p>

                                <p className="text-dark mt-3 fw-bold">Customs Transit Documentation</p>
                                <p className="text-dark">This allows customs clearance formalities to take place at the destination point rather than at the point of entry into the customs territory.</p>
       
                        </div>
                      
                    </div>

                    
            </div>
        </div>
    </>
  )
}
