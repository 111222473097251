import React from 'react'
import peripherals1 from '../../assets/images/static_pages/eletronics/peripherals/peripherals1.png'
import peripherals2 from '../../assets/images/static_pages/eletronics/peripherals/peripherals2.png'
import peripherals3 from '../../assets/images/static_pages/eletronics/peripherals/peripherals3.png'
import peripherals4 from '../../assets/images/static_pages/eletronics/peripherals/peripherals4.png'
import peripherals5 from '../../assets/images/static_pages/eletronics/peripherals/peripherals5.png'
import peripherals6 from '../../assets/images/static_pages/eletronics/peripherals/peripherals6.png'
import peripherals7 from '../../assets/images/static_pages/eletronics/peripherals/peripherals7.png'
import peripherals8 from '../../assets/images/static_pages/eletronics/peripherals/peripherals8.png'
import peripherals9 from '../../assets/images/static_pages/eletronics/peripherals/peripherals9.png'
import peripherals10 from '../../assets/images/static_pages/eletronics/peripherals/peripherals10.png'
import peripherals11 from '../../assets/images/static_pages/eletronics/peripherals/peripherals11.png'
import peripherals12 from '../../assets/images/static_pages/eletronics/peripherals/peripherals12.png'
import peripherals13 from '../../assets/images/static_pages/eletronics/peripherals/peripherals13.png'


export default function ElectronicsPeripherals() {
  return (
    <>
       <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>Peripheral's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">KEYBOARD</h5>
                                 <p className='text-dark'>
                                 Logitech K120 / Full-Size, Spill-Resistant, Curved Space Bar Wired USB Desktop Keyboard
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">MOUSE</h5>

                                 <p className='text-dark'>
                                 Logitech M90 Wired USB Mouse, 3 yr Warranty, 1000 DPI Optical Tracking, Ambidextrous PC/Mac/Laptop - Black

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">SPEAKERS</h5>
                               <p className='text-dark'>Usage/Application: Industria  Color: Grey
Brand: bosch
Certificate: CE

Ceiling: Speakers
</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">PCI</h5>
                               <p className='text-dark'>Supports a 32-bit 5 V PCI bus (PCI-7224/7248/7296)
x1 PCI Express® interface (PCIe-7248/7296)
96-CH digital TTL/DTL inputs/outputs (PCI/PCIe-7296)
48-CH digital TTL/DTL inputs/outputs (PCI/PCIe-7248
</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">RAM</h5>
                               <p className='text-dark'>Currently, the largest single stick of RAM is 128 GB.</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">EXTERNAL HARDDISK</h5>
                               <p className='text-dark'>Transcend 1TB External Hard Disk, 25M3</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals7} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">PROCESSOR</h5>
                               <p className='text-dark'>INTEL 13TH GEN CORE I9-13900K RAPTOR LAKE</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals8} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">SSD</h5>
                               <p className='text-dark'>SanDisk SSD PLUS 2TB Internal SSD - SATA III 6 Gb/s, 2.5"/7mm, Up to 545 MB/s - SDSSDA-2T00-G26</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals9} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">HARDDISK</h5>
                               <p className='text-dark'>IT Hub 500 GB Desktop Internal Hard Disk Drive (HD 500gb)</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals10} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">DVD</h5>
                               <p className='text-dark'>Mediasonic DVD Player - Upscaling 1080P All Region DVD Player for Home with HDMI / AV Output, USB Multimedia Player Function, High Speed ​​HDMI 2.0 & AV Cable (HW210AX)</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals11} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">MOTHER BOARD</h5>
                               <p className='text-dark'>Motherboard Mainboard Mobo ddr3 Intel PC Merk Hp Paket Processor Intel Core i5 650 3.20GHz Ori Murah</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals12} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">SMPS</h5>
                               <p className='text-dark'>Brand ‎FRONTECH
Manufacturer ‎FRONTECH
Package Dimensions ‎15 x 11 x 8 cm; 593 Grams</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={peripherals13} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">MOTHER BOARD FAN</h5>
                               <p className='text-dark'>CPU COOLING FAN (I3 / I5 / I7) LGA -1150/1155/1165 MOTHERBOARD SUPPORTED (ORIGINAL) ALUMINUM HEATSINK STOCK FAN (H61 / H55 / H81 MOTHERBOARD SUPPORTED)
</p>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
