import axios from "axios";
import {toast} from 'react-toastify'

import {
    MEDICINE_ADD_REQUEST,
    MEDICINE_ADD_SUCCESS,
    MEDICINE_ADD_FAIL,
    MEDICINE_LIST_REQUEST,
    MEDICINE_LIST_SUCCESS,
    MEDICINE_LIST_FAIL,
    MEDICINE_LIST_OF_DISEASE_REQUEST,
    MEDICINE_LIST_OF_DISEASE_SUCCESS,
    MEDICINE_LIST_OF_DISEASE_FAIL,
    MEDICINE_DETAILS_REQUEST,
    MEDICINE_DETAILS_SUCCESS,
    MEDICINE_DETAILS_FAIL,
    MEDICINE_DELETE_IMAGES_OR_ICON_REQUEST,
    MEDICINE_DELETE_IMAGES_OR_ICON_SUCCESS,
    MEDICINE_DELETE_IMAGES_OR_ICON_FAIL,
    MEDICINE_UPLOAD_REQUEST,
    MEDICINE_UPLOAD_SUCCESS,
    MEDICINE_UPLOAD_FAIL,
    MEDICINE_UPDATE_REQUEST,
    MEDICINE_UPDATE_SUCCESS,
    MEDICINE_UPDATE_FAIL,
    MEDICINE_DELETE_REQUEST,
    MEDICINE_DELETE_SUCCESS,
    MEDICINE_DELETE_FAIL,
} from '../constants/medicineConstants'
import { logout } from "./userActions";

export const addMedicine = (medicineData) => async(dispatch, getState) => {
    dispatch({
        type: MEDICINE_ADD_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'auth': userInfo.token,
            },
          }

        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/medicine/add`,medicineData, config)

        dispatch({
            type: MEDICINE_ADD_SUCCESS,
            payload: data
        })

        if(data.status === 409){
          toast.warn(data.message, {
            position: toast.POSITION.BOTTOM_LEFT
          });
        }else{

        toast.success("Medicine Added Successful", {
          position: toast.POSITION.BOTTOM_LEFT
        });
      }
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: MEDICINE_ADD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })

          toast.error("Something went Wrong", {
            position: toast.POSITION.BOTTOM_LEFT
          });
        
    }
}

export const updateMedicine = (id,medicineData) => async(dispatch, getState) => {
    dispatch({
        type: MEDICINE_UPDATE_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'auth': userInfo.token
            },
          }

        const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/medicine/${id}`, medicineData, config)

        dispatch({
            type: MEDICINE_UPDATE_SUCCESS,
            payload: data
        })

        toast.success("Medicine Details Successfully Updated", {
          position: toast.POSITION.BOTTOM_LEFT
        });
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: MEDICINE_UPDATE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const uploadMedicineFiles = (id,medicineData) => async(dispatch, getState) => {
  dispatch({
      type: MEDICINE_UPLOAD_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'auth': userInfo.token
          },
        }

      const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/medicine/upload/${id}`, medicineData, config)

      dispatch({
          type: MEDICINE_UPLOAD_SUCCESS,
          payload: data
      })

      toast.success("Uploaded Successfully", {
        position: toast.POSITION.BOTTOM_LEFT
      });
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: MEDICINE_UPLOAD_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const medicineList = () => async(dispatch, getState) => {
    dispatch({
        type: MEDICINE_LIST_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json'
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/medicine/list`, config)

        dispatch({
            type: MEDICINE_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {


        dispatch({
            type: MEDICINE_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const medicineListOfDisease = (disease) => async(dispatch, getState) => {
  dispatch({
      type: MEDICINE_LIST_OF_DISEASE_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/medicine/disease/list?diseaseName=${encodeURI(disease)}`, config)

      dispatch({
          type: MEDICINE_LIST_OF_DISEASE_SUCCESS,
          payload: data
      })
      
  } catch (error) {

      dispatch({
          type: MEDICINE_LIST_OF_DISEASE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const medicineDetails = (id) => async(dispatch, getState) => {
    dispatch({
        type: MEDICINE_DETAILS_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/medicine/${id}`, config)

        dispatch({
            type: MEDICINE_DETAILS_SUCCESS,
            payload: data
        })
        
    } catch (error) {

        dispatch({
            type: MEDICINE_DETAILS_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const deleteMedicine = (id) => async(dispatch, getState) => {
    dispatch({
        type: MEDICINE_DELETE_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth' : userInfo.token
            },
          }

        const {data} = axios.delete(`${process.env.REACT_APP_BASE_URL}api/medicine/${id}`, config)

        dispatch({
            type: MEDICINE_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {

      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }
        dispatch({
            type: MEDICINE_DELETE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const deleteMedicineImageorIcon = (category, id) => async(dispatch, getState) => {
  dispatch({
      type: MEDICINE_DELETE_IMAGES_OR_ICON_REQUEST
  })
  try {
      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth' : userInfo.token
          },
        }

      const {data} = axios.put(`${process.env.REACT_APP_BASE_URL}api/medicine/remove/imageOrIcon/${id}`, category, config)

      dispatch({
          type: MEDICINE_DELETE_IMAGES_OR_ICON_SUCCESS,
          payload: data
      })
      
  } catch (error) {

    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }
      dispatch({
          type: MEDICINE_DELETE_IMAGES_OR_ICON_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

