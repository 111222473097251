import React from 'react'
import projector1 from '../../assets/images/static_pages/eletronics/projector/projector1.png'
import projector2 from '../../assets/images/static_pages/eletronics/projector/projector2.png'
import projector3 from '../../assets/images/static_pages/eletronics/projector/projector3.png'
import projector4 from '../../assets/images/static_pages/eletronics/projector/projector4.png'
import projector5 from '../../assets/images/static_pages/eletronics/projector/projector5.png'
import projector6 from '../../assets/images/static_pages/eletronics/projector/projector6.png'
import projector7 from '../../assets/images/static_pages/eletronics/projector/projector7.png'
import projector8 from '../../assets/images/static_pages/eletronics/projector/projector8.png'
import projector9 from '../../assets/images/static_pages/eletronics/projector/projector9.png'

export default function ElectronicsProjector() {
  return (
    <>
      <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>projector's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">EPSON</h5>
                                 <p className='text-dark'>
                                 le35ppm/70ipm
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">EPSON</h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">EPSON</h5>
                             </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">CANON</h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">CANON</h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">CANON</h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector7} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">PROJECTOR SCREEN </h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector8} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">MONITOR </h5>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={projector9} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">TV</h5>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
