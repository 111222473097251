const RK_TOKEN = "rk_token"
const RK_USER = "rk_user"
const removeDuplicatesStringArray = (arr)=>{
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}
const capitalizeFirstLetter = (string)=>{
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const toDiseaseURL = (disease)=>{
  let t1 = disease.replace(/\s/g,'+')
  let t2 = t1.replaceAll('/', 'xxxx')
  return t2
}
const decodeDiseaseURL = (disease)=>{
  let t1 = disease.replace(/\+/g,' ')
  let t2 = t1.replaceAll('xxxx', '/')
  return t2
}

const saveToken = (token)=>{
    localStorage.setItem(RK_TOKEN,token)
}
const saveUser = (user)=>{
    localStorage.setItem(RK_USER, JSON.stringify(user))
}
const getUserName = ()=>{
  let user = localStorage.getItem(RK_USER)
  let userO = JSON.parse(user)
  return userO.name
}
const getUserEmail = ()=>{
  let user = localStorage.getItem(RK_USER)
  let userO = JSON.parse(user)
  return userO.email
}
const getToken = () =>{
   return localStorage.getItem(RK_TOKEN)
}
const getHeader = ()=>{
  return {
            headers:{
                'content-type': 'application/json',
                token: getToken()
            }
        }
}
const getDateFormat = (d)=>{
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  let y = d.getFullYear()
  let dd = '0'+d.getDate()
  let h = '0'+d.getHours()
  let min = '0'+d.getMinutes()
  return dd.slice(-2)+' '+months[d.getMonth()]+' '+y+', '+h.slice(-2)+':'+min.slice(-2)
}
const deleteToken = ()=>{
   localStorage.removeItem(RK_TOKEN)
}
const isLoggedIn = ()=>{
   return localStorage.getItem(RK_TOKEN)!== null
}
export {removeDuplicatesStringArray, getHeader, getDateFormat, toDiseaseURL, decodeDiseaseURL, capitalizeFirstLetter, saveToken, getToken, deleteToken, isLoggedIn, saveUser, getUserName, getUserEmail}