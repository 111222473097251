import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate, useLocation} from 'react-router-dom';
import CustomCircularP from '../rd-pages/widgets/customCircularP';
import { API_ENDPOINT, HEADER_TOKEN } from '../rd-utils/constants';
import { capitalizeFirstLetter, decodeDiseaseURL, removeDuplicatesStringArray, toDiseaseURL } from '../rd-utils/util';
import { diseaseList } from '../redux/actions/diseaseActions';

export default function DiseaseMedicine() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [diseases, setDiseases] = useState([])
  const [cf, setCf] = useState('')

  const loc = document.location.href.replace(/\/+$/, "")
  const keysUrl = loc.split('/')
  const diseaseFilter = decodeDiseaseURL(keysUrl[4])
  
  const title = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','ALL']

  useEffect(()=>{
    loadMedicines()
  }, [])
  function loadMedicines(){
    setLoading(true)
    axios.post(API_ENDPOINT+'user/diseases/products', {disease: diseaseFilter, type: 'medicine'}).then(res=>{
      setLoading(false)
      let d = res.data
      console.log(d)
      if(d.success){
        setDiseases(d.message)
      }
    })
  }
return (
<>
        <div className="container">
          <CustomCircularP show={loading}/>
            <div className="row my-5 d-filter">
               <div className="col-md-12">
                    <div>
                        <p>
                            <Link to={"/"} className="text-text-color">Home</Link><span> {'>'} </span> 
                            <Link to={"/medicine"}><span className='custom-text-color'>Medicine</span> <span> {'>'} </span> </Link>
                            <span className='custom-muted'>{capitalizeFirstLetter(diseaseFilter)}</span>
                        </p>
                    </div>
                    <div style={{fontWeight: 'bold', lineHeight: '0.9', fontSize: '28px', color: '#24aeb1'}}>{capitalizeFirstLetter(diseaseFilter)}({diseases.length})</div>
                    <div style={{fontStyle: 'italic', color: '#24aeb1', marginBottom: '16px'}}>Drug List</div>

                    <div className="content-section">
                    <div id="list-nav-nav" className="listNav">
                        <div className="ln-letters">
                        {
                             title.map((alpha)=>(
                                  <div className={alpha===cf? 'rd-alpha-item active':'rd-alpha-item'} onClick={()=>setCf(alpha==='ALL'?'':alpha)}>{alpha}</div>
                            ))
                         }
                        </div>  
                      </div>

                        <div className="prescriptions_products_single" style={{columnCount: 4}} id="list-nav">
                        {
                          cf===''?
                          title.map(letterHandel=>(
                              <div className="drug-list-col ln-a">
                                {diseases.filter(d =>d.name.charAt(0).toUpperCase() === letterHandel).length>0?
                                <>
                                    <h3>{letterHandel}</h3>
                                    <ul className="alpha-drug-list">
                                    {
                                    diseases.filter(d =>d.name.charAt(0).toUpperCase() === letterHandel).map(filteredDisease => (
                                        <li>
                                            <Link  to={`/product-detail/${filteredDisease._id}`}><span style={{color: '#777', fontWeight: 'bold', textDecoration: 'underline'}}>{filteredDisease.name}</span></Link>
                                        </li>
                                    ))
                                    }
                                    </ul>
                                </>:''}

                              </div>)):
                              <div className="drug-list-col ln-a">
                                <h3>{cf}</h3>
                                <ul className="alpha-drug-list">
                                  {
                                  diseases.filter(d =>d.name.charAt(0).toUpperCase() === cf).map(filteredDisease => (
                                    <li>
                                        <Link  to={`/product-detail/${filteredDisease._id}`}><span style={{color: '#777', fontWeight: 'bold', textDecoration: 'underline'}}>{filteredDisease.name}</span></Link>
                                    </li>
                                  ))
                                  }
                                </ul>
                              </div>
                          }

                        </div>
                    </div> 
                  </div>
            </div>

            {/*  Mobile view  */}
            <div className="main-content header-fix d-md-none d-lg-none">
              <div className="pres-list">
                <div className="pres_title">
                  <h2>Find Your Prescription Medicines</h2>
                  <span>Shop by Drug Category</span>
                </div>
                <div className="pres-list-grid">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex">
                        <div className='p-2 flex-fill' id="prescriptions_categories" style={{overflow: 'scroll', height: '650px'}}>
                          {/* {
                            diseases && diseases.diseasesList.map(filteredDisease => (
                              <div className="drug-list-col">
                                <h3 className="alpha-title" id="list-A" data-char="A" />
                                <ul className="alpha-drug-list">
                                  <li>
                                    <a href="#">{filteredDisease.name}</a>
                                  </li>
                                </ul>
                              </div>
                            ))
                          } */}
                        </div>
                        {/* Alphabet Scroll Section */}
                        <div className='p-2 flex-fill' id="alphabets_box">
                          <ul id="alphabets" >
                              {/* {
                                title.map((alpha)=>(
                                    <li data-char="{alpha}">{alpha}</li>
                                ))
                              } */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
</>
  )
}