import React from 'react'
// import about from '../assets/images/about/about.jpg'
import desktop from '../../assets/images/static_pages/eletronics/desktop/acer1.png'
import laptop from '../../assets/images/static_pages/eletronics/laptop/acer.png'
import mobile from '../../assets/images/static_pages/eletronics/mobile/iphone.png'
import camera from '../../assets/images/static_pages/eletronics/camera/camera1.png'
import printer from '../../assets/images/static_pages/printer.png'
import scanner from '../../assets/images/static_pages/scanner.png'
import peripherals from '../../assets/images/static_pages/peripherals.png'
import accessories from '../../assets/images/static_pages/acceriores.png'
import network from '../../assets/images/static_pages/network.png'
import projector from '../../assets/images/static_pages/projector.png'
import it from '../../assets/images/static_pages/it.png'








import {Link, useNavigate} from 'react-router-dom';
import '../static_pages/static.css'

export default function Electronics() {
  return (
    <>
    <div className="container my-4">
        <div className="row justify-content-center">
                <div className="col-md-12 my-4">
                    <h1 className='fw-bolder text-uppercase text-center'>Electronics</h1>
                </div>
               
                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={desktop} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">DESKTOP</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-desktop"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={laptop} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">LAPTOP</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-laptop"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={mobile} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">MOBILE PHONE</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-mobile"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={camera} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">CAMERAS</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-camera"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={printer} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PRINTER</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-printer"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={scanner} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">SCANNER</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-scanner"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={peripherals} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PERIPHERALS</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-peripherals"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={accessories} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">ACCESSORIES</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-accessories"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={network} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">NETWORK PRODUCTS</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-network"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={projector} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">PROJECTORS</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-projector"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className="card effect my-3" >
                        <img src={it} width={100} height={'300px'} className="card-img-top" alt="..." />
                        <div className="card-body text-center">
                          <div class="d-flex">
                            <div class="p-2 flex-fill text-start"> <h4 className="m-0 fw-bold">IT SERVICES</h4></div>
                            <div class="p-2 flex-fill text-end"><Link to={"electronics-it"} className="btn btn-outline-dark btn-sm rounded-pill">Know More</Link></div>
                          </div>
                        </div>
                    </div>
                </div>

                
                <div className="col-md-12 my-4 text-end">
                  <button className='btn btn-outline-dark btn-lg'>Get A QUOTE</button>
                </div>
        </div>
    </div>

    </>
  )
}


