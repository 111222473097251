import React from 'react'

import '../static_pages/static.css'
import img1 from '../../assets/images/static_pages/logistics/domestic/img1.png'
import img2 from '../../assets/images/static_pages/logistics/domestic/img2.png'
import img3 from '../../assets/images/static_pages/logistics/domestic/img3.png'
import img4 from '../../assets/images/static_pages/logistics/domestic/img4.png'

export default function LogisticsDomestic() {
  return (
    <>
         <div className="container my-4">
            <div className="row">
                   <div className="col-md-12 my-4 logistics_domestic_banner">
                        <h1 className='fw-bolder text-uppercase text-light text-center mt-4' style={{marginBottom: '20%'}}> DOMESTIC AIR FREIGHT</h1>
                    </div>
                    
                    {/*  */}

                    <div className="col-md-8  my-3">
                          <h2 className='text-dark fw-bold'> EXPRESS ENVELOPS</h2>
                          <p className='text-dark'>Your confidential legal documents delivers on time are always tracked! At  RK world Wide, we make reliability a top priority. When sending sensitive or confidential papers through our documents courier services, your envelope will always receive a unique tracking number so you can follow its progress until the recipient. Through our digital tracking tool, you will get real-time updates provided by the logistics company responsible in advanced technology .</p>
                    </div>
                    <div className="col-md-4">
                      <div className='text-center'>
                        <img src={img1} width={'200px'} alt=""/>
                      </div>
                    </div>

                    {/*  */}
                    
                    <div className="col-md-4  my-3">
                          <img src={img2} width={'100%'} alt=""/>
                  
                    </div>
                    <div className="col-md-8  my-3">
                        <h2 className='text-dark fw-bold'>E-COMMERCE</h2>
                        <p className='text-dark'>How E-Commerce Logistics Works </p>
                        <p className='text-dark fw-bold'>A logistics company functions in two directions:</p>
                        <ul style={{listStyleType: 'circle'}}>
                          <li>Forward Direction – Distribution and delivery of goods to buyers.</li>
                          <li>Reverse Direction – Exchanging or replacement of defective, damaged or wrong shipments.</li>
                        </ul>
                      

                            
                           
                            
                    </div>

                    {/*  */}

                    <div className="col-md-8  my-3">
                      <h2 className='text-dark fw-bold'>EXPRESS AIR CARGO</h2>
                        <p className='text-dark'>RK World wide provides round-the-clock services and time sensitive express deliveries. It delivers in all major metros in 24 hours and Mini Metros and A class cities in 48 hours.</p>
                        <p className='fw-bold'>KEY DIFFERENTIATORS</p>
                      <div className="row">
                        <div className="col-md-6">
                        <ul style={{listStyleType: 'circle'}}>
                          <li>Door-to-Door / Door-to-Airport services</li>
                          <li>Airport-to-Airport / Airport-to-Door services</li>
                          <li>Multimode service – Unique combination of air and surface modes</li>
                          <li>Unique arrangement of space with all domestic carriers</li>
                          <li>Collection on Delivery (COD)</li>
                      </ul>
                        </div>
                        <div className="col-md-6">
                        <ul style={{listStyleType: 'circle'}}>
                          <li>Sunday and holiday services</li>
                          <li>Late pickup and deliveries</li>
                          <li>Pan india to pan india reverse logistic.</li>
                          <li> Express Pallet heavy cargo shipments</li>
                          <li>Express NFO (Next Flight Out)  your smooth production and commitment </li>
                          <li>POD in hard and soft format as per clients Request</li>
                      </ul>
                          
                        </div>
                      </div>
                      
                    </div>
                    <div className="col-md-4 my-3">
                      <div className='text-center'>
                        <img src={img3} style={{width: '100%'}} alt=""/>
                      </div>
                    </div>

               {/*  */}

               <div className="col-md-4 my-3">
                      <div className='text-center'>
                      <img src={img4} style={{width: '100%'}} alt=""/>
                      </div>
                    </div>
                    <div className="col-md-8  my-3">
                        <h2 className='text-dark fw-bold'>Express Surface Cargo</h2>
                         <p className='text-dark'>Surface cargo is a prevalent mode of transportation
                            The Best option to ship causation/dangerous products  prohibited through the air and Non standard Cargo 
                            Economy service used to compete your market  products  </p>
                          <p className='fw-bold'>KEY DIFFERENTIATORS</p>
                          <div className="row">
                          <div className="col-md-6">
                            <ul style={{listStyleType: 'circle'}}>
                                <li>HUB TO HUB</li>
                                <li>PTL (Partial Truck Load)</li>
                                <li>Express pallet heavy cargo/ Density</li>
                            </ul>
                          </div>
                            <div className="col-md-6">
                            <ul style={{listStyleType: 'circle'}}>
                                <li>FTL (Full Truck Load)</li>
                               <li>Reverse PickUp Mechanism</li>
                                <li>Pan india to pan india pickup system </li>
                          
                            </ul>
                            </div>  
                           
                          </div>                          
                       
                    </div>
            </div>



                    
    </div>
    </>
          
  )
}
