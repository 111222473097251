import axios from "axios";
import {
    CART_ADD_REQUEST,
    CART_ADD_SUCCESS,
    CART_ADD_FAIL,
    CART_LIST_REQUEST,
    CART_LIST_SUCCESS,
    CART_LIST_FAIL,
    CART_CUSTOMER_LIST_REQUEST,
    CART_CUSTOMER_LIST_SUCCESS,
    CART_CUSTOMER_LIST_FAIL,
    CART_UPDATE_REQUEST,
    CART_UPDATE_SUCCESS,
    CART_UPDATE_FAIL,
    CART_DETAILS_REQUEST,
    CART_DETAILS_SUCCESS,
    CART_DETAILS_FAIL,
    CART_PROCEED_ORDER_REQUEST,
    CART_PROCEED_ORDER_SUCCESS,
    CART_PROCEED_ORDER_FAIL,
    CART_ADMIN_CUSTOMER_LIST_REQUEST,
    CART_ADMIN_CUSTOMER_LIST_SUCCESS,
    CART_ADMIN_CUSTOMER_LIST_FAIL,
    CART_UPLOAD_RECEIPT_REQUEST,
    CART_UPLOAD_RECEIPT_SUCCESS,
    CART_UPLOAD_RECEIPT_FAIL,
    CART_UPDATE_STATUS_REQUEST,
    CART_UPDATE_STATUS_SUCCESS,
    CART_UPDATE_STATUS_FAIL,
    CART_DELETE_REQUEST,
    CART_DELETE_SUCCESS,
    CART_DELETE_FAIL,
} from '../constants/cartConstants'
import { logout } from "./userActions";

export const addCart = (cartData) => async(dispatch, getState) => {
    dispatch({
        type: CART_ADD_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }
        const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}api/cart/add`, cartData, config)
        dispatch({
            type: CART_ADD_SUCCESS,
            payload: data
        })
    } catch (error) {

      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: CART_ADD_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
    }
}

export const updateCart = (cartData) => async(dispatch, getState) => {
    dispatch({
        type: CART_UPDATE_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/cart/${cartData._id}`, cartData, config)

        dispatch({
            type: CART_UPDATE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: CART_UPDATE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const cartList = () => async(dispatch, getState) => {
    dispatch({
        type: CART_LIST_REQUEST
    })
    try {

        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/cart/admin/list`, config)

        dispatch({
            type: CART_LIST_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: CART_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const cartDetailsOfCustomer = (id) => async(dispatch, getState) => {
    dispatch({
        type: CART_CUSTOMER_LIST_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }
        const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/cart/my/${id}`, config)
        dispatch({
            type: CART_CUSTOMER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }
        dispatch({
            type: CART_CUSTOMER_LIST_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
      }
}

export const cartDetails = (id) => async(dispatch, getState) => {
  dispatch({
      type: CART_DETAILS_REQUEST
  })
  try {

      const { userSignIn: { userInfo }, } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth': userInfo.token
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/cart/admin/${id}`, config)

      dispatch({
          type: CART_DETAILS_SUCCESS,
          payload: data
      })
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CART_DETAILS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const deleteCart = (id) => async(dispatch, getState) => {
    dispatch({
        type: CART_DELETE_REQUEST
    })
    try {
        const {
            userSignIn: { userInfo },
          } = getState()

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'auth': userInfo.token
            },
          }

        const {data} = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/cart/${id}`, config)

        dispatch({
            type: CART_DELETE_SUCCESS,
            payload: data
        })
        
    } catch (error) {
      if((error.response && error.response.data) && error.response.data.status === 401){
        dispatch(logout())
      }

        dispatch({
            type: CART_DELETE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
        
    }
}

export const cartListByCustomerIdForAdmin = (id) => async(dispatch, getState) => {
  dispatch({
      type: CART_ADMIN_CUSTOMER_LIST_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth': userInfo.token
          },
        }

      const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}api/cart/admin/${id}`, config)

      dispatch({
          type: CART_ADMIN_CUSTOMER_LIST_SUCCESS,
          payload: data
      })
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CART_ADMIN_CUSTOMER_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const uploadRecieptCart = (id, cartData) => async(dispatch, getState) => {
  dispatch({
      type: CART_UPLOAD_RECEIPT_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth': userInfo.token
          },
        }

      const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/cart/upload/receipt/${id}`, cartData, config)

      dispatch({
          type: CART_UPLOAD_RECEIPT_SUCCESS,
          payload: data
      })
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CART_UPLOAD_RECEIPT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const updateStatusCart = (cartData) => async(dispatch, getState) => {
  dispatch({
      type: CART_UPDATE_STATUS_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth': userInfo.token
          },
        }

      const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/cart/admin/update/status/${cartData._id}`, cartData, config)

      dispatch({
          type: CART_UPDATE_STATUS_SUCCESS,
          payload: data
      })
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CART_UPDATE_STATUS_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}

export const proceedOrderMail = (cartData) => async(dispatch, getState) => {
  dispatch({
      type: CART_PROCEED_ORDER_REQUEST
  })
  try {

      const {
          userSignIn: { userInfo },
        } = getState()

      const config = {
          headers: {
            'Content-Type': 'application/json',
            'auth': userInfo.token
          },
        }

      const {data} = await axios.put(`${process.env.REACT_APP_BASE_URL}api/cart/send/mail`,cartData, config)

      dispatch({
          type: CART_PROCEED_ORDER_SUCCESS,
          payload: data
      })
      
  } catch (error) {
    if((error.response && error.response.data) && error.response.data.status === 401){
      dispatch(logout())
    }

      dispatch({
          type: CART_PROCEED_ORDER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      
  }
}


