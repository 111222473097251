import { getToken } from "./util"
const LOCAL = "local"
const PRODUCTION="production"
const HTML_GT = '&#62;'
const DEFAULT_ASSESSMENT_ICON = '/apple-icon.png'
const state = PRODUCTION
const PLACEHOLDER_IMAGE = "/placeholder.png"
const API_ENDPOINT = state === PRODUCTION?"https://apis.rkincindia.com/":"http://localhost:5000/"
export {
    API_ENDPOINT, 
    PLACEHOLDER_IMAGE,
    HTML_GT
}