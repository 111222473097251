import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import data from '../data'
import ProductCart from '../../components/Product/ProductCart'
import { medicineList } from "../../redux/actions/medicineActions";
import { labTestList } from "../../redux/actions/labTestActions";
import { ayurvedaList } from "../../redux/actions/ayurvedaActions";
import { API_ENDPOINT, HEADER_TOKEN } from "../../rd-utils/constants";
import axios from "axios";

const ProductSlider = () => {
  const [productData, setProductData] = useState([])
  
  useEffect(()=>{
    loadMedicines()
  }, [])
  function loadMedicines(){
    axios.get(API_ENDPOINT+'user/home-medicines').then(res=>{
      let d = res.data
      console.log(d)
      if(d.success){
        setProductData(d.message)
      }
    })
  }

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
    <>
        <Slider {...settings}>
            { productData.map((item, i) => {
                return (
                    <div key={i}>
                      <ProductCart item={item} />
                     </div>
                    );
                })
            }
        </Slider>
        </>
    );
  
}

export default ProductSlider