import {
    DISEASE_ADD_REQUEST,
    DISEASE_ADD_SUCCESS,
    DISEASE_ADD_FAIL,
    DISEASE_ADD_RESET,
    DISEASE_LIST_REQUEST,
    DISEASE_LIST_SUCCESS,
    DISEASE_LIST_FAIL,
    DISEASE_DETAILS_REQUEST,
    DISEASE_DETAILS_SUCCESS,
    DISEASE_DETAILS_FAIL,
    DISEASE_UPDATE_REQUEST,
    DISEASE_UPDATE_SUCCESS,
    DISEASE_UPDATE_FAIL,
    DISEASE_DELETE_REQUEST,
    DISEASE_DELETE_SUCCESS,
    DISEASE_DELETE_FAIL,
} from '../constants/diseaseConstants'

export const addDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case DISEASE_ADD_REQUEST:
        return { loading: true }
      case DISEASE_ADD_SUCCESS:
        return { loading: false, disease: action.payload }
      case DISEASE_ADD_FAIL:
        return { loading: false, error: action.payload }
      case DISEASE_ADD_RESET:
        return {}
      default:
        return state
    }
  }

  export const updateDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case DISEASE_UPDATE_REQUEST:
        return { loading: true }
      case DISEASE_UPDATE_SUCCESS:
        return { loading: false, disease: action.payload }
      case DISEASE_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case DISEASE_LIST_REQUEST:
        return { loading: true }
      case DISEASE_LIST_SUCCESS:
        return { loading: false, diseases: action.payload }
      case DISEASE_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const diseaseDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case DISEASE_DETAILS_REQUEST:
        return { loading: true }
      case DISEASE_DETAILS_SUCCESS:
        return { loading: false, diseaseData: action.payload }
      case DISEASE_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const diseaseDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case DISEASE_DELETE_REQUEST:
        return { loading: true }
      case DISEASE_DELETE_SUCCESS:
        return { loading: false, diseaseData: action.payload }
      case DISEASE_DELETE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }