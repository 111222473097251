import React from 'react'; 
import { Outlet  } from "react-router-dom";
import Footer from './pages/include/Footer'; 
import Navbar from './components/Navbar';

function Ecomerce() {

  return (
    <>

          <Navbar />
          <Outlet />
          <Footer/>
    </>
  );
}

export default Ecomerce;