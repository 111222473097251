import {
    LAB_TEST_ADD_REQUEST,
    LAB_TEST_ADD_SUCCESS,
    LAB_TEST_ADD_FAIL,
    LAB_TEST_ADD_RESET,
    LAB_TEST_LIST_REQUEST,
    LAB_TEST_LIST_SUCCESS,
    LAB_TEST_LIST_FAIL,
    LAB_TEST_LIST_OF_DISEASE_REQUEST,
    LAB_TEST_LIST_OF_DISEASE_SUCCESS,
    LAB_TEST_LIST_OF_DISEASE_FAIL,
    LAB_TEST_DETAILS_REQUEST,
    LAB_TEST_DETAILS_SUCCESS,
    LAB_TEST_DETAILS_FAIL,
    LAB_TEST_UPLOAD_REQUEST,
    LAB_TEST_UPLOAD_SUCCESS,
    LAB_TEST_UPLOAD_FAIL,
    LAB_TEST_UPDATE_REQUEST,
    LAB_TEST_UPDATE_SUCCESS,
    LAB_TEST_UPDATE_FAIL,
    LAB_TEST_UPDATE_RESET,
    LAB_TEST_DELETE_REQUEST,
    LAB_TEST_DELETE_SUCCESS,
    LAB_TEST_DELETE_FAIL,
    LAB_TEST_DELETE_RESET,
} from '../constants/labTestConstants'

export const addLabTestReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_ADD_REQUEST:
        return { loading: true }
      case LAB_TEST_ADD_SUCCESS:
        return { loading: false, labTest: action.payload, success: true }
      case LAB_TEST_ADD_FAIL:
        return { loading: false, error: action.payload }
      case LAB_TEST_ADD_RESET:
        return { }
      default:
        return state
    }
  }

  export const updateLabTestReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_UPDATE_REQUEST:
        return { loading: true }
      case LAB_TEST_UPDATE_SUCCESS:
        return { loading: false, labTest: action.payload, success: true }
      case LAB_TEST_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case LAB_TEST_UPDATE_RESET:
        return {}
      default:
        return state
    }
  }

  export const uploadLabTestFileReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_UPLOAD_REQUEST:
        return { loading: true }
      case LAB_TEST_UPLOAD_SUCCESS:
        return { loading: false, labTestFile: action.payload, success: true }
      case LAB_TEST_UPLOAD_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listLabTestReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_LIST_REQUEST:
        return { loading: true }
      case LAB_TEST_LIST_SUCCESS:
        return { loading: false, labTests: action.payload.labTestList }
      case LAB_TEST_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listLabTestOfDiseaseReducer = (state = {}, action) => {
    switch (action.type) {
      case  LAB_TEST_LIST_OF_DISEASE_REQUEST:
        return { loading: true }
      case  LAB_TEST_LIST_OF_DISEASE_SUCCESS:
        return { loading: false, labTests: action.payload.labTestsList }
      case  LAB_TEST_LIST_OF_DISEASE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const labTestDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_DETAILS_REQUEST:
        return { loading: true }
      case LAB_TEST_DETAILS_SUCCESS:
        return { loading: false, labTestData: action.payload.labTestData }
      case LAB_TEST_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const labTestDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LAB_TEST_DELETE_REQUEST:
        return { loading: true }
      case LAB_TEST_DELETE_SUCCESS:
        return { loading: false, labTestData: action.payload, success: true }
      case LAB_TEST_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case LAB_TEST_DELETE_RESET:
        return {}
      default:
        return state
    }
  }