import React from 'react'
import about from '../assets/images/about/about.jpg'
import about1 from '../assets/images/about/about1.jpg'
import about2 from '../assets/images/about/about2.jpg'
import about3 from '../assets/images/about/about3.jpg'
import about4 from '../assets/images/about/about4.jpg'


export default function About() {
  return (
    <>
      <div className="container my-4">
      {/* SECTION 1 */}
        <div className="row justify-content-center">
            <div className="col-md-10 text-center my-4">
            <figure className="text-center">
              <blockquote className="blockquote">
                <p className='display-5 fw-bold'>ABOUT US</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                <cite title="Source Title"> RK Groups</cite>
              </figcaption>
            </figure>
                <p className='lead'>RK Groups started its journey from 2010 as a one among the largest Logistics and Warehousing, Pharmaceutical exporter as well as IT Solutions. Soon with the development, turned out to be one of leading service oriented committed organization across the globe.Our success depends upon the skills, qualities and well-being of our team.</p>
                <p className='lead text-center m-0'><span className='text-muted fw-bold'>RK World Wide Inc</span> is an exporter of Pharmaceutical, Supplements and Devices finalized products from India. We give an option to create your own branding. We believe in not just doing business rather take pride in providing quality products and matchless services with best price in the industries. Major parts of our products are being exported to various Countries in South American and Caribbean countries.</p>
            </div>
            <div className="col-md-6 my-4">
              <div>
                <img src={about1} className="c" alt="..."  width={'100%'}/>
              </div>
           </div>
           <div className="col-md-6 my-4">
              <p className='text-dark'><b className='text-muted fw-bold'>RK World Wide-</b>Logistics, for all your logistics needs. With our vast network throughout India and Globe. </p>
              <p className='text-dark'><b className='text-muted fw-bold'>Services Rendered: </b>Surface, Air and Sea freight forwarding services, and Customs Clearing services, Warehousing Facilities.</p>
              <p className='text-dark'><b className='text-muted fw-bold'>RK World Wide Inc </b>is the South Indian based first and foremost IT Solutions. We are proud to be consultants and integrators for reputed Educational institutionsand in Corporate sectors. We supply all types of desktops, laptops, tablets, electronic security systems and any networking peripherals within 24 Hours.</p>
              <p className='text-dark'>We understand our Customer needs and reach beyond their expectations with an ultimate objective of achieving their delight. We act in accordance with our words and values. We comply with the laws and respect the good business practices of the countries in which we operate.</p>
           </div>
        </div>

      {/* SECTION 2 */}
      <div>
        <div className='row justify-content-center'>
                <div className="col-md-6">
                    <h4 className='display-5'>About us:</h4>
                    <p className='text-dark m-0'>We Aspire to Deliver Value Products</p>
                    <p className='text-dark'>RK World Wide is a vibrant and young service provider company with expertise in export and import of pharmaceutical, electronic devices, FMCG, WHO & GMP products, hardware, automobile Manufacturing facility.</p>
                    <p className='text-dark'>Global & local transportation, customs & regulatory consultation & services, long term & temporary based warehousing, stocking, packing, distribution through e-commerce and traditional channels and providing highest quality of technology & communication by Human Resources who are trained on specific sectors or processes are the advantages of RK World Wide has relentlessly provided to customers all the way.</p>
                    <p className='text-dark'>We provide our customers with a partner who can be trusted for their end to end supply chain management requirements such as procurement, warehousing, global logistics, air and sea freight, customs clearance, leading up to last mile delivery & returns management (reverse logistics).</p>
                </div>
                <div className='col-md-6'>
                  <div>
                    <img src={about2} className="w-100 shadow"  alt="..." />
                  </div>
                </div>
          </div>
      
            <div className="row justify-content-center">
              <div className="col-md-12 text-center my-4">
                <h3 className='display-5'>WHY CHOOSE US?</h3>
              </div>

                {/* <div className="row justify-content-center"> */}
                  <div className="col-md-2 box effect my-3" style={{height:'auto'}}> 
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-circle-check fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Reliability & Advance Technology.</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-handshake fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Collaborative customer relationship</h6>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-money-bill fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Optimized network to save you money.</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-plane-departure fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Flexible transportation solution.</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-flask fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Proven industry  expertise.</h6>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-users fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Experienced and friendly staff.</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 box effect  my-3" style={{height:'auto'}}>
                    <div className='card'>
                      <div className="card-body text-center">
                      <i class="fa-solid fa-chart-simple fa-2xl mt-2 mb-4 text-info"></i>
                        <h6>Highly competitive price.</h6>
                      </div>
                    </div>
                  </div>
          </div>
            <div className='row justify-content-center my-5'>
              <div className="col-md-6 my-4">
                  <h3 className='display-5'>Our Mission:</h3>
                  <p className='m-0'>To uphold our social responsibilities of delivering highest standard  services to all segments of society without compromising on our core values of integrity, good ethics and commitment.</p>
                  <h3 className='display-5 mt-4'>Our Vision:</h3>
                  <p className='m-0'>To become the leading service player in the industry by assuring high quality standards and cost effective services at all times. We want to mark our presence in the society by creating a portfolio of low cost services available to all.</p>
              </div>
              <div className="col-md-6 my-4">
              <div>
                  <img src={about3} className="w-100 shadow" alt="..." />
                </div>
              </div>

              <div className="col-md-4 mt-5">
                <div>
                  <img src={about4} className=" shadow" style={{height:'300px', width: '300px'}} alt="..." />
                </div>
              </div>
              <div className="col-md-8 mt-5 text-start">
                <h4 className='display-5'>About Director</h4>
                <p className='lead'><span className='fw-bold text-muted'>Mr. Ramakrishna. M </span>is a one among the founder of this partnership firm, Ramakrishna is charged with constantly moving the company forward. From finance discussions to brainstorming on new product design to business development planning, Ramakrishna keeps himself involved in all areas of the business. </p>
                <p className='lead'>Having guided the teams at RK World Wide is establishing and developing various business. Ramakrishna is constantly exploring strategies to enhance the profitability of our business operations.  </p>
              </div>
            </div>


           
            
          <div className='row justify-content-center'>
            
          <div className="col-md-11">
            <h3 className='display-5 text-center my-4'>Our Core values: </h3>
            <p className='text-dark'>RK World Wide has always been values-driven. Our shared values guide our actions that help us make a difference. These values continue to direct the growth and business of RK World Wide. The eight core RK values underpinning the way we do business are:</p>
            </div>
          <div className="col-md-10">
            <ul>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i> Entrepreneurial Soul:</b> We embrace innovations when it comes to technology. We know how to think creatively as entrepreneurs for operational excellence to create profitable growth and value for our stakeholders.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i> Portfolio:</b> Offering the world a complete portfolio of  services that anticipate and satisfy clients’ desires and needs.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i> Responsive:</b> We understand the customer’s need, so we know to act quickly so that others can perform at their best.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i>	Passion</b> Committed at heart and mind.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i>	Diversity & Quality:</b> As inclusive as our brands. What we do, we do well.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i>	Innovation:</b> Ensuring social responsibilities, accomplishing economic growth.</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i>	Leadership:</b> Leadership means different things to different people, and effective leadership styles can vary significantly, depending on the client and the situation</li>
              <li><b className='fw-bold'><i className="fa-solid fa-circle-check text-info"></i>   Safety-Focused:</b> We believe that safety is of utmost importance, extending beyond the company to our customers, communities, and families. We endeavor to be the standard of the business through innovation and persistent learning.</li>
             
            </ul>
          </div>        
          </div>
        </div>
      </div>
    </>
  )
}


