import { Button, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItemImage from '../ItemImage';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { LoadingButton } from '@mui/lab';
import { maxWidth } from '@mui/system';
import { API_ENDPOINT, PLACEHOLDER_IMAGE } from '../../rd-utils/constants';
import { getHeader } from '../../rd-utils/util';
import { toast } from 'react-toastify';

export default function MyReceipt(props) {
    const {order, orders, setOrders, back} = props
    const [loading, setLoading] = useState(false)
    const [tid, setTid] = useState('')
    const image_file = useRef()
    const [placeholder, setPlaceholder] = useState(PLACEHOLDER_IMAGE)
    const chooseReceipt = ()=>{
        image_file.current.click()
    }


    useEffect(()=>{
        if(order.receipt){
            let rc = order.receipt
            setPlaceholder(rc.url)
            setTid(rc.transaction_id)
        }
    }, [])
    const saveReceipt = ()=>{
        if(tid === ""){
            toast("Please Enter Transaction ID")
        }else if(placeholder === PLACEHOLDER_IMAGE){
            toast("Please Choose Receipt")
        }else{
            axios.post(API_ENDPOINT+'user/upload-receipt', {order_id: order.order_id, tid: tid, url: placeholder}, getHeader()).then(res=>{
                let d = res.data
                if(d.success){
                    document.location.reload()
                }else{
                    toast(d.message)
                }
            })
        }
    }
    const onImgSelect = event=>{
        const [file] = image_file.current.files
        if(file){
            let fd = new FormData()
            fd.append("image", file)
            setLoading(true)
            axios.post(API_ENDPOINT+'admin/upload-image-only', fd, getHeader()).then(res=>{
                let d = res.data
                setLoading(false)
                if(d.success){
                    // let ca = [...certs]
                    // ca.push({url: d.message})
                    // setCerts(ca)
                    setPlaceholder(d.message)
                }else{
                    toast(d.message)
                }
            })
        
        }
      }
    return(
        <div>
              <input onChange={onImgSelect} ref={image_file} type="file" accept="image/*" style={{display: 'none'}} />
             <Button onClick={back} variant='outlined' startIcon={<ArrowBackIosIcon />}>Back</Button>
             <div style={{marginTop: '12px'}}>Order ID: <span style={{color: 'blue'}}>#{order.order_id}</span></div>

             <div style={{padding: '12px', maxWidth: '400px', border: '1px solid #ddd'}}>
                <LoadingButton loading={loading} loadingPosition='start' startIcon={<ReceiptIcon />} onClick={chooseReceipt} variant='contained'>Choose Receipt</LoadingButton>
                <img src= {placeholder} style={{height: '192px', margin: '4px', borderRadius: '4px', display: 'block', boxShadow: 'box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)'}} alt="pl"/>
                <TextField value={tid} onChange={e=>setTid(e.target.value)} sx={{marginTop: '16px'}} variant="standard" fullWidth size='small' label='Enter Transaction ID' />

                <LoadingButton fullWidth sx={{marginTop: '20px'}} loading={loading} loadingPosition='start' startIcon={<ReceiptIcon />} onClick={saveReceipt} variant='contained'>Save Receipt</LoadingButton>
             </div>
        </div>
    )
}