import {
    DOCTOR_ADD_REQUEST,
    DOCTOR_ADD_SUCCESS,
    DOCTOR_ADD_FAIL,
    DOCTOR_ADD_RESET,
    DOCTOR_LIST_REQUEST,
    DOCTOR_LIST_SUCCESS,
    DOCTOR_LIST_FAIL,
    DOCTOR_DETAILS_REQUEST,
    DOCTOR_DETAILS_SUCCESS,
    DOCTOR_DETAILS_FAIL,
    DOCTOR_UPDATE_REQUEST,
    DOCTOR_UPDATE_SUCCESS,
    DOCTOR_UPDATE_FAIL,
    DOCTOR_DELETE_REQUEST,
    DOCTOR_DELETE_SUCCESS,
    DOCTOR_DELETE_FAIL,
    DOCTOR_DELETE_RESET
} from '../constants/doctorConstants'

export const addDoctorReducer = (state = {}, action) => {
    switch (action.type) {
      case DOCTOR_ADD_REQUEST:
        return { loading: true }
      case DOCTOR_ADD_SUCCESS:
        return { loading: false, doctor: action.payload }
      case DOCTOR_ADD_FAIL:
        return { loading: false, error: action.payload }
      case DOCTOR_ADD_RESET:
        return {}
      default:
        return state
    }
  }

  export const updateDoctorReducer = (state = {}, action) => {
    switch (action.type) {
      case DOCTOR_UPDATE_REQUEST:
        return { loading: true }
      case DOCTOR_UPDATE_SUCCESS:
        return { loading: false, doctor: action.payload }
      case DOCTOR_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const listDoctorReducer = (state = {}, action) => {
    switch (action.type) {
      case DOCTOR_LIST_REQUEST:
        return { loading: true }
      case DOCTOR_LIST_SUCCESS:
        return { loading: false, doctorList: action.payload.doctorsList }
      case DOCTOR_LIST_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const doctorDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case DOCTOR_DETAILS_REQUEST:
        return { loading: true }
      case DOCTOR_DETAILS_SUCCESS:
        return { loading: false, doctorData: action.payload }
      case DOCTOR_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const doctorDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case DOCTOR_DELETE_REQUEST:
        return { loading: true }
      case DOCTOR_DELETE_SUCCESS:
        return { loading: false, doctorData: action.payload, success: true }
      case DOCTOR_DELETE_FAIL:
        return { loading: false, error: action.payload }
      case DOCTOR_DELETE_RESET:
        return {}
      default:
        return state
    }
  }