import React from 'react'

import '../static_pages/static.css'
import img1 from '../../assets/images/static_pages/logistics/international/img1.png'
import img2 from '../../assets/images/static_pages/logistics/international/img2.png'
import img3 from '../../assets/images/static_pages/logistics/international/img3.png'
import img4 from '../../assets/images/static_pages/logistics/international/img4.png'
import img5 from '../../assets/images/static_pages/logistics/international/img5.png'






export default function LogisticsInternational() {
  
  return (
    <>  
        <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4 logistics_international_banner">
                        <h4 className='fw-bolder text-uppercase text-light text-center' style={{marginBottom: '20%'}}> THE FAST AND FLEXIBLE WAY TO MOVE YOUR CARGO ALL OVER THE WORLD WIDE</h4>
                        <h4 className='fw-bolder text-uppercase text-light text-center mt-5'>INTERNATIONAL   AIR FREIGHT  GLOBAL FEDEX- DHL-AIRLINES EXPORT - IMPORT</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                          <div className="my-3">
                            <div >
                            <h2 className='text-dark fw-bold'> EXPRESS ENVELOPES/ E-COMMERCE</h2>
                          <p className='text-dark'> RK world Wide, we make reliability a top priority. When sending sensitive or confidential Envelops through our Network services, We always receive a unique tracking number so you can follow its progress .</p>
                            </div>
                          </div>
        
                        </div>
                        <div className="col-md-4">
                          <div className='text-center'>
                          <img src={img1} style={{width: '200px'}} alt=""/>
                          </div>
                        </div>
                    </div>
                   
            <div className="row my-4">
                    <div className="col-md-4">
                      <div className='text-center'>
                      <img src={img2} style={{width: '100%'}} alt=""/>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="my-3">
                        <div >
                        <h2 className='text-dark fw-bold'> EXPRESS AIR CARGO AND PALLET </h2>
                         <p className='text-dark'>This is the quickest and most costly method to transport air cargo. The carriers guarantee speedy service, like an expedited delivery, for the cost of a premium.</p>
                        <p className='text-dark'>The pallets are sheets made of aluminum, which are used to hold goods stackable and Non-stakable . 
                            A net is used to cover the goods stacked and stop them from falling.
                            </p>
                                                    <p className='text-dark'>RK world wide is a one stop solution international courier service to all the destinations across the globe.
                            The Covering  all over  countries for both forward as well as Export-import  services.
                            </p>

                        </div>
                      </div>
    
                    </div>
            </div>

            <div className="row my-4">
                   
                    <div className="col-md-8">
                      <div className="my-3">
                   
                        <h2 className='text-dark fw-bold'>AIR FREIGHT FORWARDER</h2>
                         <p className='text-dark'>We operate international freight services through our wholly owned subsidiary company providing the criticality to freight shipments that they truly deserve.
We provide Consolidation cargo system  services.</p>
                      
                      </div>
    
                    </div>
                    <div className="col-md-4">
                      <div className='text-center'>
                      <img src={img3} style={{width: '100%'}} alt=""/>
                      </div>
                    </div>
            </div>

            <div className="row my-4">
                    <div className="col-md-4">
                      <div className='text-center'>
                         <img src={img4} style={{width: '100%'}} alt=""/>
                      </div>
                      <div className='text-center'>
                         <img src={img5} style={{width: '100%'}} alt=""/>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="my-3">
                        <div >
                        <h2 className='text-dark fw-bold'>DANGEROUS CARGO (DG CARGO HANDLING) </h2>
                         <p className='text-dark'>we handle DG with packing and Hazardous goods to perfection. All compliance regarding necessary certifications and checks & balances are met. </p>
                         
                         
                         <h2 className='text-dark fw-bold'>DOOR TO DOOR</h2>
                         <p className='text-dark'>We also handle door to door  deliveries expertly for a wide range of products </p>
                         <p className='text-dark'>International air express is a unique product mainly for shipments ranging from 500 GMs to 50 kgs. These are the services in which a premium cost is applicable but the services are door to door and faster in speed compared to other services.</p>
                        
                         <h2 className='text-dark fw-bold'>EXCESS BAGGAGES</h2>
                         <p className='text-dark'>Both Commercial and Non-Commercial shipments including Documents / Non Documents, Samples, Gifts, Eatables, Sweets, dry foods, Snacks, Pickles, Homemade items, Household Utensils, Condiments, Clothes, Books and University Applications excess baggage etc, which are small shipments, are best routes through this mode</p>

                         <h2 className='text-dark fw-bold'>COURIER SAMPLES AND COMMECIAL SHIPMENTS</h2>
                         <p className='text-dark'> In addition to our own network, we have strategic partnership accounts with UPS, FedEx,DHL and all Airlines  thereby providing our customers’ unmatched variety for selection based on geographic and other aspects.</p>
                         <p className='text-dark'>    Documentation, paper work, packing, pricing, door pickup, tracking, personalized customer support services 24/7/365 days, RK world wide  is your Trusted international air express specialist.Turnaround time (TAT) based deliveries for urgent shipments</p>

                        </div>
                      </div>
    
                    </div>
            </div>
                    
            </div>
        </div>
    </>
  )
}