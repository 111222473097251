import React from 'react'
import it1 from '../../assets/images/static_pages/eletronics/it/it1.png'
import it2 from '../../assets/images/static_pages/eletronics/it/it2.png'
import it3 from '../../assets/images/static_pages/eletronics/it/it3.png'
import it4 from '../../assets/images/static_pages/eletronics/it/it4.png'
import it5 from '../../assets/images/static_pages/eletronics/it/it5.png'
import it6 from '../../assets/images/static_pages/eletronics/it/it6.png'
export default function ElectronicsIt() {
  return (
    <>
       <div className="container my-4">
            <div className="row justify-content-center">
                    <div className="col-md-12 my-4">
                        <h1 className='fw-bolder text-uppercase text-center'>it Service's</h1>
                    </div>

                    <div className='row my-4'>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it1} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">WEB DEVELOPMENT</h5>

                                 <p className='text-dark'>
                                 Our team of proficient website developers uses the latest technologies to provide responsive website designs for small as well as large enterprises.


                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it2} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">APPLICATION DEVELOPMENT</h5>

                                 <p className='text-dark'>
                                 Our team of proficient Application  developers 
Use the best of mobile technology to provide a user-friendly and secure platform.


                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it3} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">DIGITAL MARKETING</h5>
                               <p className='text-dark'>
                               Social media marketing
E-mail marketing
Bulk SMS marketing
content marketing

                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it4} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">DOMAIN AND HOSTING</h5>
                               <p className='text-dark'>Domain Registration
Bulk Registration 
Domain transfer 
bulk transfer
Web hosting
VPS 
Cpanel
</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it5} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Website Annual Maintainance</h5>
                               <p className='text-dark'>Maintenance Service with Content Update, Security, Speed, Backup, Plugins, SSL, CDN & More. Expert to Handle Maintenance Requests.

</p>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 my-3 text-center">
                          <div className='card shadow'>
                            <img src={it6} style={{width: '100%', height: '100%'}} alt=""/>
                            <div className="card-body">
                               <h5 class="card-title text-dark">Software Application Development</h5>
                               <p className='text-dark'>Website Development
Mobile Applications

</p>

                            </div>
                          </div>
                        </div>
                    </div>

            </div>
        </div>
    </>
  )
}
