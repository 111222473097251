import React,{useEffect, useState}  from 'react'
import ReactDOM from 'react-dom/client';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Profile from './Profile'
import MyOrder from './MyOrder'
import Help from './Help'
import { deleteToken, getUserEmail, getUserName, isLoggedIn } from '../../rd-utils/util';
export default function UserDashboard() {
    const navigate = useNavigate()
    const [display, setDisplay] = useState('order');
    const sort = (data) => {
         if (data) {
            setDisplay(data);
         }
     }

     useEffect(()=>{
        if(!isLoggedIn()){
            navigate('/login')
        }
     }, [])

     const userName = getUserName()
     const userEmail = getUserEmail()
     const logout = ()=>{
        deleteToken()
        navigate('/login')
     }
    return (
        <>
             <div className="container ">
                <div className="row my-5">
                    <div className="col-md-3">
                        <h5 className='my-3 fw-bolder my-3'>My Accounts</h5> 
                        <div className='text-muted'>
                            <h6 className={display == 'profile' ? 'custom-text-color' : '' } style={{cursor: 'pointer'}} onClick={() => sort('profile')}><i className="fa-solid fa-circle-user"></i><span className='ms-4'>My Profile</span></h6><hr />
                            <h6 className={display == 'order' ? 'custom-text-color' : '' }  style={{cursor: 'pointer'}} onClick={() => sort('order')}> <i className="fa-solid fa-copy"></i> <span className='ms-4'>My Order</span></h6><hr />
                            <h6 className={display == 'help' ? 'custom-text-color' : '' }  style={{cursor: 'pointer'}} onClick={() => sort('help')}><i className="fa-solid fa-circle-question"></i> <span className='ms-4'>Help</span></h6><hr />
                            <h6 onClick={logout} className='text-muted' ><i className="fa-solid fa-arrow-right-from-bracket"></i> <span className='ms-4'>Logout</span></h6><hr />
                        </div>
                    </div> 
                    <div className="col-md-9">
                        {
                            display == 'profile' ? ( 
                                <>
                                 <h5 className='my-3 custom-text-color fw-bold'>My Profile</h5>
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <div className="form-floating m-3">
                                                <input disabled value={userName} type="text" className="form-control" placeholder="Full Name"/>
                                                <label htmlFor="floatingInput">Full Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-floating m-3">
                                                <input disabled value={userEmail} type="email" className="form-control" placeholder="Email Address"/>
                                                <label htmlFor="floatingPassword">Email Address</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="form-floating m-3">
                                                <input type="password" className="form-control" placeholder="Mobile Number"/>
                                                <label htmlFor="floatingPassword">Mobile Number</label>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div className="form-floating m-3 text-end">
                                                <button disabled className='btn btn-blue-style btn-lg'>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                              ) : display == 'order' ? ( 
                                <MyOrder />
                               ) : (
                                 <>
                                  <h5 className='my-3 custom-text-color fw-bold'>Help</h5>
                                 </>
                               ) 
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
